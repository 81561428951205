<div [ngStyle]="envBorderStyle()">
  <compass-toolbar
    [title]="title"
    [sidenav]="sidenav"
    [isAuthenticated]="(isAuthenticated$ | async)"
  ></compass-toolbar>
  <mat-sidenav-container>
    <mat-sidenav
      #sidenav
      [opened]="isAuthenticated$ | async"
      mode="side"
      class="app-sidenav mat-elevation-z2 pt-3"
      [@openCloseSidenav]="(isSidenavHidden$ | async) ? 'closed' : 'open'"
      [class.closed]="isSidenavHidden$ | async"
    >
      <nav>
        <div *ngFor="let link of (links$ | async);">
          <a
            mat-button
            class="nav-link mb-2"
            [routerLink]="link.path"
            [state]="{ schema: link }"
            routerLinkActive="active"
          >
            <mat-icon>
              {{ link.icon }}
            </mat-icon>
            <span *ngIf="!(isSidenavHidden$ | async)" class="label">{{ link.label }}</span>
          </a>
        </div>
        <div class="actions">
          <button *ngIf="!(isSidenavHidden$ | async)" (click)="hideSidenav()" mat-icon-button>
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button *ngIf="(isSidenavHidden$ | async)" (click)="showSidenav()" mat-icon-button>
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content 
      [@openCloseSidenavContent]="(isSidenavHidden$ | async) ? 'closed' : 'open'"
      id="sideNavContent"
    >
      <router-outlet></router-outlet>
      <div *ngIf="(isLoading$ | async)" class="loading">
        <mat-spinner></mat-spinner>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
