import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  ComponentDataEntry,
  ControlType,
  DbCallType,
  DbFunction,
  NotifyService,
  Section,
  Schema,
  FormService,
  DbTable,
  DateService,
  RefreshService
} from '@compass/core-data';
import { parse } from 'papaparse';
import { Subject, merge, throwError, Observable, of, BehaviorSubject } from 'rxjs';  
import { distinctUntilChanged, take, map, takeUntil, filter, debounceTime, catchError, tap } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { DbFacade } from '@compass/core-state';
import { FormControlComponent } from '../form-control/form-control.component';

@Component({
  selector: 'compass-component-data-entry',
  templateUrl: './component-data-entry.component.html',
  styleUrls: ['./component-data-entry.component.scss']
})
export class ComponentDataEntryComponent implements OnDestroy, OnInit {

  /** The component which contains a data input control. */
  @Input() componentType: ComponentDataEntry;

  @Input() section: Section;

  /** The control type is declared so the enum is available in the template. */
  ControlType = ControlType;

  /** The FormGroup instance for the schema. */
  @Input() form: FormGroup;

  @Input() schema: Schema;

  public formValues$ = new BehaviorSubject<any>(null);

  /** Subject to trigger unsubcribe to observable streams. */
  private unsubscribe = new Subject<void>();

  /** Form Controls that have key referenced in this Data Entry component's dbValues > dbCall > keys array */
  relatedFormControls: Array<AbstractControl> = [];

  @ViewChildren(FormControlComponent) formControls: QueryList<any>;

  isLoading$: Observable<boolean>;

  constructor(
    private readonly dbFacade: DbFacade,
    private readonly formService: FormService,
    private readonly notifyService: NotifyService,
    private readonly dateService: DateService,
    private readonly refreshService: RefreshService
  ) {
  }

  ngOnInit(): void {
    // If the id is defined, register it as a candidate to be refreshed
    if (this.componentType.id) {
      this.refreshService.refreshComponent(this, this.componentType.id);
    }
    // If there is no database call, there's nothing further that this component needs to do
    if (!this.componentType.dbValues) return;

    if (!this.componentType || !this.form || !this.schema || !this.section || !this.componentType.dbValues) {
      console.error(`DataEntryComponent is missing required properties!`);
    }

    this.relatedFormControls = this.formService.getRelatedFormControls(
      this.componentType,
      this.form,
      this.schema,
      this.section
    );

    this.populate();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getMarginRight(control: any) {
    return control.objectID === this.ControlType.Hidden ? 0 : 10;
  }

  private populate(refresh?: boolean): void {
    switch (this.componentType.dbValues?.dbCall.objectID) {
      case DbCallType.Function:
        this.populateFromFunction();
        break;
      case DbCallType.Table:
        this.populateFromTable();
        break;
      case DbCallType.Sql:
        throw new Error('Populating a data entry component via SQL is not supported.');
    }
  }

  private populateFromTable(): void {
    const dbTable = this.componentType.dbValues.dbCall as DbTable;

    // unsubscribe from previous value changes observables
    this.unsubscribe.next();

    // set initial values
    this.setFormValuesUsingDbTable(dbTable);

    // exit here if no keys
    if (
      !dbTable.keys ||
      (dbTable.keys && dbTable.keys.length === 0)
    ) {
      return;
    }

    // watch for future value changes on dependent controls
    const observables = dbTable.keys
      .filter(key => this.formService.getFormControl(key, this.form, this.schema, this.section))
      .map(key => {
        const control = this.formService.getFormControl(key, this.form, this.schema, this.section);
        return control.valueChanges.pipe(
          distinctUntilChanged(),
          takeUntil(this.unsubscribe)
        );
      });

    merge(...observables)
      .pipe(debounceTime(100))
      .subscribe(() => this.setFormValuesUsingDbTable(dbTable));
  }

  private setFormValuesUsingDbTable(dbTable: DbTable): void {
    let tableColumns = dbTable.tableColumns;

    if (!tableColumns) {
      tableColumns = this.componentType.controls.map(control => {
        return control.key;
      })
    }

    // get parameters
    let parameters = this.dbFacade.getParametersWithTableName(
      dbTable,
      this.form,
      this.schema,
      this.section
    );

    const missingRequiredParams = this.relatedFormControls ? this.relatedFormControls.find(formControl => {
      const valueRequired = this.formService.isControlRequired(formControl);
      const missingValue = !formControl.value;
      return valueRequired && missingValue;
    }) : false;

    if (missingRequiredParams) {
      this.resetFormControls();
      // console.error(`Missing required parameter(s) for Data Entry Component (title: '${this.componentType.title}', id: '${this.componentType.id}').`, missingRequiredParams)
      return;
    }

    // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
    if (dbTable.staticKeys) {
      dbTable.staticKeys.forEach((staticKey: any) => {
        parameters.push([staticKey.key, staticKey.value]);
      });
    }

    // Get data from tbl query
    this.dbFacade.tbl(dbTable, tableColumns, parameters, dbTable.sortColumns).pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe),
      catchError(e => {
        console.error(e);
        this.notifyService.notifyError(e);
        return throwError(e);
      })
    ).subscribe(data => {
      // First, reset/clear the form controls
      this.resetFormControls();

      if (!data[0]) return;

      // For each control with a key property, find it in the dataset
      const values = this.componentType.controls.filter(control => {
        if (!control.key) return false;

        if (!data[0].hasOwnProperty(control.key)) {
          console.error(`Could not find ${control.key} in the dataset ${JSON.stringify(data[0])}`);
          return false;
        }
        return true;
      }).map(control => data[0][control.key]);

      if (!isEmpty(values)) {
        if (values.length != this.componentType.controls.length) {
          console.warn(`Expecting the result set (${values.length}) to match the number of form controls (${this.componentType.controls.length}) in this data entry component!`)
        }
        // Set the observable of all data entry form values
        this.formValues$.next(values);
      } else {
        this.formValues$.next([]);
      }
    });
  }

  private populateFromFunction(): void {
    const dbFunction = this.componentType.dbValues.dbCall as DbFunction;

    // unsubscribe from previous value changes observables
    this.unsubscribe.next();

    // set initial values
    this.setFormValuesUsingDbFunction(dbFunction);

    // exit here if no keys
    if (
      !dbFunction.functionCall.keys ||
      (dbFunction.functionCall.keys && dbFunction.functionCall.keys.length === 0)
    ) {
      return;
    }

    // watch for future value changes on dependent controls
    const observables = dbFunction.functionCall.keys
      .filter(key => this.formService.getFormControl(key, this.form, this.schema, this.section))
      .map(key => {
        const control = this.formService.getFormControl(key, this.form, this.schema, this.section);
        return control.valueChanges.pipe(
          distinctUntilChanged(),
          takeUntil(this.unsubscribe)
        );
      });

    merge(...observables)
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.setFormValuesUsingDbFunction(dbFunction)
      });
  }

  private setFormValuesUsingDbFunction(dbFunction: DbFunction): void {
    this.isLoading$ = of(true)

    // get parameters
    let parameters = this.dbFacade.getParametersWithDbFunction(
      dbFunction,
      this.form,
      this.schema,
      this.section
    );

    const missingRequiredParams = this.relatedFormControls ? this.relatedFormControls.find(formControl => {
      const valueRequired = this.formService.isControlRequired(formControl);
      const missingValue = isEmpty(formControl.value);
      return valueRequired && missingValue;
    }) : false;

    if (missingRequiredParams) {
      this.isLoading$ = of(false)
      // console.error(`Missing required parameter(s) for Data Entry Component (title: '${this.componentType.title}', id: '${this.componentType.id}').`, missingRequiredParams)
      this.resetFormControls();
      return;
    }

    // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
    if (dbFunction.functionCall.staticKeys) {
      dbFunction.functionCall.staticKeys.forEach((staticKey: any) => {
        parameters.push([staticKey.key, staticKey.value]);
      });
    }

    this.dbFacade
      .fn(dbFunction, parameters)
      .pipe(
        take(1),
        map(result => {
          const rows = result['pie_query'];
          const keys = Object.keys(rows);
          return keys.map(key => rows[key]).map(value => parse(value, { delimiter: ',' }));
        }),
        map(results => {
          // currently, we only care about the first result
          return results[0];
        })
      )
      .subscribe(
        (parsed) => {
          // Reset all child form controls for this data entry component
          this.resetFormControls();

          // We have data
          if (parsed && !isEmpty(parsed.data)) {
            // Data set should match the number of non-table controls in this data entry component
            if (parsed.data[0].length != this.componentType.controls.filter(control => control.hasOwnProperty('key')).length) {
              console.warn(`Expecting the result set (${parsed.data[0].length}) to match the number of form controls (${this.componentType.controls.length}) in this data entry component!`)
            }

            // Set the array of values (form control has a property and subscriber for this observable)
            this.formValues$.next(parsed.data[0]);
            // console.log(`DataEntry component function query (fxn: ${JSON.stringify(dbFunction.functionCall.fxnName)}, params: ${parameters}) results: ${JSON.stringify(parsed.data[0])}`);
          } else { // Nothing returned from the db / no data
            this.formValues$.next([]);
          }
        },
        (error => console.error(error))
      );
  }

  private resetFormControls() {
    if (!this.formControls) return;

    this.formControls.forEach((control, index) => {
      const formControl = this.form.controls[control.relationalKey];
      if (formControl) {
        formControl.reset();
      }
    })
  }
}
