import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NotifyService } from '../utils/notify/notify.service';
import { AffectedComponent } from '../schema/action.model';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  subject = new Subject<AffectedComponent>();
  /** Subject to trigger unsubcribe to observable streams. */
  unsubscribe = new Subject<void>();

  constructor(private notifyService: NotifyService) { }

  refresh(componentId: string) {
    return this.subject.pipe(
      filter((affectedComponent: AffectedComponent) => affectedComponent.id === componentId)
    );
  }

  refreshComponent(component: any, componentId?: string) {
    const id = componentId ? componentId : (component.control.key || component.control.id);
    // If a component id (either key or id) is not passed, and can't be derived, don't register this component
    if (!id) return;

    this.refresh(id).pipe(takeUntil(this.unsubscribe)).subscribe(
      (affectedComponent: AffectedComponent) => {
        const isRefresh = true;
        const affectedWithDefaults = {
          refresh: true,
          removeHighlight: true,
          ...affectedComponent
        };
        if (affectedWithDefaults.refresh) {
          if (component.populate) {
            component.populate(isRefresh);
          }

          if (component.populateGrid) {
            component.populateGrid(isRefresh);
          }

          if (component.setDataToSave) {
            component.setDataToSave([]);
          }

          if (component.reapplySortFilter) {
            component.reapplySortFilter();
          }

          // Autocomplete/Dropdowns
          if (component.populateOptions) {
            component.populateOptions(isRefresh);
          }

          if (component.control && component.control.title) {
            const msg = component.control.title
              ? `${component.control.title} refreshed`
              : 'Component refreshed';
            this.notifyService.notify(msg);
          }
        }
        if (affectedWithDefaults.removeHighlight && component.removeHighlight) {
          component.removeHighlight();
        }
      },
      () => this.notifyService.notifyError(`${component.control.title} failed to refresh.`)
    );
  }
}
