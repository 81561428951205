import gql from 'graphql-tag';

export const timelineFragment = gql`
  fragment timelineFragment on TimelineDTO {
    tim_id
    tim_name
    tim_desc
    cale_tim_id
    cale_tim_sort
    tim_sys_active
    tim_sys_ts
    tim_sys_usr
    tim_sys_desc
  }
`;

export const allTimelinesQuery = gql`
  query allTimelinesQuery {
    allTimelines {
      ...timelineFragment
    }
  }
  ${timelineFragment}
`;

export const timelineByIdQuery = gql`
  query timelineByIdQuery($id: String!) {
    timelineById(id: $id) {
      ...timelineFragment
    }
  }
  ${timelineFragment}
`;

export const createDimensionsTimelineMutation = gql`
  mutation createDimensionsTimelineMutation($input: TimelineInput!) {
    createDimensionsTimeline(input: $input) {
      ...timelineFragment
    }
  }
  ${timelineFragment}
`;

export const updateDimensionsTimelineMutation = gql`
  mutation updateDimensionsTimelineMutation($patch: TimelineInput!) {
    updateDimensionsTimeline(patch: $patch) {
      ...timelineFragment
    }
  }
  ${timelineFragment}
`;

export const deleteDimensionsTimelineMutation = gql`
  mutation deleteDimensionsTimelineMutation($id: String!) {
    deleteDimensionsTimeline(id: $id)
  }
`;
