import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DataCollectionType, DbCallType, DbFunction } from '@compass/core-data';
import { CellValueChangedEvent } from 'ag-grid-community';
import { isEmpty } from 'lodash';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { TableComponent } from '../table/table.component';


@Component({
  selector: 'compass-control-table-dimension',
  templateUrl: '../table/table.component.html',
  styleUrls: ['../table/table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ControlTableDimensionComponent extends TableComponent implements OnDestroy {

  // This property holds the refData for the column definitions
  // i.e. {"colKey1": {1: "foo", 2: "bar"}, "colKey2": {1: "foo", 2: "bar"}}
  refDataColMappings = {};

  setRowData(): void {
    if (this.relatedFormControls.some(formControl => formControl.status != 'VALID')) return;

    const dbValues = this.getDbValuesFn();

    if (!dbValues) return;

    const tableRowsObservable = this.control.tableRowsDbValues ? this.getGeneratedRowsForTableRows() : this.getGeneratedRowsPerDimension();

    // get column observable
    if (this.control.tableColumns.length > 1)
      throw new Error(
        'Dimension/Manual table only supports 1 Manual Table Columns object of Fields array.'
      );

    const tableColumn = this.control.tableColumns[0];
    const column =
      tableColumn.objectID === DataCollectionType.Dimension
        ? this.dbFacade.getOptionsWithDbFunction(
          tableColumn.dimMembers.dbCall,
          this.form,
          this.section
        )
        : of([]);

    combineLatest(forkJoin(column, dbValues), forkJoin(tableRowsObservable))
      .pipe(
        map(([[columns, values], rows]: [[any[], any[]], any[]]) => {
          let results = [];
          if (this.control.tableRowsDbValues) {
            results = rows[0];
          } else {
            // add rows to results
            rows.forEach((options, index) => {
              options.forEach(option =>
                results.push({
                  [this.control.tableRows[index].key]: option.value
                })
              );
            });
          }

          // set each value deterministic of the row values
          values.forEach((dbValuesRecord, rowIndex) => {
            // Dimension table where rows are populated with tableRowsDbValues results
            if (this.control.tableRowsDbValues) {
              // find the first matching row (hstore array of values) from dbValues record set that has the same set of values as a tableRowsValues row
              const newRow = results.find(r => {
                return this.control.tableRows.every((collectionDim, index) => r[collectionDim.key] == dbValuesRecord[index])
              });
              if (!newRow) {
                return;
              }

              // Add the tableColumns' dimension key and value to the results (row data object)
              this.control.tableColumns.forEach((tableColumn, tableColIndex) => {
                const dimValIndex = this.control.tableRows.length + tableColIndex;
                const tableControlValueIndex = this.control.tableRows.length + this.control.tableColumns.length;

                newRow[dbValuesRecord[dimValIndex]] = dbValuesRecord[tableControlValueIndex];
              })

              return;
            }

            // Dimension table where rows are populated by their own dbValues for each
            // determine row index
            const indices = rows
              .map((options, rowIndex) => {
                return options.findIndex(option => option.value === dbValuesRecord[rowIndex]);
              })
              .filter(i => i !== -1)
              .map((rowIndex, i) => rowIndex + i);
            if (indices.length === 0) {
              return;
            }
            const index = indices[0];

            // determine column key
            const key = columns.find(
              option => option.value === dbValuesRecord[this.control.tableRows.length]
            );
            if (!key) {
              return;
            }

            results[index][key.value] = dbValuesRecord[this.control.tableRows.length + this.control.tableColumns.length];
          });

          return results;
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        rowData => this.rowData.next(rowData),
        err => {
          this.showLoadingOverlay = false;
          console.error(err);
          this.gridApi.hideOverlay();
        }
      );
  }

  // Get the data for the array of tableRows from the pagedef and make it ready for the grid
  // dbFunction MUST return an hstore with values: 'payload', JSON_AGG(data)::text
  private getGeneratedRowsForTableRows(): Observable<Array<{ [key: string]: string | number }>> {
    this.refDataColMappings = {};
    const dbFunction = this.control.tableRowsDbValues.dbCall as DbFunction;
    const parameters: Array<[string, string]> = this.dbFacade.getParametersWithDbFunction(
      dbFunction,
      this.form,
      this.schema,
      this.section
    );

    if (dbFunction.functionCall?.keys?.length && isEmpty(parameters)) {
      return null;
    }

    // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
    if (dbFunction.functionCall && dbFunction.functionCall.staticKeys) {
      dbFunction.functionCall.staticKeys.forEach((staticKey: any) => {
        parameters.push([staticKey.key, staticKey.value]);
      });
    }

    return this.dbFacade.fn(dbFunction, parameters).pipe(
      map((results: any) => {
        const data = results.pie_query.payload;
        // No result set returned from database
        if (!data) return [];

        const jsonData = JSON.parse(data);
        const tableColumnObj = this.gridService.generateGridRow(this.control);
        let newRows = [];
        jsonData.forEach(row => {
          // Add the keys for the tableColumns into a "row" (ag-grid) object
          let newRow: {[key: string]: string | number} = {};
          // Iterate through the tableRows to append each key value pair ot the newRow object
          this.control.tableRows.forEach(tableRow => {
            const newRowKey = tableRow.key;
            const newRowVal = row[tableRow.dbColMap.key];

            // Append the key/value pair
            const refData = {[newRowVal]: row[tableRow.dbColMap.value]};
            this.refDataColMappings[tableRow.key] = this.refDataColMappings[tableRow.key] ? Object.assign(this.refDataColMappings[tableRow.key], refData) : refData;

            // Add the key for the tableRow and the correct value from the db data row
            newRow[newRowKey] = newRowVal;
          });

          // Merge the tableRows key/value pairs with the tableColumnObj key/value (null) pairs
          newRow = Object.assign(newRow, tableColumnObj);
          newRows.push(newRow);
        });

        // Alter columnDefs
        this.control.tableRows.forEach(tableRow => {
          // Set the refData
          this.gridApi.getColumnDef(tableRow.key).refData = this.refDataColMappings[tableRow.key];

          // In the future, if we are to support editing Dimensions, we would need this:
          // this.gridApi.getColumnDef(tableRow.key).cellEditorParams = {values: Object.keys(this.refDataColMappings[tableRow.key])};
        })

        return newRows;
      })
    )
  }

  getGeneratedRowsPerDimension() {
    return this.control.tableRows.length
      ? this.control.tableRows.map(row => {
        if (row.dimMembers.dbCall.objectID === DbCallType.Sql)
          throw new Error('Dimension table row data collection does not support SQL.');

        if (row.dimMembers.dbCall.objectID === DbCallType.Table)
          throw new Error('Dimension table row data collection does not support Table.');

        return this.dbFacade.getOptionsWithDbFunction(
          row.dimMembers.dbCall,
          this.form,
          this.section
        );
      })
      : of([]);
  }
}
