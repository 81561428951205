import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import * as UsersActions from './users.actions';
import { UsersState } from './users.reducer';
import { selectAuthenticatedUserLoaded, selectIsUserLoading, selectUser, selectUserPrivs, selectUserRoles } from './users.selectors';

@Injectable({
  providedIn: 'root'
})
export class UsersFacade {

  constructor(private readonly store: Store<UsersState>) {}
  
  authenticatedUser$ = this.store.pipe(select(selectUser));

  authenticatedUserPrivs$ = this.store.pipe(select(selectUserPrivs));

  authenticatedUserRoles$ = this.store.pipe(select(selectUserRoles));

  /** Observable that emits true when a user is authenticated. */
  get isAuthenticated(): Observable<boolean> {
    return this.authenticatedUser$.pipe(map(user => !!user));
  }

  /** Observable that emits true when loading the authenticated user. */
  isLoading = this.store.pipe(select(selectIsUserLoading));

  /** Observable that emits true when an authenticated user has been loaded, false if authentication has not been attempted. */
  isAuthenticatedUserLoaded = this.store.pipe(select(selectAuthenticatedUserLoaded));

  loadAuthenticatedUser(): void {
    this.store.dispatch(new UsersActions.LoadAuthenticatedUser());
  }

  logoutUser(): void {
    this.store.dispatch(new UsersActions.LogoutUser());
  }
}
