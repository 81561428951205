import { Favorite } from '@compass/core-data';
import { Action } from '@ngrx/store';

export enum FavoritesActionTypes {
  LOAD_FAVORITES = '[FAVORITES] Load Favorites',
  FAVORITES_LOADED = '[FAVORITES] Favorites Loaded',
  LIKE_ENTITY = '[FAVORITES] Like Entity',
  ENTITY_LIKED = '[FAVORITES] Entity Liked',
  DISLIKE_ENTITY = '[FAVORITES] Dislike Entity',
  ENTITY_DISLIKED = '[FAVORITES] Entity Disliked'
}

export class LoadFavorites implements Action {
  readonly type = FavoritesActionTypes.LOAD_FAVORITES;
}

export class FavoritesLoaded implements Action {
  readonly type = FavoritesActionTypes.FAVORITES_LOADED;
  constructor(public payload: { favorites: Favorite[] }) {}
}

export class LikeEntity implements Action {
  readonly type = FavoritesActionTypes.LIKE_ENTITY;
  constructor(public payload: { id: string; type: string; label: string }) {}
}

export class EntityLiked implements Action {
  readonly type = FavoritesActionTypes.ENTITY_LIKED;
  constructor(public payload: { favorites: Favorite[] }) {}
}

export class DislikeEntity implements Action {
  readonly type = FavoritesActionTypes.DISLIKE_ENTITY;
  constructor(public payload: { id: string; type: string }) {}
}

export class EntityDisliked implements Action {
  readonly type = FavoritesActionTypes.ENTITY_DISLIKED;
  constructor(public payload: { id: string }) {}
}

export type FavoritesActions =
  | LoadFavorites
  | FavoritesLoaded
  | LikeEntity
  | EntityLiked
  | DislikeEntity
  | EntityDisliked;
