import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, NotifyService, PrivilegesService, UsersService } from '@compass/core-data';
import { WINDOW } from '@compass/core-window';
import { environment } from '@env/environment';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  AuthenticatedUserLoaded,
  UsersActionTypes,
  AuthenticatedUserPrivsLoaded,
  LoadAuthenticatedUserPrivs,
  AuthenticatedUserRolesLoaded,
  LoadAuthenticatedUserRoles
} from './users.actions';

@Injectable()
export class UsersEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly usersService: UsersService,
    private readonly privilegesService: PrivilegesService,
    private readonly notifyService: NotifyService,
    @Inject(WINDOW) private readonly window: Window,
    private router: Router
  ) {}

  @Effect()
  loadAuthenticatedUser$: Observable<Action> = this.actions$.pipe(
    ofType(UsersActionTypes.LOAD_AUTHENTICATED_USER),
    switchMap(() => this.usersService.authenticatedUser()),
    switchMap(user => {
      return [
        new AuthenticatedUserLoaded({ user }),
        new LoadAuthenticatedUserPrivs({ user }),
        new LoadAuthenticatedUserRoles({ user })
      ]
    }),
    catchError(error => {
      console.error(error);
      // alert('There was an error validating your user. Please contact your System Administrator or try logging in again.')
      // this.router.navigate(['/login']);
      return of(new AuthenticatedUserLoaded({ user: null }));
    })
  );

  @Effect()
  loadAuthenticatedUserPrivs$: Observable<Action> = this.actions$.pipe(
    ofType(UsersActionTypes.LOAD_AUTHENTICATED_USER_PRIVS),
    switchMap(({payload}: any) => this.privilegesService.loadUserApps(payload.user.usr_id)
      .pipe(map(apps => new AuthenticatedUserPrivsLoaded({privileges: apps})))
    ),
    catchError(error => {
      this.notifyService.notify(error, 'Error');
      return of(new AuthenticatedUserPrivsLoaded({ privileges: null }));
    })
  );

  @Effect()
  loadAuthenticatedUserRoles$: Observable<Action> = this.actions$.pipe(
    ofType(UsersActionTypes.LOAD_AUTHENTICATED_USER_ROLES),
    switchMap(({payload}: any) => this.privilegesService.loadUserRoles(payload.user.usr_id)
      .pipe(map(roles => new AuthenticatedUserRolesLoaded({roles: roles})))
    ),
    catchError(error => {
      this.notifyService.notify(error, 'Error');
      return of(new AuthenticatedUserPrivsLoaded({ privileges: null }));
    })
  );

  @Effect({ dispatch: false })
  logoutUser$: Observable<Action> = this.actions$.pipe(
    ofType(UsersActionTypes.LOGOUT_USER),
    tap(() => {
      // TODO: wipe out any cookies or other local storage
      this.window.open(environment.oauth2.logoutURL, '_self');
    })
  );
}
