<compass-form-control
  *ngFor="let control of componentType.controls; index as i"
  [form]="form"
  [control]="control"
  [section]="section"
  [schema]="schema"
  [value]="getControlValueAtIndex(values | async, i)"
></compass-form-control>

<ng-container *ngIf="componentType.actions && componentType.actions.length > 0">
  <compass-action
    *ngFor="let action of componentType.actions"
    [action]="action"
    [componentType]="componentType"
    [form]="form"
    [section]="section"
    [schema]="schema"
  ></compass-action>
</ng-container>

