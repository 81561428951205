import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { SchemasState } from './schemas.reducer';
import { Schema } from '@compass/core-data';
import { selectSchemas, selectCurrentSchema, selectSchema } from './schemas.selectors';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { LoadSchema } from './schemas.actions';

@Injectable({
  providedIn: 'root'
})
export class SchemasFacade {
  currentSchema$: Observable<Schema>;
  currentSchema: Schema;

  constructor(private readonly store: Store<SchemasState>) {
    this.currentSchema$ = store.pipe(
      select(selectCurrentSchema),
      filter(schema => !!schema),
      tap((schema: Schema) => this.currentSchema = schema)
    );
  }

  loadSchema(fileName: string): Observable<Schema> {
    this.store.dispatch(new LoadSchema(fileName));
    return this.currentSchema$;
  }
}
