import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ComponentReport,
  ControlType,
  DbCallType,
  DbFunction,
  DbTable,
  NotifyService,
  Schema,
  Section,
  FormService
} from '@compass/core-data';
import { parse } from 'papaparse';
import { Subject, merge } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
  debounceTime,
  take
} from 'rxjs/operators';
import { DbFacade } from '@compass/core-state';

@Component({
  selector: 'compass-component-report',
  templateUrl: './component-report.component.html',
  styleUrls: ['./component-report.component.scss']
})
export class ComponentReportComponent implements OnChanges, OnDestroy {
  /** The component which contains a data input control. */
  @Input() componentType: ComponentReport;

  /** The control type is declared so the enum is available in the template. */
  ControlType = ControlType;

  /** The FormGroup instance for the schema. */
  @Input() form: FormGroup;

  @Input() schema: Schema;

  @Input() section: Section;

  /** Control values positionally */
  values = new Subject<Array<string>>();

  /** Subject to trigger unsubcribe to observable streams. */
  private unsubscribe = new Subject<void>();

  constructor(
    private readonly dbFacade: DbFacade,
    private readonly notifyService: NotifyService,
    private readonly formService: FormService
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges.componentType &&
      simpleChanges.componentType.currentValue &&
      this.componentType.dbValues
    ) {
      this.populate();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getControlValueAtIndex(values: string[], index: number): string | null {
    if (!values || (values && values.length < index)) {
      return null;
    }
    return values[index];
  }

  private populate(): void {
    switch (this.componentType.dbValues.dbCall.objectID) {
      case DbCallType.Function:
        this.populateFromFunction();
        break;
      case DbCallType.Sql:
        throw new Error('Populating a data entry component via SQL is not supported.');
      case DbCallType.Table:
        this.populateFromTable();
        break;
    }
  }

  private populateFromTable(): void {
    const dbTable = this.componentType.dbValues.dbCall as DbTable;

    // verify function call has keys
    if (
      !dbTable.keys ||
      (dbTable.keys && dbTable.keys.length === 0)
    ) {
      return;
    }

    // unsubscribe from previous value changes observables
    this.unsubscribe.next();

    // set initial values
    this.setFormValuesUsingTable(dbTable);

    // watch for future value changes on dependent controls
    const observables = dbTable.keys
      .filter(key => this.formService.getFormControl(key, this.form, this.schema, this.section))
      .map(key => {
        const control = this.formService.getFormControl(key, this.form, this.schema, this.section);
        return control.valueChanges
          .pipe(
            filter(val => !!val),
            distinctUntilChanged(),
            takeUntil(this.unsubscribe)
          )
      });

      merge(...observables)
        .pipe(debounceTime(100))
        .subscribe(() => this.setFormValuesUsingTable(dbTable));
  }

  private populateFromFunction(): void {
    const dbFunction = this.componentType.dbValues.dbCall as DbFunction;

    // verify function call has keys
    if (
      !dbFunction.functionCall.keys ||
      (dbFunction.functionCall.keys && dbFunction.functionCall.keys.length === 0)
    ) {
      return;
    }

    // unsubscribe from previous value changes observables
    this.unsubscribe.next();

    // set initial values
    this.setFormValuesUsingDbFunction(dbFunction);

    // watch for future value changes on dependent controls
    const observables = dbFunction.functionCall.keys
      .filter(key => this.formService.getFormControl(key, this.form, this.schema, this.section))
      .map(key => {
        const control = this.formService.getFormControl(key, this.form, this.schema, this.section);
        return control.valueChanges
          .pipe(
            filter(val => !!val),
            distinctUntilChanged(),
            takeUntil(this.unsubscribe)
          )
      });

      merge(...observables)
        .pipe(debounceTime(100))
        .subscribe(() => this.setFormValuesUsingDbFunction(dbFunction));
  }

  private setFormValuesUsingTable(dbTable: DbTable): void {
    // get parameters
    const parameters = this.dbFacade.getParametersWithTableName(dbTable, this.form, this.schema, this.section);

    // do not set form values if the parameters are undefined
    if (!parameters || !parameters.length) return;

    // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
    if (dbTable.staticKeys) {
      dbTable.staticKeys.forEach((staticKey: any) => {
        parameters.push( [staticKey.key, staticKey.value]);
      });
    }
      
    const arr = ['nothing'];

    this.dbFacade
      .tbl(dbTable, arr, parameters)
      .pipe(
        take(1),
        map(result => {
          const rows = result['pie_query'];
          const keys = Object.keys(rows);
          return keys.map(key => rows[key]).map(value => parse(value, { delimiter: ',' }));
        }),
        map(results => {
          // currently, we only care about the first result
          return results[0];
        })
        // Removing filter so that subscribe can set empty value if no data returned from db
        // filter(parsed => parsed && parsed.data && parsed.data.length > 0)
      )
      .subscribe(parsed => {
        if (parsed && parsed.data && parsed.data.length > 0) {
          this.values.next(parsed.data[0]);
        } else {
          this.values.next([null]);
        }

      });
  }

  private setFormValuesUsingDbFunction(dbFunction: DbFunction): void {
    // get parameters
    let parameters = this.dbFacade.getParametersWithDbFunction(dbFunction, this.form, this.schema, this.section);

    // do not set form values if the parameters are undefined
    if (!parameters || !parameters.length) return;

    // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
    if (dbFunction.functionCall.staticKeys) {
      dbFunction.functionCall.staticKeys.forEach((staticKey: any) => {
        parameters.push( [staticKey.key, staticKey.value]);
      });
    }

    this.dbFacade
      .fn(dbFunction, parameters)
      .pipe(
        take(1),
        map(result => {
          const rows = result['pie_query'];
          const keys = Object.keys(rows);
          return keys.map(key => rows[key]).map(value => parse(value, { delimiter: ',' }));
        }),
        map(results => results[0]) // currently, we only care about the first result
      )
      .subscribe(parsed => {
        if (parsed && parsed.data && parsed.data.length > 0) {
          this.values.next(parsed.data[0]);
        } else {
          this.values.next([null]);
        }

      });
  }

}
