import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentUpload, SchemaService } from '@compass/core-data';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { from, of } from 'rxjs';
import { Observable } from 'rxjs';
import { map, catchError, delay, concatMap } from 'rxjs/operators';

@Component({
  selector: 'compass-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
  
  /** The component which contains a data input control. */
  @Input() componentType: ComponentUpload;

  progressInfos: any[] = [];

  public files: NgxFileDropEntry[] = [];

  /** The accepted MIME types. */
  MIME_TYPES = 'application/json';

  constructor(
    private readonly schemaService: SchemaService,
    public cd: ChangeDetectorRef
  ) {}

  onFileDrop(files: NgxFileDropEntry[]) {
    this.files = files;
    const promises = this.files.map((uploadFile) => this.extractFileFromUpload(uploadFile));
    Promise.all(promises).then(
      (result) => {

        const observableArr = from(result).pipe(
          concatMap((file, index) => this.uploadFile(index, file))
        ).subscribe(
          (res) => {},
          (error) => console.error(error),
          () => {
            this.cd.detectChanges();
            this.progressInfos = [];
          }
        );
      },
      (error) => {
        console.error(error)
      }
    );
  }

  // File contents lives in a callback. Return it wrapped in a prmise.
  extractFileFromUpload(file: NgxFileDropEntry): Promise<any> {
    const fileEntry = file.fileEntry as FileSystemFileEntry;

    return new Promise((resolve, reject) => {
      fileEntry.file((file: File) => {
        resolve(file);
      });
    });
  }

  // Call the db service to create the schema
  uploadFile(idx: number, uploadFile: File): Observable<any> {
    // Add schema to progressInfos array
    this.progressInfos[idx] = { value: 0, fileName: uploadFile.name };

    return this.schemaService.createSchema(uploadFile).pipe(
      delay(100),
      catchError(err => {
        console.error(err);
        // Update the schema's result (message below progress bar)
        this.progressInfos[idx].result = err;
        return of();
      }),
      map(res => {
        // Update the schema's value to 100 (full progress bar)
        this.progressInfos[idx].value = 100;
        // Update the schema's result (message below progress bar)
        this.progressInfos[idx].result = 'Successfully uploaded!';
      })
    )
  }
}