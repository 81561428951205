import gql from 'graphql-tag';

export const fnQuery = gql`
  query fnQuery($connection: String, $name: String, $parameters: [[String]]) {
    fn(connection: $connection, name: $name, parameters: $parameters)
  }
`;

export const tblQuery = gql`
  query tblQuery($connection: String, $tableName: String, $columnNames: [String], $parameters: [[String]], $sortColumns: [SortColumnInput]) {
    tbl(connection: $connection, tableName: $tableName, columnNames: $columnNames, parameters: $parameters, sortColumns: $sortColumns)
  }
`;
