const endpoint = 'https://compass-api.dev.bsci.com';
export const environment = {
  baseEndpoint: endpoint,
  endpoint: `${endpoint}/graphql`,
  aws: {
    Auth: {
      region: 'us-east-1',
      identityPoolId: 'us-east-1:2a0c969a-ce6d-4584-8ef7-e18375371e58',
      userPoolId: 'us-east-1_CBabHyzPK',
      userPoolWebClientId: '17tedurhlcd4jb0gfflkr996hs'
    },
    S3: {
      AWSS3: {
        bucket: 'bsc-budget-tool-development',
        region: 'us-east-1'
      }
    }
  },
  oauth2: {
    authenticateURL: `${endpoint}/oauth2/authenticate`,
    logoutURL: `${endpoint}/oauth2/logout`
  },
  localstorage: {
    persist: 'cbt',
    url: 'url',
    persistedColFilters: 'persistedColFilters',
    persistedColSorts: 'persistedColSorts',
  },
  production: false,
  borderColor: "green"
};
