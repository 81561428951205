import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './ui.reducer';

export const selectUiState = createFeatureSelector<State>('ui');

export const isSidenavHidden = createSelector(
  selectUiState,
  state => state.sidenavHidden
);
