export enum ComponentType {
  Carousel = 'Gallery',
  DataEntry = 'Data Entry',
  HTML = 'HTML',
  Tableau = 'Tableau',
  Report = 'Report',
  Upload = 'Upload',
  UserRolesList = 'UserRolesList',
  RoleAppsList = 'RoleAppsList',
  Chart = 'Chart',
  FileUpload = 'FileUpload',
  Informational = 'Informational'
}
