import { Component } from '@angular/core'
import { AgEditorComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-grid-material-aggvalue-editor',
  template: `
    <input id="aggValueCell" [(ngModel)]="value" type="number" (blur)="onBlurInput()">
  `,
  styles: [
    `
    input {
      width: 100%;
      height: 100%;
    }
    `
  ]
})
export class GridEditAggValuesComponent implements AgEditorComponent {
  columnWidth: string;
  params: any;
  value: string;

  isPopup(): boolean {
    return false;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    
    // handle json
    if (params.value && params.value.hasOwnProperty('value')) {
      this.value = params.value['value'];
    } else {
      this.value = params.value;
    }
  }

  // Should return the final value to the grid, the result of the editing
  getValue(): string {
    const colId = this.params.column.getColId();
    const currentVal = this.params.api.getValue(colId, this.params.node);

    if (this.value === currentVal || !this.params.node.hasChildren()) {
      return this.value;
    }

    const valName = this.params.colDef.pivotValueColumn.colId;

    let childNode;
    this.params.colDef.pivotKeys.forEach((key) => {    
      childNode = childNode ? childNode[key] : this.params.node.childrenMapped[key]
    });

    // [RowNode]
    childNode[0].data[valName] = this.value;
    this.params.node.aggData[colId] = this.value;

    return this.value;
  }

  onBlurInput(): void {
    this.params.stopEditing()
  }
}
