import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';

import { Role } from './roles.model';
import {
  loadRolesQuery,
  createRoleMutation,
  updateRoleMutation,
  deleteRoleMutation
} from './roles.graphql';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  constructor(private apollo: Apollo) { }

  load(): Observable<Role[]> {
    return this.apollo
      .query({
        query: loadRolesQuery,
        fetchPolicy: 'network-only',
      }).pipe(
        map((response: ApolloQueryResult<any>) => {
          return response.data.loadRoles;
        })
      );
  }

  create(input: Role): Observable<Role> {
    delete input.rol_id;
    return this.apollo
      .mutate({
        mutation: createRoleMutation,
        variables: {
          input
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.createRole)
      );
  }

  update(patch: Role): Observable<Role> {
    return this.apollo
      .mutate({
        mutation: updateRoleMutation,
        variables: {
          patch
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.updateRole)
      );
  }

  delete(rol_id: number): Observable<Role> {
    return this.apollo
      .mutate({
        mutation: deleteRoleMutation,
        variables: {
          rol_id
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.deleteRole)
      );
  }
}
