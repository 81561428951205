import { UiAction, UiActionTypes } from './ui.actions';

export interface State {
  sidenavHidden: boolean;
}

export const initialState: State = {
  sidenavHidden: false
};

export function uiReducer(state = initialState, action: UiAction) {
  switch (action.type) {
    case UiActionTypes.HIDE_SIDENAV:
      return { ...state, sidenavHidden: true };

    case UiActionTypes.SHOW_SIDENAV:
      return { ...state, sidenavHidden: false };

    default:
      return state;
  }
}
