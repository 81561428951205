import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ControlTable, TableType, Section, Schema } from '@compass/core-data';
import { CellValueChangedEvent } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'compass-control-table',
  templateUrl: './control-table.component.html',
  styleUrls: ['./control-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ControlTableComponent implements OnInit {
  /** The table control to display. */
  @Input() control: ControlTable;

  @Input() section: Section;

  @Input() schema: Schema;

  /** The FormGroup instance for the schema. */
  @Input() form: FormGroup;

  /** Define the TableType enum for use in the template. */
  TableType = TableType;

  gridId: string;

  style = new BehaviorSubject<Object>(
    {
      width: '100%'
    }
  );

  ngOnInit() {
    this.gridId = this.control.id + '-grid';
  }

  setGridWidth(calcGridWidth) {
    if (this.control.pivotOn) return;

    // If tableFit set to false in the schema, exit.
    if ('tableFit' in this.control && !this.control.tableFit) return;

    this.style.next(
      {
        'max-width': `${calcGridWidth}px`
      }
    );
  }
}
