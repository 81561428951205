<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{ control.title }}</mat-label>
    <input
      type="text"
      [formControlName]="relationalKey"
      [value]="value"
      class="shadow-dateInput">
    <input
      type="text"
      matInput
      formControlName="{{searchControlKey}}"
      [placeholder]="control.title"
      aria-label="Number"
      [matAutocomplete]="auto"
      (blur)="onBlur($event)"
      [value]="selectedValue"
      (click)="$event.target.select()">
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
    <mat-autocomplete
      #auto="matAutocomplete"
      #autocomplete
      [displayWith]="displayValue"
      (optionSelected)='optionSelected($event.option.value)'
      (opened)="panelOpened()"
      (closed)="panelClosed()"
    >
      <mat-option *ngFor="let option of (filteredOptions | async)"
        id="{{option.value}}"
        [value]="option"
        [class]="option.value == formControl.value ? 'mat-active mat-selected' : ''"
      >
        {{option.label}}
      </mat-option> 
    </mat-autocomplete>
    <mat-error *ngIf="searchControl.invalid">Not a valid selection</mat-error>
  </mat-form-field>
</form>
