export interface Role {
  rol_id: number;
  rol_name: string;
  rol_desc: string;
}

export const emptyRole: Role = {
  rol_id: null,
  rol_name: '',
  rol_desc: ''
}
