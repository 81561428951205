export interface Baseline {
  bas_id: number;
  bas_name: string;
  bas_desc: string;
  base_bas_id: number;
  base_bas_sort: number;
  bas_final: boolean;
  bas_active: boolean;
  bas_sys_active: boolean;
  bas_sys_ts: string;
  bas_sys_usr: string;
  bas_sys_desc: string;
}

export const emptyBaseline: Baseline = {
  bas_id: null,
  bas_name: '',
  bas_desc: '',
  base_bas_id: null,
  base_bas_sort: null,
  bas_final: false,
  bas_active: false,
  bas_sys_active: false,
  bas_sys_ts: '',
  bas_sys_usr: '',
  bas_sys_desc: '',
}
