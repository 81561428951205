<mat-card>
  <h3 mat-subheader>Upload Schema</h3>
  <ngx-file-drop
    #fileDrop
    showBrowseBtn="true"
    browseBtnLabel="Browse Files"
    browseBtnClassName="compass-upload-button"
    dropZoneClassName="compass-upload-dropzone"
    contentClassName="compass-upload-content"
    [accept]="MIME_TYPES"
    (onFileDrop)="onFileDrop($event)"
  >
    <mat-icon>cloud_upload</mat-icon>
    <h1>DRAG & DROP</h1>
    <p>your files to upload, or</p>
  </ngx-file-drop>
</mat-card>

<mat-card *ngFor="let file of progressInfos">
  <h3 mat-subheader>{{ file.fileName }}</h3>
  <mat-card-subtitle>
    <mat-progress-bar
      mode="determinate"
      value="{{ file.value }}"
    ></mat-progress-bar>
  </mat-card-subtitle>
  <mat-card-content><div [innerHTML]="file.result"></div></mat-card-content>
</mat-card>
