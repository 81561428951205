<mat-card>
  <mat-card-header>
    <mat-card-title>
      Trending Dashboards
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="views">
      <mat-list *ngIf="getFirstColumnViews().length > 0; else noViews" class="col">
        <mat-list-item
          *ngFor="let view of getFirstColumnViews()"
          class="mat-list-option"
          [routerLink]="['/dashboard', view.viewId]"
          routerLinkActive="router-link-active"
        >
          <img mat-list-icon class="thumbnail-image" [src]="sanitize(view.thumbnailUrl)" />
          <h3 mat-line>{{ view.name }}</h3>
          <p mat-line>{{view.workbookName}}</p>
        </mat-list-item>
      </mat-list>
      <mat-list class="col">
        <mat-list-item
          *ngFor="let view of getSecondColumnViews()"
          class="mat-list-option"
          [routerLink]="['/dashboard', view.viewId]"
          routerLinkActive="router-link-active"
        >
          <img mat-list-icon class="thumbnail-image" [src]="sanitize(view.thumbnailUrl)" />
          <h3 mat-line>{{ view.name }}</h3>
          <p mat-line>{{view.workbookName}}</p>
        </mat-list-item>
      </mat-list>
    </ng-container>
    <ng-template #noViews>
      <em>No dashboards to display.</em>
    </ng-template>
  </mat-card-content>
</mat-card>
