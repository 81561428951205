import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, pairwise, startWith } from 'rxjs/operators';
import { Section, Schema, DbCallType, Control, Db } from '../../schema';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  form: FormGroup;
  public formChanges$: Observable<[object, object]>;

  setForm(form: FormGroup) {
    this.form = form;

    this.formChanges$ = this.form.valueChanges.pipe(
      startWith([{}]),
      debounceTime(100),
      filter((val: object) => Object.keys(val).length > 0),
      distinctUntilChanged((a, b) => isEqual(a, b)),
      pairwise()
    );
  }

  getChangesForControl(
    control: Control,
    form: FormGroup,
    schema: Schema,
    section: Section
  ): Observable<object> {
    const keys = this.getRelatedFormKeys(control, form, schema, section);
    return this.getChangesForKeys(keys);
  }

  getChangesForKeys(keys: string[]): Observable<object> {
    return this.formChanges$.pipe(
      debounceTime(100),
      filter(
        ([oldValue, newValue]) => {
          return keys.length > 0 &&
          // Leaving the below commented out. Is there a scenario where we want to ignore new or old values that are null?
          // keys.every(key => newValue[key]) &&
          // keys.every(key => oldValue[key]) &&
          keys.some(key => newValue[key] !== oldValue[key])
        }
      )
    );
  }

  getForm(): FormGroup {
    return this.form;
  }

  getFormControl(key: string, form: FormGroup, schema: Schema, section?: Section): AbstractControl {
    return form.get(this.generateRelationalKey(key, form, schema, section));
  }

  generateRelationalKey(key: string, form: FormGroup, schema: Schema, section?: Section): string {
    if (!schema && !section) {
      console.warn(`Missing schema and section for form control ${key}.`);
    }
    if (section && form.controls.hasOwnProperty(`${section.id}_${key}`)) {
      return `${section.id}_${key}`;
    } else if (schema && form.controls.hasOwnProperty(`${schema.id}_${key}`)) {
      return `${schema.id}_${key}`;
    }

    return key;
  }

  getRelatedFormKeys(
    control: Control,
    form: FormGroup,
    schema: Schema,
    section: Section
  ): string[] {
    let db: Db;
    if ('items' in control && 'dbCall' in control.items) {
      db = control.items;
    } else if ('dbValues' in control) {
      db = control.dbValues;
    } else {
      return [];
    }
    const dbCall = db.dbCall;

    switch (dbCall.objectID) {
      case DbCallType.Function:
        if (!dbCall.functionCall.keys) return [];
        return dbCall.functionCall.keys.map((key: string) =>
          this.generateRelationalKey(key, form, schema, section)
        );
      case DbCallType.Table:
        if (!dbCall.keys && !dbCall.getDataSet) return [];

        let keys = dbCall.keys;
        if (!keys && dbCall.getDataSet) {
          keys = dbCall.getDataSet.functionCall.keys;
        }
        return keys.map((key: string) => this.generateRelationalKey(key, form, schema, section));
      default:
        return [];
    }
  }

  getRelatedFormControls(
    control: any,
    form: FormGroup,
    schema: Schema,
    section: Section
  ): Array<AbstractControl> {
    let relatedControls: Array<AbstractControl> = [];
    const dbCall = control.dbValues?.dbCall || control.items?.dbCall;

    switch (dbCall.objectID) {
      case DbCallType.Function:
        if (!dbCall.functionCall.keys) break;

        dbCall.functionCall.keys.forEach(key => {
          const control = this.getFormControl(key, form, schema, section);
          if (control && control.statusChanges) {
            relatedControls.push(control);
          }
        });
        break;

      case DbCallType.Table:
        if (!dbCall.keys && !dbCall.getDataSet) break;

        const keys = dbCall.getDataSet ? dbCall.getDataSet.functionCall.keys : dbCall.keys;

        keys.forEach(key => {
          const control = this.getFormControl(key, form, schema, section);
          if (control && control.statusChanges) {
            relatedControls.push(control);
          }
        });
        break;
    }

    return relatedControls;
  }

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }
}
