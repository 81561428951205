<div [formGroup]="form">
  <input matInput
    class="shadow-dateInput"
    [matTooltip]="control.description"
    [formControlName]="relationalKey"
  >
  <mat-form-field 
    appearance="outline"
    class="form-field-small"
  >
    <mat-label>{{control.title}}</mat-label>
    <input matInput
      #dateInput
      [placeholder]="dateService.dateFormat"
      [formControl]="dateFormControl"
      [matDatepicker]="datePicker"
      (dateChange)="dateChange($event.value)"
    >
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <ng-container *ngIf="dateService.displayCalendarDays; else yearCalendar">
      <mat-datepicker
        #datePicker
        startView="month"
      ></mat-datepicker>
    </ng-container>
    <ng-template #yearCalendar>
      <mat-datepicker
        #datePicker
        panelClass="hide-calendar-button"
        startView="multi-year"
        (monthSelected)="monthSelected($event, datePicker)"
      ></mat-datepicker>
    </ng-template>
  </mat-form-field>
</div>
