import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  ComponentChart,
  DbFunction,
  FormService,
  RefreshService,
  Schema,
  Section
} from '@compass/core-data';
import { DbFacade } from '@compass/core-state';
import Chart from 'chart.js';
import { ChartConfiguration, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { isEmpty } from 'lodash';
import { BaseChartDirective, Label } from 'ng2-charts';
import { parse } from 'papaparse';
import { merge, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  take,
  takeUntil
} from 'rxjs/operators';

// plugin to display message when there is no data
Chart.plugins.register({
  afterDraw: function(chart) {
    if (chart.data.datasets?.length === 0) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      chart.clear();

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('No Data To Show', width / 2, height / 2);
      ctx.restore();
    }
  }
})

@Component({
  selector: 'compass-js-chart',
  templateUrl: './component-js-chart.component.html',
  styleUrls: ['./component-js-chart.component.scss'],
  host: {
    class: 'compass-js-chart'
  }
})
export class ComponentJsChartComponent implements OnInit, AfterViewInit {
  @Input() id: string;
  @Input() componentType: ComponentChart;
  @Input() form: FormGroup;
  @Input() schema: Schema;
  @Input() section: Section;

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  private chartConfiguration: ChartConfiguration = {};

  private unsubscribe = new Subject<void>();

  /** Form Controls that have key referenced by the chart */
  relatedFormControls: Array<AbstractControl> = [];

  constructor(
    private readonly formService: FormService,
    private readonly dbFacade: DbFacade,
    private readonly refreshService: RefreshService,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.relatedFormControls = this.formService.getRelatedFormControls(
      this.componentType,
      this.form,
      this.schema,
      this.section
    );
  }

  ngAfterViewInit() {
    this.refreshService.refreshComponent(this, this.id);
    this.populate();
  }

  private setFormValuesUsingDbFunction(dbFunction: DbFunction, refresh?: boolean) {
    let parameters = this.dbFacade.getParametersWithDbFunction(
      dbFunction,
      this.form,
      this.schema,
      this.section
    );

    if (!parameters || !parameters.length) {
      return (this.chartConfiguration = {});
    }

    const missingRequiredParams = this.relatedFormControls ? this.relatedFormControls.find(formControl => {
      const valueRequired = this.formService.isControlRequired(formControl);
      const missingValue = isEmpty(formControl.value);
      return valueRequired && missingValue;
    }) : false;

    // Check that parameters (relatedFormControls) all have values
    if (missingRequiredParams) {
      // console.error(`Missing required parameter(s) for chart (title: '${this.componentType.title}').`);
      return this.chartConfiguration = {};
    }

    this.dbFacade
      .fn(dbFunction, parameters)
      .pipe(
        take(1),
        map(result => {
          const rows = result['pie_query'];
          const keys = Object.keys(rows);
          return keys
            .map(key => rows[key])
            .map(value => {
              try {
                const parsed = parse(value, { delimiter: ',' });
              
                // Avoid JSON.parse error when parsed.data == [""]. This happens when value == """".
                // TODO - display a "No Data" message on the chart
                if (parsed.data[0].includes("") || (Array.isArray(parsed.data[0]) && parsed.data[0][0].includes("\"datasets\": \n"))) {
                  return {};
                }

                const data = JSON.parse(parsed.data[0]);
                return data;
              } catch(error) {
                console.error(error);
                return {};
              }
              
            });
        }),
        map(results => results[0])
      )
      .subscribe(parsed => {
        this.chartConfiguration = parsed

        // ng2-charts has some bugs on updating data; manually reset data and create chart again
        if (refresh && this.chart != undefined) {
          
          this.chart.ngOnDestroy();
          this.chart.datasets = this.chartConfiguration.data.datasets;
          this.chartConfiguration.data.datasets.forEach((dataset: any) => this.chart.data.push(dataset.data));
          this.chart.chart = this.chart.getChartBuilder(this.chart.ctx);
        }

        // Force change detection to render the chart
        this.cd.detectChanges();
      });
  }

  get chartType(): ChartType {
    return this.chartConfiguration.type ? (this.chartConfiguration.type as ChartType) : 'line';
  }

  get datasets(): ChartDataSets[] {
    return this.chartConfiguration.data ? this.chartConfiguration.data.datasets : [];
  }

  get labels(): Label[] {
    return this.chartConfiguration.data ? (this.chartConfiguration.data.labels as Label[]) : [];
  }

  get chartOptions(): ChartOptions {
    return this.chartConfiguration.options ? this.chartConfiguration.options : {};
  }

  populate(refresh?: boolean): void {
    const dbFunction = this.componentType.dbValues.dbCall as DbFunction;

    if (
      !dbFunction.functionCall.keys ||
      (dbFunction.functionCall.keys && dbFunction.functionCall.keys.length === 0)
    ) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.setFormValuesUsingDbFunction(dbFunction, refresh);

    const observables = dbFunction.functionCall.keys
      .filter(key => this.formService.getFormControl(key, this.form, this.schema, this.section))
      .map(key => {
        const control = this.formService.getFormControl(key, this.form, this.schema, this.section);
        return control.valueChanges.pipe(
          filter(val => !!val),
          distinctUntilChanged(),
          takeUntil(this.unsubscribe)
        );
      });

    merge(...observables)
      .pipe(debounceTime(100))
      .subscribe(() => this.setFormValuesUsingDbFunction(dbFunction));
  }
}
