import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Section, Schema } from '@compass/core-data';

@Component({
  selector: 'compass-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent {
  @Input() form: FormGroup;

  @Input() section: Section;

  @Input() schema: Schema;

  @Input() dspMatCardTitle: boolean;
}
