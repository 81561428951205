import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, zip } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

import { UiFacade, UsersFacade } from '@compass/core-state';
import { sideNavAnimation, sideNavContainerAnimation } from './core/animations/sidenav.animations';
import { environment } from '../environments/environment';
import { User } from '@compass/core-data';

declare let pendo: any;

@Component({
  selector: 'compass-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [sideNavAnimation, sideNavContainerAnimation]
})
export class AppComponent implements OnInit {
  isSidenavHidden$ = this.uiFacade.isSidenavHidden$;
  isAuthenticated$ = this.userFacade.isAuthenticated;
  isLoading$ = this.userFacade.isLoading;
  title = 'Compass';
  subMenuShown = false;
  
  links$ = new BehaviorSubject<Array<any>>([{
    path: '/home',
    icon: 'home',
    label: 'Home',
    fileName: 'compass_home.json'
  }]);

  constructor(
    private readonly userFacade: UsersFacade,
    private readonly uiFacade: UiFacade,
    private router: Router
  ) {}

  ngOnInit() {
    // Get user, their apps and roles (NOTE: these could all be refactored into a single gql query)
    const userObs = this.userFacade.authenticatedUser$.pipe(filter(user => !!user),first());
    const userAppsObs = this.userFacade.authenticatedUserPrivs$.pipe(filter(privs => !!privs),first());
    const userRolesObs = this.userFacade.authenticatedUserRoles$.pipe(first());

    // Zip these datasets together
    zip(userObs, userAppsObs, userRolesObs)
    .subscribe(results => {
      const user: User = results[0];
      const userApps = results[1];
      const userRoles = results[2];

      // Populate menu with apps that user has access to
      const links = userApps.map(element => {
        return {
          path: `apps/${element.app_foldername}`,
          label: element.app_name,
          icon: element.app_icon,
          id: element.app_id,
          fileName: element.app_filename,
          title: element.app_name,
          folder: element.app_foldername,
          app: element.app_foldername
        }
      });
      this.links$.next([...this.links$.getValue(), ...links]);

      // Initialize pendo agent
      // pendo.initialize({
      //   visitor: {
      //       id: user.usr_name,   // Required if user is logged in, default creates anonymous ID
      //       email: user.usr_email,       // Recommended if using Pendo Feedback, or NPS Email
      //       full_name: user.usr_firstname || ' ' || user.usr_lastname,   // Recommended if using Pendo Feedback
      //       // role: 'user',    // Optional
            
      //       // You can add any additional visitor level key-values here,
      //       // as long as it's not one of the above reserved names.
      //       apps: userApps.map(app => app.app_name),
      //       roles: userRoles
      //   },
      //   account: {
      //       id: 'Compass', // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      //       name: 'Compass'      // Optional
      //       // is_paying:    // Recommended if using Pendo Feedback
      //       // monthly_value:// Recommended if using Pendo Feedback
      //       // planLevel:    // Optional
      //       // planPrice:    // Optional
      //       // creationDate: // Optional

      //       // You can add any additional account level key-values here,
      //       // as long as it's not one of the above reserved names.
      //   }
      // });
    }, error => console.error(error));
  }

  expandSubMenu(): void {
    this.subMenuShown = !this.subMenuShown;
  }

  isActiveLink(link: any) {
    return (
      this.router.isActive(link.path, true) ||
      (link.children && link.children.some((child: any) => this.router.isActive(child.path, true)))
    );
  }

  hideSidenav() {
    this.uiFacade.hideSidenav();
  }

  showSidenav() {
    this.uiFacade.showSidenav();
  }

  envBorderStyle() {
    if (environment.borderColor) {
      return {
        'border-style': 'solid',
        'border-color': environment.borderColor,
        'height': '100%'
      };
    }
  }
}
