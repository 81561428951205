import { User, Favorite } from '@compass/core-data';

import { FavoritesActionTypes, FavoritesActions } from './favorites.actions';

export interface FavoritesState {
  isLoading: boolean;
  favorites: Favorite[] | null;
}

export const initialState: FavoritesState = {
  isLoading: false,
  favorites: null
};

export function favoritesReducer(state = initialState, action: FavoritesActions): FavoritesState {
  switch (action.type) {
    case FavoritesActionTypes.LOAD_FAVORITES:
      return {
        ...state,
        isLoading: true
      };

    case FavoritesActionTypes.FAVORITES_LOADED:
      return {
        ...state,
        favorites: action.payload.favorites,
        isLoading: false
      };
    case FavoritesActionTypes.ENTITY_LIKED:
      return {
        ...state,
        favorites: action.payload.favorites,
        isLoading: false
      };
    case FavoritesActionTypes.ENTITY_DISLIKED:
      const index = state.favorites.findIndex(favorite => favorite.id === action.payload.id);
      return {
        ...state,
        favorites: state.favorites
          .slice(0, index)
          .concat(state.favorites.slice(index + 1, state.favorites.length)),
        isLoading: false
      };
    default:
      return state;
  }
}
