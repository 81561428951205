import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@compass/core-window';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor {
  constructor(@Inject(WINDOW) private readonly window: Window) {}

  checkAuthorization(event: HttpResponse<any>) {
    if (event.body && event.body.errors && event.body.errors[0].message.statusCode === 401) {
      // Set the URL to redirect to after login
      this.window.localStorage.setItem(environment.localstorage.url, window.location.pathname);

      // this.usersFacade.logoutUser();
      this.window.open(environment.oauth2.authenticateURL, '_self');
    }
  }

  logError(error: HttpErrorResponse) {
    console.log('---- ERROR RESPONSE START ----');
    console.error('STATUS', error.status);
    console.error('MESSAGE', error.message);
    console.log('---- ERROR RESPONSE END ----');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            this.checkAuthorization(event);
          }
        },
        error => {
          this.logError(error);
        }
      )
    );
  }
}
