import { UserRole } from '../privileges/privileges.model'

export interface User {
  usr_id: number;
  usr_name: string;
  usr_desc?: string;
  usr_firstname?: string;
  usr_lastname?: string;
  usr_email?: string;
  usr_active?: boolean;
  roles?: [UserRole];
  usr_uuid?: string;
  usr_created?: string;
  usr_updated?: string;
}

export const emptyUser: User = {
  usr_id: null,
  usr_name: '',
  usr_desc: '',
  usr_firstname: '',
  usr_lastname: '',
  usr_email: '',
  usr_active: true,
  roles: [null],
  usr_uuid: null,
  usr_created: '',
  usr_updated: ''
};
