import gql from 'graphql-tag';

export const favoritesFragment = gql`
  fragment favoritesFragment on TableauFavoritesDTO {
    type
    label
    id
    image
  }
`;

export const getFavorites = gql`
  query GetFavorites {
    getFavorites {
      ...favoritesFragment
    }
  }
  ${favoritesFragment}
`;

export const likeEntity = gql`
  mutation LikeEntity($input: LikeEntityInput!) {
    likeEntity(input: $input) {
      ...favoritesFragment
    }
  }
  ${favoritesFragment}
`;

export const dislikeEntity = gql`
  mutation DislikeEntity($input: DislikeEntityInput!) {
    dislikeEntity(input: $input) {
      success
    }
  }
`;
