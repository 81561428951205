import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { UsersService } from '@compass/core-data';
import { UsersFacade } from '@compass/core-state';
import { WINDOW } from '@compass/core-window';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly usersFacade: UsersFacade,
    private usersService: UsersService,
    private router: Router,
    @Inject(WINDOW) private readonly window: Window,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // First check NGRX store for user
    const user = this.usersFacade.authenticatedUser$.pipe(
      switchMap(user => {
        if (user) return of(true);

        // No user in the store, kick off the load user dispatch
        this.usersFacade.loadAuthenticatedUser();
        return this.usersService.authenticatedUser();
      }),
      switchMap(user => {
        if (!user) {
          // store current URL in localstorage
          this.window.localStorage.setItem(environment.localstorage.url, state.url);

          // this.router.navigate(['/login']);

          // redirect to OAuth2 authentication URL
          this.window.open(environment.oauth2.authenticateURL, '_self');
          return of(false);
        }
        return of(true);
      })
    );

    return user;
  }
}
