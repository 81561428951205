import { Component, ViewChild, ViewContainerRef } from '@angular/core'
import { AgEditorComponent } from 'ag-grid-angular'

export interface Option {
  value: string,
  label: string
}

@Component({
  selector: 'app-ag-grid-autocomplete-editor',
  template: `
    <mat-form-field>
      <input matInput #cInput
          type="text" (ngModel)="inputText"
          name="autocompleteControl" (ngModelChange)='inputTextChanged($event)'
          [matAutocomplete]="auto" #trigger="matAutocompleteTrigger">
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayValue">
      <mat-option *ngFor="let option of filteredOptions" [value]="option"
          (onSelectionChange)="optionSelected(option)">
          {{ option.label }}
      </mat-option>
    </mat-autocomplete>
  `,
  styles: [`
    ::ng-deep .cdk-overlay-pane {
        /* Do you changes here */
        position: fixed; // <- only this one is crucial
        z-index: 1000;
        background:white
    }
  `]
})
export class GridAutoCompleteComponent implements AgEditorComponent {
  // @ViewChild('container', {read: ViewContainerRef}) public container;
  @ViewChild('cInput', { static: true }) public cInput;

  options: Option[];

  filteredOptions: Option[];

  inputText: string;

  selectedValue: Number | String;

  agInit(params: any): void {
    if (params.value) {
      this.selectedValue = params.value;
    }

    this.options = params.options.get(params.colDef.field);
    this.filteredOptions = this.options;

    // Focus on input (Open the popup)
    setTimeout(() => {
      this.cInput.nativeElement.focus();
    }, 100);
  }

  // How to display the options in the list
  displayValue(option) {
    return option ? option.label : null;
  }

  // Value to display in the grid
  getValue() {
    return this.selectedValue;
  }

  // Not a (ag-grid) popup
  isPopup?(): boolean { return false; }

  optionSelected(option) {
    this.selectedValue = option ? option.value : null;
  }

  // Filter the list
  inputTextChanged(searchString: string) {
    if (!searchString || typeof searchString !== 'string') {
      this.filteredOptions = this.options;
      return;
    }

    // Reduce list to options whose labels contain the search string
    this.filteredOptions = this.options.filter((x: Option) => x.label.toLowerCase().includes(searchString.toLowerCase()))
  }


}
