import { User, Privilege, Role } from '@compass/core-data';

import { UsersActionTypes, UsersActions } from './users.actions';

export interface UsersState {
  authenticatedUserLoaded: boolean;
  isLoading: boolean;
  user: User | null;
  privileges: any[] | null;
  roles: any[] | null;
}

export const initialState: UsersState = {
  authenticatedUserLoaded: false,
  isLoading: false,
  user: null,
  privileges: null,
  roles: null
};

export function usersReducer(state = initialState, action: UsersActions): UsersState {
  switch (action.type) {
    case UsersActionTypes.AUTHENTICATED_USER_LOADED:
      return {
        ...state,
        authenticatedUserLoaded: true,
        isLoading: false,
        user: action.payload.user
      };

    case UsersActionTypes.LOAD_AUTHENTICATED_USER:
      return {
        ...state,
        authenticatedUserLoaded: false,
        isLoading: true
      };

    case UsersActionTypes.LOGOUT_USER:
      return {
        ...state,
        user: null,
        authenticatedUserLoaded: false,
        privileges: null,
        roles: null
      };

    case UsersActionTypes.LOAD_AUTHENTICATED_USER_PRIVS:
      return {
        ...state,
        isLoading: true
      };

    case UsersActionTypes.AUTHENTICATED_USER_PRIVS_LOADED:
      return {
        ...state,
        privileges: action.payload.privileges,
        isLoading: false
      };
    
    case UsersActionTypes.LOAD_AUTHENTICATED_USER_ROLES:
      return {
        ...state,
        isLoading: true
      };

    case UsersActionTypes.AUTHENTICATED_USER_ROLES_LOADED:
      return {
        ...state,
        roles: action.payload.roles,
        isLoading: false
      };

    default:
      return state;
  }
}
