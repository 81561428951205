import { Component, Input } from '@angular/core';
import { ComponentCarousel } from '@compass/core-data';

@Component({
  selector: 'compass-component-carousel',
  templateUrl: './component-carousel.component.html',
  styleUrls: ['./component-carousel.component.scss']
})
export class ComponentCarouselComponent {
  /** The component to render. */
  @Input() componentType: ComponentCarousel;
}
