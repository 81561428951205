<div [id]="component.id" [ngStyle]="{ width: component.pageWidth }" class="component flex-wrap">
  <h2 class="col-12 mb-2" style="font-size: 18px;" *ngIf="component.title">{{ component.title }}</h2>
  <p class="description" *ngIf="component.description">{{ component.description }}</p>

  <div class="d-flex flex-wrap align-items-baseline">
    <ng-container *ngIf="component.parameters && component.parameters.length > 0">
      <compass-parameter
        *ngFor="let parameter of component.parameters"
        [ngStyle]="{width: parameter.controlType.pageWidth}"
        [form]="form"
        [parameter]="parameter"
        [section]="section"
        [schema]="schema"
      ></compass-parameter>
    </ng-container>

  <ng-container
      *ngIf="component.componentType && component.componentType.objectID"
      [ngSwitch]="component.componentType.objectID"
    >
      <compass-component-carousel
        *ngSwitchCase="ComponentType.Carousel"
        [componentType]="component.componentType"
      ></compass-component-carousel>

      <compass-file-upload
        *ngSwitchCase="ComponentType.FileUpload"
        [id]="component.id"
        [componentType]="component.componentType"
      ></compass-file-upload>

      <compass-component-informational
        *ngSwitchCase="ComponentType.Informational"
        [id]="component.id"
        [componentType]="component.componentType"
        [form]="form"
        [section]="section"
        [schema]="schema"
      ></compass-component-informational>

      <compass-chart
        *ngSwitchCase="ComponentType.Chart"
        [id]="component.id"
        [componentType]="component.componentType"
        [form]="form"
        [section]="section"
        [schema]="schema"
      ></compass-chart>

      <compass-component-data-entry
        *ngSwitchCase="ComponentType.DataEntry"
        [componentType]="component.componentType"
        [form]="form"
        [section]="section"
        [schema]="schema"
      ></compass-component-data-entry>

      <compass-component-html
        *ngSwitchCase="ComponentType.HTML"
        [componentType]="component.componentType"
      ></compass-component-html>

      <compass-component-tableau
        *ngSwitchCase="ComponentType.Tableau"
        [componentType]="component.componentType"
        [form]="form"
        [section]="section"
        [schema]="schema"
      ></compass-component-tableau>

      <compass-component-report
        *ngSwitchCase="ComponentType.Report"
        [componentType]="component.componentType"
        [form]="form"
        [section]="section"
        [schema]="schema"
      ></compass-component-report>

      <compass-upload
        *ngSwitchCase="ComponentType.Upload"
        [componentType]="component.componentType"
      >
      </compass-upload>

      <compass-role-apps-list
        *ngSwitchCase="ComponentType.RoleAppsList"
        [form]="form"
        [componentType]="component.componentType"
      >
      </compass-role-apps-list>

      <compass-user-roles-list
        *ngSwitchCase="ComponentType.UserRolesList"
        [form]="form"
        [componentType]="component.componentType"
      >
      </compass-user-roles-list>
    </ng-container>

    <ng-container *ngIf="component.actions && component.actions.length > 0">
      <div class="action-container">
        <compass-action
          *ngFor="let action of component.actions"
          [action]="action"
          [componentType]="component.componentType"
          [form]="form"
          [section]="section"
          [schema]="schema"
        ></compass-action>
      </div>
    </ng-container>
  </div>
</div>
