import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Page, FormService } from '@compass/core-data';
import { SchemasFacade } from '@compass/core-state';
import { CompassFormGroup } from './form-group/compass-form-group';
import { FormGroup } from '@angular/forms';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'compass-budget-tool',
  templateUrl: './budget-tool.component.html',
  styleUrls: ['./budget-tool.component.scss']
})
export class BudgetToolComponent implements OnInit, OnDestroy {
  form: FormGroup = new CompassFormGroup({});

  currentSchema$;

  /** Subject to trigger unsubcribe to observable streams. */
  private unsubscribe = new Subject<void>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public readonly schemasFacade: SchemasFacade,
    private readonly formService: FormService,
  ) {
    // Register this as "the" form for the page
    this.formService.setForm(this.form);

    this.currentSchema$ = this.schemasFacade.currentSchema$;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit() {
    // Render schema based on route param
    this.activatedRoute.paramMap.pipe(
      takeUntil(this.unsubscribe),
      filter(paramMap => paramMap.has('schema')),
      map(paramMap => paramMap.get('schema')),
    ).subscribe(fileName => this.schemasFacade.loadSchema(fileName));
  }

  // Format the schema's path/URL
  formatRouterLink(page: Page) {
    return [`/apps/${this.activatedRoute.snapshot.params.folder}/${page.fileName}`]
  }

}
