<!-- <div *ngIf="(isLoading$ | async)" class="loading">
  <mat-spinner></mat-spinner>
</div> -->
<div class="row">
  <mat-card class="mx-3 mt-2" style="flex: 1 1;">
    <mat-card-title>Soda Schema Validator</mat-card-title>
    <mat-card-content style="width: 100%">
      <form style="width: 100%" [formGroup]="form" (submit)="onSubmit()">
        <div style="display: flex; flex-direction: column;">
          <mat-form-field appearance="outline">
            <mat-label>Paste your Soda or a single Sock JSON here</mat-label>
            <textarea matInput [formControl]="sodaSnippetControl">
            </textarea>
          </mat-form-field>
          <p *ngIf="validationError" style="color: red;">{{validationError}}</p>
          <p *ngIf="!validationError && validationSuccess && sodaSnippetControl.value" style="color: green;">Valid JSON!</p>
        </div>
        <mat-card-actions>
          <button 
            mat-raised-button
            type="submit"
            class="mr-2 mb-2"
            color="primary"
            [disabled]="form.invalid"
          >Validate</button>
          <button 
            mat-raised-button
            type="button"
            class="mr-2 mb-2"
            color="secondary"
            (click)="onClickClear()"
          >Clear</button>  
        </mat-card-actions>
      </form>
    </mat-card-content>
  </mat-card>
  <mat-card class="mx-3 mt-2" style="flex: 1 1;">
    <mat-card-title>Generated SQL</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
    <mat-card-content style="width: 100%; white-space: break-spaces; overflow: auto;">
      <textarea id="sqlOutput" readonly matInput style="resize:none;">{{data.sql}}</textarea>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-raised-button
        type="submit"
        class="mr-2 mb-2"
        color="primary"
        (click)="onClickCopySql()"
        [disabled]="!data.sql"
      >Copy</button>
    </mat-card-actions>
  </mat-card>
</div>

<mat-card class="mx-3 mt-2">
  <mat-card-title>Data</mat-card-title>
  <mat-card-content style="width: 100%" *ngIf="data.results">
    <ag-grid-angular
      style="width: 100%; height: 300px;"
      class="ag-theme-balham"
      [rowData]="rowData"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [gridReady]="onGridReady"
    >
    </ag-grid-angular>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button
      class="mb-2"
      (click)="onExport()"
      [disabled]="!rowData"
    >Export</button>
  </mat-card-actions>
</mat-card>
<!-- Leaving Raw response card/contents out, if/until requested -->
<!-- <mat-card class="mx-3 mt-2" style="display: flex; flex-direction: column; max-height: 400px !important; margin-bottom: 12px">
  <mat-card-title>Raw response</mat-card-title>
  <mat-card-content style="width: 100%; height: 100%;" *ngIf="data.results" style="overflow:auto; ">{{data.results | json}}</mat-card-content>
</mat-card> -->