import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ControlDisplay, Section, Schema } from '@compass/core-data';

@Component({
  selector: 'compass-control-display',
  templateUrl: './control-display.component.html',
  styleUrls: ['./control-display.component.scss']
})
export class ControlDisplayComponent {
  /** The display control to display. */
  @Input() control: ControlDisplay;

  /** The FormGroup instance for the schema. */
  @Input() form: FormGroup;

  @Input() section: Section;

  @Input() schema: Schema;

  @Input() relationalKey: string;

  /** The control value. */
  @Input() value: boolean | number | string;
}
