import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isEmpty } from 'lodash';

import { Schema, FormService } from '@compass/core-data';
import { LocalStorageService } from '@compass/core-window';

// Expansion panel open/close state property name for local storage
const expPanelStateProp = 'sectionExpansion';

@Component({
  selector: 'compass-schema',
  templateUrl: './schema.component.html',
  styleUrls: ['./schema.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchemaComponent implements OnChanges {
  /** The selected schema. */
  @Input() schema: Schema;

  /** The FormGroup instance for the entire schema. */
  @Input() form: FormGroup;

  // Pagedef property to further restrict access to only roles in the array
  schemaRoles: [];

  constructor(
    private cd: ChangeDetectorRef,
    private formService: FormService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.schema.currentValue) {
      // Determine which element to place the scrollbar on:
      // if mobile or schema.rollingParameters, place on schema, otherwise, place on content div
      const schemaElement = document.getElementById('schema');
      const contentElement = document.getElementById('content');
      const desktopDisplay = window.innerHeight > 900;

      // TODO: support rolling navigation with frozen parameters
      if (this.schema.rollingNavigations) {
        schemaElement.style.overflow = 'unset';
      } else if (this.schema.rollingParameters === false && desktopDisplay) {
        schemaElement.style.removeProperty('overflow');
        contentElement.style.overflow = 'auto';
        contentElement.style.height = '100%';
      } else {
        schemaElement.style.overflow = 'auto';
        contentElement.style.removeProperty('overflow');
      }
    }
  }

  /* Only displays ion.ions if every top-level parameter has been selected */
  checkAllExistingParametersFulfilled(): boolean {
    if (!this.schema || !this.schema.sections) {
      return false;
    }

    if (!this.schema.parameters) {
      return true;
    }

    if (isEmpty(this.form.controls)) {
      return false;
    }

    return this.schema.parameters.every(param => {
      const control = this.formService.getFormControl(
        param.controlType.key,
        this.form,
        this.schema
      );

      return control && control.value;
    });
  }

  trackSection(index, section) {
    return index + section.id;
  }

  // Expansion panel open and close events (outputs) call this method to save the current open/close state to local storage
  saveSectionExpandStateToCache(open: boolean, section) {
    let data = this.localStorageService.getItem(expPanelStateProp) || {};
    data[section.id] = open;

    this.localStorageService.setItem(expPanelStateProp, data);
  }

  // Expansion panel's expanded (input) property 
  getSectionExpandStateFromCache(sectionId) {
    const cachedChoices = this.localStorageService.getItem(expPanelStateProp);
    return this.schema.sections?.length ? cachedChoices[sectionId] !== false : true;
  }
}
