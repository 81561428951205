import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WINDOW } from '@compass/core-window';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'compass-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  hide = true;
  // destroy$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    @Inject(WINDOW) private readonly window: Window,
  ) {}

  ngOnInit() {}

  login() {
    this.window.open(environment.oauth2.authenticateURL, '_self');
  }
}
