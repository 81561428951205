export class DateService {
  dateFormat: string = 'DD-MMM-YYYY';
  displayCalendarDays = true;
  dateValDefaultFormat = 'YYYY-MM-DD'

  constructor() { }

  get parse() {
    return {
      dateInput: this.dateFormat
    }
  }

  get display() {
    return {
      dateInput: this.dateFormat,
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
  }
}
