<div [formGroup]="formGroup">
  <mat-form-field appearance="none" class="ag-custom-component-popup">
    <input matInput
      #dateInput
      class="ag-input-field-input ag-text-field-input"
      [placeholder]="dateService.dateFormat"
      [matDatepicker]="datePicker"
      [formControl]="dateFormControl"
      (dateInput)="onDateInput($event.value)"
      (dateChange)="onDateChange($event.value)"
    >
    <mat-datepicker-toggle 
      matSuffix 
      #calendarButton
      [for]="datePicker" 
      class="ag-custom-component-popup"
      panelClass="ag-custom-component-popup"
    ></mat-datepicker-toggle>
    <ng-container *ngIf="dateService.displayCalendarDays; else yearCalendar">
      <mat-datepicker
        #datePicker
        (closed)="onClose()"
        class="ag-custom-component-popup"
        panelClass="ag-custom-component-popup"
        startView="month"
      ></mat-datepicker>
    </ng-container>
    <ng-template #yearCalendar>
      <mat-datepicker
        #datePicker
        class="ag-custom-component-popup"
        panelClass="hide-calendar-button ag-custom-component-popup"
        startView="multi-year"
        (closed)="onClose()"
        (monthSelected)="monthSelected($event, datePicker)"
      ></mat-datepicker>
    </ng-template>
  </mat-form-field>
</div>
