import { FavoritesEffects } from './favorites/favorites.effects';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NxModule } from '@nrwl/nx';
import { localStorageSync } from 'ngrx-store-localstorage';

import { ItemsEffects } from './items/items.effects';
import { clearState } from './meta/meta.reducer';
import { UsersEffects } from './users/users.effects';
import { reducers } from '.';
import { RolesEffects } from './roles/roles.effects';
import { SchemasEffects } from './schemas/schemas.effects';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['ui'], rehydrate: true })(reducer);
}

@NgModule({
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers: [clearState, localStorageSyncReducer] }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 10,
          logOnly: environment.production
        })
      : [],
    EffectsModule.forRoot([
      UsersEffects,
      ItemsEffects,
      RolesEffects,
      FavoritesEffects,
      SchemasEffects
    ])
  ]
})
export class CoreStateModule {}
