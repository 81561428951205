import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import {
  loadUserRolesQuery,
  loadUserAppsQuery,
} from './privileges.graphql';

@Injectable({
  providedIn: 'root'
})
export class PrivilegesService {
  constructor(private apollo: Apollo) {}

  loadUserApps(usr_id: number): Observable<any> {
    return this.apollo
      .query({
        query: loadUserAppsQuery,
        variables: {
          usr_id: usr_id
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => {
          return response.data.loadUserApps;
        })
      );
  }

  loadUserRoles(usr_id: number): Observable<any[]> {
    return this.apollo
      .query({
        query: loadUserRolesQuery,
        variables: {
          usr_id: usr_id
        },
        fetchPolicy: 'network-only',
      }).pipe(
        map((response: ApolloQueryResult<any>) => {
          return response.data.loadUserRoles;
        })
    );
  }
}
