<form [formGroup]="form">
  <ng-container [ngSwitch]="true">
    <!-- LONGTEXT -->
    <ng-container *ngSwitchCase="DataType.Longtext === control.dataType">
      <mat-form-field appearance="outline" class="form-field-large">
        <mat-label>{{ control.title }}</mat-label>
        <textarea
          matInput
          [matTooltip]="control.description"
          [formControlName]="relationalKey"
          [value]="value">
        </textarea>
      </mat-form-field>
    </ng-container>
    <!-- TEXT -->
    <ng-container *ngSwitchCase="DataType.Text === control.dataType">
      <mat-form-field appearance="outline" class="form-field-small">
        <mat-label>{{ control.title }}</mat-label>
        <input matInput
          type="text"
          [matTooltip]="control.description"
          [formControlName]="relationalKey"
          [value]="value"
        />
      </mat-form-field>
    </ng-container>
    <!-- BOOLEAN -->
    <ng-container *ngSwitchCase="DataType.Boolean === control.dataType">
      <div class="checkbox">
        <mat-checkbox
          [formControlName]="relationalKey"
          [value]="value"
        >
          {{ control.title }}
        </mat-checkbox>
      </div>
    </ng-container>
    <!-- DATE -->
    <ng-container *ngSwitchCase="DataType.Date === control.dataType">
      <compass-control-input-date
        [control]="control"
        [form]="form"
        [relationalKey]="relationalKey">
      </compass-control-input-date>
    </ng-container>
    <!-- PERCENT -->
    <!-- NUMBER -->
    <!-- INTEGER -->
    <!-- MONEY -->
    <ng-container *ngSwitchCase="DataType.Percent === control.dataType || DataType.Integer === control.dataType || DataType.Number === control.dataType || DataType.Money === control.dataType">
      <mat-form-field appearance="outline" class="form-field-small">
        <mat-label>{{ control.title }}</mat-label>
        <input matInput
          type="number"
          [matTooltip]="control.description"
          [formControlName]="relationalKey"
          [value]="value"
        />
      </mat-form-field>
    </ng-container>

    <!-- DEFAULT -->
    <ng-container *ngSwitchDefault>
      <mat-form-field appearance="outline" class="form-field-small">
        <mat-label>{{ control.title }}</mat-label>
        <input matInput
          type="text"
          [matTooltip]="control.description"
          [formControlName]="relationalKey"
          [value]="value"
        />
      </mat-form-field>
    </ng-container>
  </ng-container>
</form>
