import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';

import { Timeline } from './timeline.model';
import {
  allTimelinesQuery,
  createDimensionsTimelineMutation,
  updateDimensionsTimelineMutation,
  deleteDimensionsTimelineMutation
} from './timeline.graphql';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {
  constructor(private apollo: Apollo) { }

  all(): Observable<Timeline[]> {
    return this.apollo
      .query({
        query: allTimelinesQuery,
        fetchPolicy: 'network-only',
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.allTimelines)
      );
  }

  create(input: Timeline): Observable<Timeline> {
    const randomId = Math.floor(Math.random() * 100) + 1;
    const addIdToInput = {tim_id: randomId, ...input};
    return this.apollo
      .mutate({
        mutation: createDimensionsTimelineMutation,
        variables: {
          input: addIdToInput
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.createDimensionsTimeline)
      );
  }

  update(patch: Timeline): Observable<Timeline> {
    delete (<any>patch).__typename;
    return this.apollo
      .mutate({
        mutation: updateDimensionsTimelineMutation,
        variables: {
          patch
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.updateDimensionsTimeline)
      );
  }

  delete(id: string): Observable<Timeline> {
    return this.apollo
      .mutate({
        mutation: deleteDimensionsTimelineMutation,
        variables: {
          id
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.deleteDimensionsTimeline)
      );
  }
}
