import gql from 'graphql-tag';

export const baselineFragment = gql`
  fragment baselineFragment on BaselineDTO {
    bas_id
    bas_name
    bas_desc
    base_bas_id
    base_bas_sort
    bas_final
    bas_active
    bas_sys_active
    bas_sys_ts
    bas_sys_usr
    bas_sys_desc
  }
`;

export const allBaselinesQuery = gql`
  query allBaselinesQuery {
    allBaselines {
      ...baselineFragment
    }
  }
  ${baselineFragment}
`;

export const baselineByIdQuery = gql`
  query baselineByIdQuery($id: String!) {
    baselineById(id: $id) {
      ...baselineFragment
    }
  }
  ${baselineFragment}
`;

export const createDimensionsBaselineMutation = gql`
  mutation createDimensionsBaselineMutation($input: BaselineInput!) {
    createDimensionsBaseline(input: $input) {
      ...baselineFragment
    }
  }
  ${baselineFragment}
`;

export const updateDimensionsBaselineMutation = gql`
  mutation updateDimensionsBaselineMutation($patch: BaselineInput!) {
    updateDimensionsBaseline(patch: $patch) {
      ...baselineFragment
    }
  }
  ${baselineFragment}
`;

export const deleteDimensionsBaselineMutation = gql`
  mutation deleteDimensionsBaselineMutation($id: String!) {
    deleteDimensionsBaseline(id: $id)
  }
`;
