import gql from 'graphql-tag';

export const schemaQuery = gql`
  query schemaQuery($fileName: String) {
    schema(fileName: $fileName)
  }
`;

export const createSchemaMutation = gql`
  mutation createSchema($parameters: [[String]]) {
    createSchema(parameters: $parameters)
  }
`;

export const createTableDefMutation = gql`
  mutation createTableDef($schema: JSON) {
    createTableDef(schema: $schema)
  }
`;
