<div [id]="gridId" class="grid-container" [ngStyle]="style | async" [ngSwitch]="control.tableType">
  <compass-control-table-manual
    *ngSwitchCase="TableType.Manual"
    [control]="control"
    [form]="form"
    [section]="section"
    [schema]="schema"
    (gridWidthChanged)="setGridWidth($event)"
  ></compass-control-table-manual>

  <compass-control-table-dimension
    *ngSwitchCase="TableType.Dimension"
    [control]="control"
    [form]="form"
    [section]="section"
    [schema]="schema"
    (gridWidthChanged)="setGridWidth($event)"
  ></compass-control-table-dimension>

  <compass-control-table-dimension-manual
    *ngSwitchCase="TableType.DimensionOrManual"
    [control]="control"
    [form]="form"
    [section]="section"
    [schema]="schema"
    (gridWidthChanged)="setGridWidth($event)"
  ></compass-control-table-dimension-manual>

  <compass-control-report-table
    *ngSwitchCase="TableType.ReportTable"
    [control]="control"
    [form]="form"
    [section]="section"
    [schema]="schema"
    (gridWidthChanged)="setGridWidth($event)"
  ></compass-control-report-table>
</div>
