<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{ control.title }}</mat-label>
    <input matInput
      [matTooltip]="control.description"
      [value]="value === undefined ? null : value"
      [formControlName]="relationalKey"
    />
  </mat-form-field>
</form>
