import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  ControlDropdown,
  DataSourceType,
  Db,
  DbFunction,
  ManualItems,
  Section,
  Schema,
  FormService,
  RefreshService,
} from '@compass/core-data';
import { DbFacade } from '@compass/core-state';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Subject, merge, of } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, takeWhile, debounceTime, filter, tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'compass-control-dropdown',
  templateUrl: './control-dropdown.component.html',
  styleUrls: ['./control-dropdown.component.scss']
})
export class ControlDropdownComponent implements OnDestroy, OnInit {
  @Input() control: ControlDropdown;
  @Input() form: FormGroup;
  @Input() value: boolean | number | string | Array<number | string>;
  @Input() section: Section;
  @Input() schema: Schema;
  @Input() relationalKey: string;
  @Input() isParameter: boolean;

  // An observable of an array of key and value objects that populates the dropdown options.
  options = new BehaviorSubject<Array<{ value: string; label: string }>>([]);
  // Populate with options (used by multi-selects)
  allOptions: any[] = [];
  // Used by multi-select to hold the currently selected values. When the menu is closed these are then used to set the controls values.
  pendingValues: any;
  // The main (hidden) form control
  formControl: AbstractControl;
  // Boolean indicating state of component.
  private alive = true;
  // Subject to trigger unsubcribe to observable streams.
  private unsubscribe = new Subject<void>();
  // mat-select control's formControlName
  matSelectKey: string;

  constructor(
    private readonly dbFacade: DbFacade,
    private readonly formService: FormService,
    public readonly refreshService: RefreshService,
    private apollo: Apollo
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.alive = false;
  }

  ngOnInit() {
    if (!this.schema && !this.section) {
      console.error(`Dropdown ${this.control} is missing Schema and Section.`)
    }

    // The hidden control that holds the value
    this.formControl = this.form.get(this.relationalKey);
    if (!this.formControl) console.error('Expecting formControl to be defined!');

    // Register the component with the refresh service
    if (this.control.key) {
      this.refreshService.refreshComponent(this, this.control.key);
    }

    // Add the mat-select FormControl
    this.matSelectKey = `matSelectKey_${this.relationalKey}`;
    const newFormControl: FormControl = new FormControl(this.formControl.value);
    this.form.addControl(this.matSelectKey, newFormControl);
    
    // Subscribe to value changes on the relational key FormControl and set the mat-select FormControl value (to display the value)
    this.formControl.valueChanges.subscribe((value) => {
      if (value && typeof value != 'string' && !this.control.multiple) {
        value = value.toString();
      }
      if (this.form.controls[this.matSelectKey].value !== value) {
        this.form.get(this.matSelectKey).setValue(value, {emitEvent: false});
      }
    });

    // Subscribe to the selected value changes on the matSelectKey FormControl and set the pending values if multiple, otherwise just set the main form control value.
    this.form.get(this.matSelectKey).valueChanges.subscribe((selectedValueOrValues) => {
      if (!this.control.multiple) {
        return this.formControl.setValue(selectedValueOrValues);
      }

      this.pendingValues = selectedValueOrValues;
    });
    
    // Subscribe to options value changes to populate allOptions, and validate if form control has a value already set
    this.options.pipe(filter(options => !isEmpty(options))).subscribe(options => {
      this.allOptions = options.map(option => option.value)

      // Verify the current value exists in the list of options
      if (!isEmpty(this.formControl.value)) {
        this.currentValExistsInOptions(options, this.formControl.value)
      }

      // Auto-select first option if parameter
      if (this.isParameter && options.length == 1) {
        const val = this.control.multiple ? [options[0].value] : options[0].value;
        this.form.get(this.matSelectKey).setValue(val)
      }
    });

    this.populateOptions();

    // this.setFormControlDisabledState();
  }
  
  // Multi-select: handle select all toggle, select all options if checked, none if not
  private selectAllChanged(checked) {
    this.form.get(this.matSelectKey).setValue(checked ? this.allOptions : []);
  }
  
  // Multi-select: logic to mark the Select All option as checked
  private selectAllIsChecked(): boolean {
    const multiSelect = this.form.get(this.matSelectKey);
    return multiSelect.value ? multiSelect.value.length == this.allOptions.length : false;
  }

  // Multi-select: only set value(s) when menu is closed
  onOpenedChange(isOpen: boolean) {
   if (!isOpen && this.pendingValues) {
      this.formControl.setValue(this.pendingValues);
      this.pendingValues = null;
    }
  }

  private observeKeys() {
    const items = this.control.items as Db;
    const dbFunction = items.dbCall as DbFunction;

    // verify function call has keys
    if (
      !dbFunction.functionCall.keys ||
      (dbFunction.functionCall.keys && dbFunction.functionCall.keys.length === 0)
    ) {
      return;
    }

    // unsubscribe from previous value changes observables
    this.unsubscribe.next();

    // watch for future value changes on dependent controls
    const observables = dbFunction.functionCall.keys
      .filter(key => this.formService.getFormControl(key, this.form, this.schema, this.section))
      .map(key => {
        const control = this.formService.getFormControl(key, this.form, this.schema, this.section);
        return control.valueChanges
          .pipe(
            distinctUntilChanged(),
            takeUntil(this.unsubscribe)
          )
      });

      merge(...observables)
        .pipe(debounceTime(100))
        .subscribe(() => this.setOptionsWithDbFunction(dbFunction));
  }

  private setFormControlDisabledState(): void {
    // toggle disabled state
    this.options
      .pipe(
        map(options => !options || (options && options.length === 0) || this.control.editable === false),
        takeWhile(() => this.alive)
      )
      .subscribe(disabled => {
        if (disabled) {
          this.formControl.disable();
        } else {
          this.formControl.enable();
        }
      });
  }

  private setOptionsWithDbFunction(dbFunction: DbFunction): void {
    this.dbFacade.getOptionsWithDbFunction(dbFunction, this.form, this.section).subscribe(options => {
      this.options.next(options);
    });
  }

  private setOptionsFromManualItems() {
    const items = this.control.items as ManualItems;
    this.options.next(
      items.manualItems.map(item => {
        return {
          value: item,
          label: item
        }
      })
    );
  }

  private setOptionsFromSoda() {
    // Query backend graphql 
    this.apollo
      .query({
        query: gql` query sodaApiQuery($json: JSON, $debug: Boolean) {
            soda(json: $json, debug: $debug)
        }`,
        variables: {
          json: this.control.items,
          debug: true
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        tap((resp: any) => {
          resp.data.results;

          const options = resp.data.soda.results.map(res => {
            return {
              value: res.org_id,
              label: res.payload
            };
          });

          this.options.next(options);
          this.allOptions = options;
        }),
        catchError((err) => {
          console.error(err)
          return of(err);
        })
      )
      .subscribe();
  }

  currentValExistsInOptions(options, currentVal) {
    if (!currentVal) return;
    let exists = false;

    if (Array.isArray(currentVal)) {
      if (!this.control.multiple) console.warn(`Dropdown form control value is array but not multiple select on pagedef.`);
      // Current value is an array, be sure every item in the array exists in the list of options
      exists = currentVal.every(item => options.find(option => option.value === item))
    } else {
      exists = options.find(option => option.value === currentVal);
    }

    if (!exists) {
      return console.warn(`The current value (${currentVal}) does not exist in the list of options for this dropdown!`);
    } else if (this.form.get(this.matSelectKey).value != currentVal) {
      // console.log('After options were populated, the current value displayed needed to be updated.');
      this.form.get(this.matSelectKey).setValue(currentVal, {emitEvent: false});
    }
  }

  populateOptions(isRefresh?: boolean) {
    if (this.control.items['$schema'] && this.control.items['$schema'] == 'soda.json') {
      this.setOptionsFromSoda();
    } else {
      switch (this.control.items.objectID) {
        case DataSourceType.DatabaseCall:
          if (!isRefresh) {
            this.observeKeys();
          }

          const dbFunction = this.control.items.dbCall as DbFunction;
          this.setOptionsWithDbFunction(dbFunction);
          break;
        case DataSourceType.Manual:
          this.setOptionsFromManualItems();
          break;
      }
    }
  }
}
