import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Moment } from 'moment';
import moment from 'moment';

import { ControlInput, DataType, DateService } from '@compass/core-data';

@Component({
  selector: 'compass-control-input-date',
  templateUrl: './control-input-date.component.html',
  styleUrls: ['./control-input-date.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useClass: DateService},
  ]
})
export class ControlInputDateComponent implements OnInit {
  @Input() control: ControlInput;
  @Input() form: FormGroup;
  @Input() relationalKey: string;

  @ViewChild('datePicker', { read: MatDatepicker}) datePicker: MatDatepicker<any>;

  DataType = DataType;
  formControl: AbstractControl;  // The hidden formControl to store the YYYY-MM-DD date format
  dateFormControl = new FormControl(); // The input tied to the mat-datepicker

  private unsubscribe = new Subject<void>();

  constructor(@Inject(MAT_DATE_FORMATS) public dateService: DateService) { }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit() {
    this.formControl = this.form.get(this.relationalKey);

    // Set the dateFormat property in the CompassDateFormats class
    if (this.control?.dateFormat) {
      this.dateService.dateFormat = this.control.dateFormat;

      if (this.control.dateFormat.toLowerCase().indexOf('d') == -1) {
        this.dateService.displayCalendarDays = false;
      }
    }

    // Subscribe to the (hidden) value change for the first value only, and set the date picker's value
    this.formControl.valueChanges.pipe(
      takeUntil(this.unsubscribe),
      map(value => this.dateFormControl.setValue(value ? moment(value) : null))
    ).subscribe();
  }

  dateChange(value: Moment): void {
    const val = value ? value.format(this.dateService.dateValDefaultFormat) : '';
    this.formControl.setValue(val);
  }

  monthSelected(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    this.datePicker.select(normalizedMonth);
    this.datePicker.close();
  }
}
