import { Component, Input } from '@angular/core';
import { ComponentHTML } from '@compass/core-data';

@Component({
  selector: 'compass-component-html',
  templateUrl: './component-html.component.html',
  styleUrls: ['./component-html.component.scss']
})
export class ComponentHtmlComponent {
  /** The component to render. */
  @Input() componentType: ComponentHTML;
}
