import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  Action,
  AffectedComponent,
  ControlTable,
  DataCollectionType,
  DbCallType,
  DbFunction,
  FormService,
  GridCellMetadata,
  NotifyService,
  RefreshService,
  Schema,
  Section,
  TableType
} from '@compass/core-data';
import { DbFacade, GridDateFilterComponent, GridService } from '@compass/core-state';
import { LocalStorageService } from '@compass/core-window';
import { environment } from '@env/environment';
import {
  CellClickedEvent,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  ColDef,
  Column,
  ColumnApi,
  ExcelExportParams,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  ProcessCellForExportParams,
  ProcessDataFromClipboardParams,
  RowDataChangedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { isEmpty, omit, } from 'lodash';
import { parse } from 'papaparse';
import { BehaviorSubject, forkJoin, merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, take, takeUntil, tap } from 'rxjs/operators';
import { DialogConfirmationComponent } from '../../core/components/dialog-confirmation/dialog-confirmation.component';
import { DialogLoadingComponent } from '../../core/components/dialog-loading/dialog-loading.component';

@Component({
  selector: 'compass-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() schema: Schema;
  @Input() control: ControlTable;
  @Input() section: Section;
  @Output() gridWidthChanged = new EventEmitter<number>();
  @Input() form: FormGroup;

  showLoadingOverlay = false;

  dialogLoadingRef: MatDialogRef<DialogLoadingComponent>;
  actionKeys: Array<string> = []; // Array of all keys required by this table's Actions
  relatedFormControls: Array<AbstractControl> = []; /** Form Controls that have key referenced by the table */
  originalData: Array<{ [key: string]: string }> = []; // Array of key value pairs, used as params to send to db call
  dataToSave: Array<{ [key: string]: string }> = []; // Array of key value pairs, used as params to send to db call
  saveButtonEnabled = false;
  columnDefs: Array<ColDef>;
  rowData = new BehaviorSubject<Array<{ [key: string]: string | number }>>(null);
  columnApi: ColumnApi;
  gridApi: GridApi;
  unsubscribe = new Subject<void>();
  onAddRowFilterModel: any;
  onAddRowSortModel: {
    colId: string;
    sort: string;
  }[];
  dspClearFilterBtn = new BehaviorSubject<boolean>(false);
  dspReapplySortFilterBtn = new BehaviorSubject<boolean>(false);
  dspAddRowBtn = new BehaviorSubject<boolean>(false);
  dspRemoveRowBtn = new BehaviorSubject<boolean>(false);
  disableRemoveRowBtn = true;

  popupParent;

  /** The default options for the grid. */
  gridOptions: GridOptions = {
    singleClickEdit: true,
    suppressMovableColumns: true,
    accentedSort: true,
    excelStyles: [
      {
        id: 'booleanType',
        dataType: 'boolean'
      }
    ],
    suppressScrollOnNewData: true,
    suppressCopyRowsToClipboard: true,
    // This property should be enabled, but it causes a bug in the Date fields of the grid (the popup calendar doesn't open)
    stopEditingWhenGridLosesFocus: true,
    defaultColDef: {
      editable: true,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      enablePivot: true,
      enableRowGroup: true,
      enableValue: true,
      minWidth: 75,
      maxWidth: 600,
      // autoHeight: true
    },
    suppressColumnVirtualisation: true,
    enableRangeSelection: true,
    enableBrowserTooltips: true,
    enterMovesDown: true,
    alwaysShowVerticalScroll: false,
    frameworkComponents: {
      agDateInput: GridDateFilterComponent
    },
    onSelectionChanged: (event: SelectionChangedEvent) => {
      this.disableRemoveRowBtn = event.api.getSelectedNodes().length != 1;
      this.cd.detectChanges();
    },
    onRowDoubleClicked: function (params) {
      params.node.setExpanded(!params.node.expanded);
    },
    onFilterChanged: (event: FilterChangedEvent) => this.onFilterChanged(event),
    onSortChanged: (event: SortChangedEvent) => this.onSortChanged(event),
    // There must have been an issue when pasting large amount of data. So we will hook into the pasteStart and pasteEnd events and toggle the (ag-grid) default Loading... overlay
    onPasteStart: () => this.gridApi.showLoadingOverlay(),
    onPasteEnd: () => this.gridApi.hideOverlay(),
    onCellClicked: (event: CellClickedEvent)=> {
      if (event?.colDef?.cellEditor === 'agLargeTextCellEditor') {
        this.gridApi.startEditingCell({
          rowIndex: event.rowIndex,
          colKey: event.column
        })
      }
    },
    onCellEditingStarted: (event: CellEditingStartedEvent) => {
      this.cd.detectChanges();
      const cellEditor = event.colDef.cellEditor;
      if (cellEditor === 'agRichSelectCellEditor' || cellEditor === 'agLargeTextCellEditor')
        this.preventScroll(true);
    },
    onCellEditingStopped: (event: CellEditingStoppedEvent) => this.preventScroll(false),
    processCellForClipboard: (params: ProcessCellForExportParams) => {
      try {
        // Helper function to check if the cell value is actually an object (json as a string)
        let valIsObj: GridCellMetadata = this.gridService.getGridCellJson(params.value);

        // If the value is an object, return it's value property
        if (valIsObj !== null && valIsObj !== undefined) {
          return valIsObj.value;
        }

        // Special handling for columns that use refData - return the decoded value
        if (params.column?.getColDef()?.refData) {
          let refData = params.column.getColDef().refData;
          
          if (refData.hasOwnProperty(params.value)) {
            return refData[params.value];
          }
        }

        return params.value;
      } catch (error) {
        return params.value;
      }
    },
    // Resolves issue https://bsc-oit.atlassian.net/browse/CC-1539 - pasting data from excel on windows machine clears the cell below the range/row being pasted in
    processDataFromClipboard(params: ProcessDataFromClipboardParams) {
      // If the user data to paste contains multiple rows
      if (params.data.length > 1) {
        const lastRow = params.data[params.data.length -1];
        // if the last "row" (item in the array of data being pasted) has a single value, and that value is blank
        if (lastRow.length === 1 && lastRow[0] === '') {
          // remove the last "row" (item in the array of data being pasted) from the array of data to paste
          params.data.pop();
        }
      }
      return params.data;
    },
    onCellKeyDown: (event) => this.onCellKeyDown(event),
  };

  constructor(
    public cd: ChangeDetectorRef,
    public dbFacade: DbFacade,
    public gridService: GridService,
    public readonly notifyService: NotifyService,
    public readonly refreshService: RefreshService,
    public readonly matDialog: MatDialog,
    public readonly formService: FormService,
    public readonly router: Router,
    public readonly localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    if (!this.control) console.error('Expecting control to be defined in OnInit!')

    this.popupParent = document.querySelector(this.control.id);

    if (this.control.rowHeightMultiplier) {
      this.gridOptions.rowHeight = 25 * this.control.rowHeightMultiplier;
    }

    if (this.control.columnWidth) {
      this.gridOptions.defaultColDef.width = this.control.columnWidth;
    }

    // Check the Pivot box of the Columns tray, if the value is set on the schema
    if (this.control.pivotOn) {
      this.gridOptions.pivotMode = true;
      this.gridOptions.pivotRowTotals = this.control.pivotRowTotals;
      this.gridOptions.pivotColumnGroupTotals = this.control.pivotColumnGroupTotals;
      this.gridOptions.groupIncludeTotalFooter = this.control.groupIncludeTotalFooter;
      this.gridOptions.sideBar = {
        toolPanels: ['columns', 'filters']
      }
      // Don't display the aggfunc <aggfunc>(<column name>) in the header, i.e. sum(Count)
      this.gridOptions.suppressAggFuncInHeader = true;

      // Don't display the aggfunc (<number>) in the groups
      this.gridOptions.autoGroupColumnDef = {
        cellRendererParams: {
          suppressCount: true
        }
      }

      // Set the pivot column header height
      this.gridOptions.pivotGroupHeaderHeight = this.control.pivotGroupHeaderHeight || 100;

      // Hide group details
      if (!('getDataSet' in this.control.dbValues.dbCall)) {
        this.gridOptions.groupDefaultExpanded = -1;
        this.gridOptions.groupHideOpenParents = true;
      }
    }
  }

  ngAfterViewInit(): void {
    // If table is flagged as Read Only, remove Add Row Button, Save Button
    if (this.control.readonly) {
      this.dspAddRowBtn.next(false);
      this.dspRemoveRowBtn.next(false);
      this.gridOptions.defaultColDef.editable = false;
    } else { // Only display Remove Row button if present in the schema. Only hide New Row button if tableSave NOT in schema.
      if (!this.control.hasOwnProperty('tableSave')) {
        this.dspAddRowBtn.next(false);
      }
      this.dspRemoveRowBtn.next(!!this.control.deleteRow);
    }

    // The pagedef property hideNewRowButton
    if (this.control.hideNewRowButton) this.dspAddRowBtn.next(false);

    if (this.control.hideGrid)
      document.getElementById(this.control.id).setAttribute('style', 'display: none;');

    this.refreshService.refreshComponent(this, this.control.id);

    this.relatedFormControls = this.formService.getRelatedFormControls(
      this.control,
      this.form,
      this.schema,
      this.section
    );

    if (this.control.tableActions) {
      this.actionKeys = this.control.tableActions.map((action: Action) => {
        const dbCall = action.db.dbCall;
        switch (dbCall.objectID) {
          case DbCallType.Function:
            return dbCall.functionCall.keys;

          case DbCallType.Table:
            let tableKeys = [];
            if (dbCall.getDataSet) {
              tableKeys = dbCall.getDataSet.functionCall.keys;
            }

            if (dbCall.keys) {
              tableKeys = [...tableKeys, dbCall.keys];
            }
            return tableKeys;
        }
      })[0];
    }

    if (this.control.tableSave) {
      if (this.control.tableSave.db.dbCall.objectID == DbCallType.Function) {
        this.actionKeys = [
          ...new Set(this.actionKeys.concat(this.control.tableSave.db.dbCall.functionCall.keys))
        ];
      } else {
        console.error('Table Save only supports Function db calls.');
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onCellValueChanged(event: CellValueChangedEvent): void {
    const newValue = event.newValue == null || event.newValue == undefined ? '' : event.newValue.toString();
    const oldValue = event.oldValue == null || event.oldValue == undefined ? '' : event.oldValue.toString();
    if (!this.actionKeys || oldValue === newValue) return;

    let newDataToSave: any = {};
    const columnDimKey = this.control.tableColumns[0]['key'];

    this.actionKeys.forEach(key => {
      // Key matches the current column def key (TableColumn dimension from schema)
      if (key == columnDimKey) {
        newDataToSave[key] = event.colDef.field == null || event.colDef.field == undefined ? '' : event.colDef.field.toString();
      }

      // Key matches a property in the row node (TableRow dimension from schema)
      if (key in event.node.data) {
        newDataToSave['rowIndex'] = event.node.rowIndex;
        newDataToSave[key] = event.node.data[key] == null || event.node.data[key] == undefined ? '' : event.node.data[key].toString();
      }

      // Key matches the table control from the schema
      if (this.control.tableControl?.key === key) {
        newDataToSave[key] = newValue;
      }
    });

    if (Object.keys(newDataToSave).length) {
      const key = this.control.tableControl?.key || event.colDef.field;
      const originalData = { ...newDataToSave, [key]: oldValue };
      const origDataIndex = this.originalData.findIndex(origRowData => JSON.stringify(origRowData) === JSON.stringify(newDataToSave) || JSON.stringify(origRowData) === JSON.stringify(originalData));
      let prevChangeIndex; // Same cell (col and row index) as already exists in this.dataToSave?

      switch (this.control.tableType ) {
        case TableType.Manual:
        case TableType.DimensionOrManual:
          prevChangeIndex = this.dataToSave.findIndex(rowData => rowData.rowIndex == event.node.rowIndex.toString());
          break;

        case TableType.Dimension:
          prevChangeIndex = this.dataToSave.findIndex(rowData => rowData.rowIndex == event.node.rowIndex.toString() && rowData[columnDimKey] === event.colDef.field);
          break;
      }

      // Row updated previously
      if (prevChangeIndex >= 0) {
        // New values match original values
        if (origDataIndex >= 0) {
          // Remove from dataToSave
          this.dataToSave.splice(prevChangeIndex, 1);
          return;
        } else { // New values don't match original values
          // Update data to save
          this.dataToSave[prevChangeIndex] = newDataToSave;  
        }
      } else { // Row's first update
        this.dataToSave.push(newDataToSave);

        // If there's not already an origData entry, create one. This avoids duplicates when subsequently changing values back to original value.
        if (origDataIndex == -1) {
          this.originalData.push(originalData);
        }
      }
    }
  }

  validateDataToSave(): boolean {
    let columns;
    if (this.control.tableColumns[0].objectID === DataCollectionType.Manual) {
      columns = this.control.tableColumns[0].fields;
    } else if (this.control.tableColumns[0].objectID === DataCollectionType.Dimension) {
      columns = this.control.tableColumns;
    }

    // If tableRows are defined (Dim/Dim and Dim/Manual tables), add them to the columns array to be validated
    if (this.control.tableRows) {
      columns.concat(this.control.tableRows);
    }

    const requiredColumns = columns.filter(tableColumn => tableColumn.valueRequired)?.map(item => item.key)
    if (!requiredColumns?.length)
      return true;

    const requiredColumnsHaveData = this.dataToSave.every(dataRow => requiredColumns.every(key => dataRow[key] != ""));

    return requiredColumnsHaveData;
  }

  onSave(): void {
    this.onAddRowFilterModel = this.gridApi.getFilterModel();
    this.onAddRowSortModel = this.gridApi.getSortModel();

    this.gridApi.stopEditing(false);
    const valid = this.validateDataToSave();

    if (!valid) {
      this.showMessageFailure('Data NOT saved! There are required fields without values.');
      return;
    }

    setTimeout(() => {
      if (!this.control.tableSave.db) {
        const msg = 'Error: The "db" property was undefined on this action';
        alert(msg);
        console.error(msg);
        return;
      }

      if (this.control.tableSave.db.dbCall.objectID != DbCallType.Function) {
        throw new Error('Actions only support dbCall type Function.');
      }

      // Get parameters from page schema form
      const dbFunction = this.control.tableSave.db.dbCall;
      let parameters = this.dbFacade.getParametersWithDbFunction(dbFunction, this.form, this.schema, this.section);
      // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
      if (dbFunction.functionCall.staticKeys) {
        dbFunction.functionCall.staticKeys.forEach((staticKey: any) => {
          parameters.push([staticKey.key, staticKey.value]);
        });
      }

      let action$: Observable<any>;
      if (!isEmpty(this.dataToSave)) {
        action$ = merge(
          forkJoin(
            this.dataToSave.map(cellParams => {
              // Remove 'rowIndex' metadata property from the params (used by tables when setting dataToSave)
              cellParams = omit(cellParams, 'rowIndex');

              const paramsForSave = [...parameters, ...Object.entries(cellParams)];
              return this.dbFacade.fn(dbFunction, paramsForSave);
            })
          )
        );

        this.showLoadingDialog();

        action$.pipe(
          tap(() => this.showMessageSuccess(this.control.tableSave.messageSuccess)),
          catchError(e => {
            this.hideLoadingDialog();
            console.error(e);
            this.showMessageFailure(`${this.control.tableSave.messageFailure}: ${e}`);
            return throwError(e);
          }),
          delay(500),
        ).subscribe({
          next: () => {
            // Clear the data that was saved
            this.dataToSave = [];

            const affectedComponents = this.control.tableSave.affectedComponents;
            // Kick off refreshService subject observable that tables will subscribe to
            if (affectedComponents && affectedComponents.length) {
              affectedComponents.forEach((affectedComponent: AffectedComponent) => this.refreshService.subject.next(affectedComponent));
            }
            this.actionComplete(this.dataToSave);
          },
          error: (err) => {
            this.hideLoadingDialog();
            this.showMessageFailure(`${this.control.tableSave.messageFailure}: ${err}`);
            this.actionComplete(this.dataToSave);
          },
          complete: () => {
            this.hideLoadingDialog();
          }
        });

        if (this.control.tableSave.redirect) {
          this.router.navigate(['apps', this.control.tableSave.redirect]);
        }
      }
    })
  }

  showMessageFailure(message: string): void {
    message = message || 'Action failed.';
    this.notifyService.notifyError(message);
  }

  showMessageSuccess(message: string): void {
    message = message || 'Action was successful.';
    this.notifyService.notify(message);
  }

  preventScroll(prevent: boolean) {
    const schemaElement = document.getElementById('schema');
    const contentElement = document.getElementById('content');

    // Toggle the correct element's overflow
    if (this.schema.rollingParameters === false && contentElement) {
      contentElement.style.overflow = prevent ? 'hidden' : 'auto';
    } else if (schemaElement) {
      schemaElement.style.overflow = prevent ? 'hidden' : 'auto';
    }

    let grids: any = document.getElementsByClassName('ag-body-viewport');
    for (let grid of grids) {
      grid.style.overflow = prevent ? 'hidden' : 'auto';
    }
  }

  onDeleteRow(): void {
    // Open confirmation dialog
    const dialogRef = this.matDialog.open(DialogConfirmationComponent, {
      width: '250px',
      data: { message: 'Are you sure you want to Delete this row?' }
    });
    // Only proceed to delete if user clicked Yes button (result === true)
    dialogRef.afterClosed().subscribe(proceed => {
      if (!proceed) return;

      // verify the action dbCall is a Function (SQL not supported)
      if (this.control.deleteRow.db.dbCall.objectID !== DbCallType.Function) {
        throw new Error('Only Functions are supported in actions for deleting rows.');
      }
      // get the selected rows
      const rows = this.gridApi.getSelectedRows();

      // verify at least one row is selected (should always be a single selection)
      if (rows.length !== 1) {
        return;
      }

      // get the selected node (needed for id/index)
      const nodes = this.gridApi.getSelectedNodes();

      // verify at least one row is selected (should always be a single selection)
      if (nodes.length !== 1) {
        return;
      }

      // if this is an empty row, just delete it from the grid
      if (this.rowIsEmpty(nodes[0].rowIndex)) {
        this.gridApi.applyTransaction({ remove: [this.gridApi.getRowNode(nodes[0].id).data] });
        return;
      }

      // get parameters for form
      const dbFunction = this.control.deleteRow.db.dbCall;
      const parameters = this.dbFacade.getParametersWithDbFunction(
        dbFunction,
        this.form,
        this.schema,
        this.section
      );

      // get array of tabular data keys (non parameters)
      const dataKeys = parameters ? dbFunction.functionCall.keys.filter(
        key =>
          parameters.findIndex(parameter => {
            return parameter[0] === key;
          }) === -1
      ) : dbFunction.functionCall.keys;

      // add non param key/val pairs to parameters array
      const row = rows[0];
      dataKeys.forEach(key => {
        const val = row[key] !== null ? row[key].toString() : '';
        parameters.push([key, val]);
      });

      // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
      if (dbFunction.functionCall.staticKeys) {
        dbFunction.functionCall.staticKeys.forEach((staticKey: any) => {
          parameters.push([staticKey.key, staticKey.value]);
        });
      }

      this.deleteWithDbFunction(dbFunction, parameters, rows);
    });
  }

  isJson(str) {
    let parsed;
    try {
      parsed = JSON.parse(str);
    } catch (e) {
      return null;
    }
    return parsed;
  }

  onExport(): void {
    // https://www.ag-grid.com/javascript-data-grid/excel-export-api/#excelexportparams
    const exportParams: ExcelExportParams = {
      // Check each cell on export, if value can be parsed as JSON and has a value property, use that value.
      processCellCallback: (params: ProcessCellForExportParams) => {
        // Dim/Dim and Dim/Manual tables that use tableRowsDbValues require this to retrieve the decoded value
        if (params.column.getColDef()?.refData) {
          return params.column.getColDef()?.refData[params.value];
        }

        // If the value of the cell is JSON, go look for a property called value and return that
        const jsonValue = this.isJson(params.value);
        if (jsonValue && jsonValue.value) {
          return jsonValue.value;
        }

        return params.value;
      },
      fileName: this.control.title || 'export'
    };

    this.gridApi.exportDataAsExcel(exportParams);
  }

  onGridReady(gridOptions: GridOptions): void {
    this.gridApi = gridOptions.api;
    this.columnApi = gridOptions.columnApi;

    if (!isEmpty(this.relatedFormControls)) {
      this.observeKeys();
    }

    this.populateGrid();
  }

  onReapplySortFilter(): void {
    if (this.onAddRowFilterModel) {
      this.gridApi.setFilterModel(this.onAddRowFilterModel);
      this.onAddRowFilterModel = null;
    }

    if (this.onAddRowSortModel) {
      this.gridApi.setSortModel(this.onAddRowSortModel);
      this.onAddRowSortModel = null;
    }

    this.dspReapplySortFilterBtn.next(false);
  }

  // Wired to Action component's dataChanged Output. It should always emit an empty array.
  actionComplete(event: string | any[]): void {
    // this means action was successful
    if (!event.length) {
      // Empty the data to save and disable the save button
      this.dataToSave = [];
      this.saveButtonEnabled = false;
      this.cd.detectChanges();

      const refreshTableAfterSave = !!this.control.tableSave.affectedComponents?.find(thing => thing.id === this.control.id);

      if (!refreshTableAfterSave) {
        this.gridApi.setColumnDefs(this.columnDefs)
      }
      // Exit here if this property exists and is set to false
      if (this.control.tableSave?.redrawRowsOnRefresh === false) {
        return;
      }
      // This will remove the red text
      this.gridApi.redrawRows();
    }
  }

  // TODO: refactor all of this (canCreateNewRow, rowIsEmpty) to a boolean property in this component. There should be a subscription to this.rowData that calls a method to toggle the New Row disabled property.
  canCreateNewRow(): boolean {
    if (this.control.readonly || !this.gridApi) return false;

    const lastRowIndex = this.gridApi.getLastDisplayedRow();
    if (lastRowIndex == -1) return true;
    const lastRowNotEmpty = !this.rowIsEmpty(lastRowIndex);

    return this.control.disableNewRowUntilSave
      ? !this.dataToSave.length && lastRowNotEmpty
      : lastRowNotEmpty;
  }

  canExport(): boolean {
    return this.control && this.control.hasOwnProperty('export') ? this.control.export : true
  }

  deleteWithDbFunction(
    dbFunction: DbFunction,
    parameters: Array<[string, string]>,
    deletedRows: Array<any>
  ): void {
    this.showLoadingDialog();

    this.dbFacade
      .fn(dbFunction, parameters)
      .pipe(take(1))
      .subscribe(
        () => {
          // Remove row from grid
          this.gridApi.applyTransaction({ remove: deletedRows });

          // Show the success message
          if (this.control.deleteRow.messageSuccess) {
            this.notifyService.notify(this.control.deleteRow.messageSuccess);
          }

          // Refresh affected components
          const affectedComponents = this.control.deleteRow.affectedComponents;
          if (affectedComponents && affectedComponents.length) {
            affectedComponents.forEach((affectedComponent: AffectedComponent) => this.refreshService.subject.next(affectedComponent));
          }
        },
        (err) => {
          this.hideLoadingDialog();
          console.error(err);
          this.notifyService.notifyError(err?.message || err);
        },
        () => this.hideLoadingDialog()
      );
  }

  getDbValuesFn(): Observable<Array<any>> | null {
    // verify DbFunction
    if (
      !this.control ||
      !this.schema ||
      !this.control.dbValues ||
      !this.control.dbValues.dbCall ||
      this.control.dbValues.dbCall.objectID !== DbCallType.Function
    ) {
      return null;
    }

    const missingRequiredParams = this.relatedFormControls ? this.relatedFormControls.find(formControl => {
      const valueRequired = this.formService.isControlRequired(formControl);
      const missingValue = isEmpty(formControl.value);
      return valueRequired && missingValue;
    }) : false;

    // Check that parameters (relatedFormControls) all have values
    if (missingRequiredParams) {
      console.log(`Missing required parameter(s) for table (title: '${this.control.title}', id: '${this.control.id}').`);
      return null;
    }

    // get parameters
    const dbFunction = this.control.dbValues.dbCall as DbFunction;
    const parameters = this.dbFacade.getParametersWithDbFunction(
      dbFunction,
      this.form,
      this.schema,
      this.section
    );

    if (this.control.dbValues.dbCall.functionCall.keys && isEmpty(parameters)) {
      // console.log(`Missing required parameter(s) for table (title: '${this.control.title}', id: '${this.control.id}').`);
      return null;
    }

    // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
    if (dbFunction.functionCall && dbFunction.functionCall.staticKeys) {
      dbFunction.functionCall.staticKeys.forEach((staticKey: any) => {
        parameters.push([staticKey.key, staticKey.value]);
      });
    }

    return this.dbFacade.fn(dbFunction, parameters).pipe(
      map(result => {
        const rows = result['pie_query'];
        const keys = Object.keys(rows);
        return keys.map(key => {
          const parsed = parse(rows[key], { delimiter: ',' });

          if (parsed.errors.length > 0) {
            console.error(parsed.errors);
            return null;
          }

          // there is only a single row of data
          return parsed.data[0];
        });
      })
    );
  }

  getDbValuesTbl(): Observable<Array<any>> | null {
    // verify DbFunction
    if (
      !this.control ||
      !this.schema ||
      !this.control.dbValues ||
      !this.control.dbValues.dbCall ||
      this.control.dbValues.dbCall.objectID !== DbCallType.Table
    ) {
      return null;
    }

    const dbTable = this.control.dbValues.dbCall;
    const tableColumn = this.control.tableColumns[0];
    let columnNames = [];
    const parameters = this.dbFacade.getParametersWithTableName(
      dbTable,
      this.form,
      this.schema,
      this.section
    );

    // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
    if (dbTable.staticKeys) {
      dbTable.staticKeys.forEach((staticKey: any) => {
        parameters.push([staticKey.key, staticKey.value]);
      });
    }

    // return an array of key/value pairs based on the tableColumns
    switch (tableColumn.objectID) {
      case DataCollectionType.Dimension:
        throw new Error('Manual table does not support dimension table columns.');
      case DataCollectionType.Manual:
        columnNames = tableColumn.fields.map(col => col.key);
    }

    // Get data from tbl query
    return this.dbFacade.tbl(dbTable, columnNames, parameters, dbTable.sortColumns).pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe),
      catchError(e => {
        console.error(e);
        this.notifyService.notifyError(e);
        return throwError(e);
      })
    );
  }

  getDomLayout(): string {
    // If schema has displayRow property, set the max height of the table to the equivalent of that many rows
    if (this.control && this.control.displayRow) {
      const tblHeight = this.control.displayRow * 28 + 64;
      const el = document.getElementById(this.control.id);
      if (el) el.style.height = `${tblHeight}px`;

      return 'normal';
    }

    return 'autoHeight';
  }

  getGridHeight(): string {
    const el = document.getElementById(this.control.id);
    
    return el?.clientHeight ? `${el.clientHeight}px` : '100%';
  }

  observeKeys(): void {
    // unsubscribe from previous value changes observables
    this.unsubscribe.next();

    this.formService
      .getChangesForControl(this.control, this.form, this.schema, this.section)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.populateGrid(true), err => console.error(err));
  }

  // Returns true if the row for the passed rowIndex has no data (in any columns).
  rowIsEmpty(rowIndex: number): boolean {
    const row = this.gridApi.getDisplayedRowAtIndex(rowIndex);
    if (!row || !row.data) {
      console.warn(`Missing row or rowdata for row index ${rowIndex}. (rowIsEmpty)`);
      return true;
    }

    return Object.values(row.data).every(val => val === null || val === '');
  }

  setRowData(): void {
    // Defined in child components (table types)
  }

  populateGrid(isRefresh?: boolean) {
    this.saveButtonEnabled = false;
    this.disableRemoveRowBtn = true;
    this.dataToSave = [];

    const missingRequiredParams = this.relatedFormControls ? this.relatedFormControls.find(formControl => {
      const valueRequired = this.formService.isControlRequired(formControl);
      const missingValue = isEmpty(formControl.value);
      return valueRequired && missingValue;
    }) : false;
    if (missingRequiredParams) {
      // console.log(`Missing required parameter(s) for table (title: '${this.control.title}', id: '${this.control.id}').`);
      return this.resetGrid();
    }

    // Display the grey loading modal/overlay over the grid
    this.showLoadingOverlay = true;

    // Need to set colDefs first for rowData to populate grid correctly
    this.setColDefs(isRefresh).subscribe(
      // Populate row data
      () => {
        try {
          this.setRowData();
          this.onReapplySortFilter();  
        } catch (error) {
          console.error(error);
          this.showLoadingOverlay = false;
        }
        
      },
      (err) => {
        console.error(err);
        this.gridApi.hideOverlay();
        this.notifyService.notifyError(`There was an error creating grid column definitions. Please check the console for more info.`);
        this.showLoadingOverlay = false;
      }
    );
  }

  resetGrid() {
    this.columnDefs = [];
    this.rowData.next([]);
    this.showLoadingOverlay = false;
  }

  autoSizeColumns() {
    // Auto-size all columns now that we have grid data.
    if (this.gridOptions.columnApi.getAllColumns() && this.gridOptions.columnApi.getAllColumns().length) {
      let skipHeader = false;
      const allColumnIds = [];
      // Gather all columns that don't have a width already defined in their colDef
      this.gridOptions.columnApi.getAllColumns().forEach((column: Column) => {
        if (!column.getColDef().width) {
          allColumnIds.push(column.getId())
        }
      });

      this.gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }
  }

  onRowDataChanged(event: RowDataChangedEvent): void {
    this.autoSizeColumns();
    this.applyCachedFilters();
    this.applyCachedSorts();
    this.showLoadingOverlay = false;
  }

  applyCachedSorts() {
    this.gridApi.setSortModel(this.localStorageService.getItem(this.sortCacheKey()));
  }

  applyCachedFilters() {
    this.gridApi.setFilterModel(this.localStorageService.getItem(this.filterCacheKey()));
  }

  onClearFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  private sortCacheKey() {
    return `${environment.localstorage.persistedColSorts}.${this.control.id}`;
  }

  private filterCacheKey() {
    return `${environment.localstorage.persistedColFilters}.${this.control.id}`;
  }

  onSortChanged(event: SortChangedEvent): void {
    this.localStorageService.setItem(this.sortCacheKey(), this.gridApi.getSortModel());
  }

  onFilterChanged(event: FilterChangedEvent): void {
    const filters = this.gridApi.getFilterModel();
    this.localStorageService.setItem(this.filterCacheKey(), filters);
    this.dspClearFilterBtn.next(Object.entries(filters).length > 0)
  }

  setColDefs(isRefresh?: boolean) {
    return this.gridService
      .getColumnDefsFromControlTable(this.control, this.form, this.section, this.gridOptions)
      .pipe(tap(colDefs => {
        // If refresh and no change to columns, don't re-set the column defs
        if (this.control.tableRowsDbValues && isRefresh && colDefs?.length === this.columnDefs?.length) {

          // Check for any differences in the field value between both the current array and incoming array of column defs
          const colDefChanges = colDefs.find((newCol, index) => this.columnDefs[index].field != newCol.field)

          // No changes, return the current column defs, instead of redefining them
          if (!colDefChanges) {
            return of(this.columnDefs);
          }
        }

        // Store the new colDefs
        this.columnDefs = colDefs;

        // isRefresh and there has been a change to column defs
        // Resolves CC-1222 - new row not decoding id
        if (isRefresh) {
          // Resolves CC-1326 - Changing page level Parameters on Dimension and Dimension Manual tables not refreshing columns
          this.gridApi.setColumnDefs([]);
          this.gridApi.setColumnDefs(this.columnDefs);
        }
      }));
  }

  hideLoadingDialog(): void {
    this.dialogLoadingRef.close();
  }

  showLoadingDialog(): void {
    // Don't open another loading dialog if already open
    if (this.dialogLoadingRef?.getState() == 0) return;

    this.dialogLoadingRef = this.matDialog.open(DialogLoadingComponent, {disableClose: true});
  }

  onCellKeyDown(e) {
    let keyPress = e.event.keyCode;
    // backspace or delete key
    if (keyPress === 8 || keyPress === 46) {
      let cellRanges = e.api.getCellRanges();

      cellRanges.forEach(cells => {
        // we only need the ids of the columns to set the data
        let colIds = cells.columns.reduce((filtered, col) => {
          if (col.colDef.editable) {
            filtered.push(col.colId);
          }
          return filtered;
        }, []);

        // cell range start and end index depends on how you select the ranges, so we ensure that the startRowIndex is always less than the endRowIndex regardless of how the cell range was selected
        let startRowIndex = Math.min(
          cells.startRow.rowIndex,
          cells.endRow.rowIndex
        );

        let endRowIndex = Math.max(
          cells.startRow.rowIndex,
          cells.endRow.rowIndex
        );
        this.clearCells(startRowIndex, endRowIndex, colIds);
      });
    }
  }

  clearCells(start, end, columns) {
    // iterate through every row
    for (let i = start; i <= end; i++) {
      let rowNode = this.gridOptions.api.getRowNode(i);

      // iterate through each column, inside the row and clear the cell
      columns.forEach(column => {
        // if cell data is json, check/validate if it has an editable property set to false
        if (typeof rowNode.data === 'object') {
          if (this.gridService.getGridCellJson(rowNode.data[column])?.editable === false) {
            return;
          }
        }

        // set data to empty string
        rowNode.setDataValue(column, '');
      });
    }
  }
}
