import { Role } from '../roles/roles.model';

export interface App {
  app_id: number;
  app_name: string;
  app_desc: string;
  app_filename: string;
  app_foldername: string;
  app_icon: string;
}

export interface Privilege {
  rol_id: number;
  usr_id: number;
  app_id: number;
  app: App;
}

export const newEmptyPrivilege: Privilege = {
  rol_id: null,
  usr_id: null,
  app_id: null,
  app: null
}

export interface UserRole {
  usr_id: number;
  rol_id: number;
  role: Role;
  privileges?: [Privilege];
}

export const newUserRole: UserRole = {
  usr_id: null,
  rol_id: null,
  role: null,
  privileges: null
}
