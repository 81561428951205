<div class="form-control" [ngSwitch]="control.objectID" [attr.id]="relationalKey">
  <compass-control-auto-complete
    *ngSwitchCase="ControlType.AutoComplete"
    [control]="control"
    [form]="form"
    [section]="section"
    [schema]="schema"
    [relationalKey]="relationalKey"
    [isParameter]="isParameter"
  ></compass-control-auto-complete>

  <compass-control-display
    *ngSwitchCase="ControlType.Display"
    [control]="control"
    [form]="form"
    [section]="section" 
    [schema]="schema"
    [relationalKey]="relationalKey"
  ></compass-control-display>

  <compass-control-dropdown
    *ngSwitchCase="ControlType.Dropdown"
    [control]="control"
    [form]="form"
    [section]="section"
    [schema]="schema"
    [relationalKey]="relationalKey"
    [isParameter]="isParameter"
  ></compass-control-dropdown>

  <compass-control-hidden
    *ngSwitchCase="ControlType.Hidden"
    [control]="control"
    [form]="form"
    [section]="section"
    [schema]="schema"
  ></compass-control-hidden>

  <compass-control-input
    *ngSwitchCase="ControlType.Input"
    [control]="control"
    [form]="form"
    [section]="section"
    [schema]="schema"
    [relationalKey]="relationalKey"
  ></compass-control-input>

  <compass-control-table
    *ngSwitchCase="ControlType.Table"
    [control]="control"
    [form]="form"
    [section]="section"
    [schema]="schema"
  ></compass-control-table>
</div>
