import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@compass/core-data';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import * as jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { loginQuery } from './auth.graphql';
import { LoginPayload } from './login-payload.interface';

export const TOKEN_NAME = 'token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuthenticated$ = new BehaviorSubject(false);

  constructor(private apollo: Apollo, private router: Router) {
    this.setToken(this.getToken());
  }

  getToken() {
    return localStorage.getItem(TOKEN_NAME);
  }

  setToken(token: string) {
    localStorage.setItem(TOKEN_NAME, token);
    this.isAuthenticated$.next(token !== '' && token !== null);
  }

  decodeFromToken(key?: string) {
    try {
      const rawToken = this.getToken();

      if (rawToken === '' || rawToken === 'null') return;

      const decodedToken = jwtDecode(rawToken);
      return key ? decodedToken[key] : decodedToken;
    } catch (e) {
      return;
    }
  }

  login(user: Partial<User>): Observable<LoginPayload> {
    return this.apollo
      .query({
        query: loginQuery,
        variables: {
          user
        }
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.login));
  }

  logout() {
    this.setToken('');
    localStorage.setItem('TableauToken', '');
    this.router.navigate(['/login']);
  }
}
