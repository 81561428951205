import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { SchemaType } from './schema-type.enum';
import { schemaQuery, createSchemaMutation, createTableDefMutation } from './schema.graphql';
import { Schema } from './schema.model';
import { Tabledef, TableType } from './tabledef.model';

@Injectable({
  providedIn: 'root'
})
export class SchemaService {

  constructor(private apollo: Apollo) {}

  createSchema(file: File): Observable<any> {
    const observable = from(file.text()).pipe(
      switchMap((fileContent: string) => {
        const schema = JSON.parse(fileContent);

        if (schema.$schema != SchemaType.Table) return of(schema);

        const tableDef = schema as Tabledef;

        if (tableDef.tableType === TableType.View) return of(schema);

        // Create the table in the database
        this.apollo
          .mutate({
            mutation: createTableDefMutation,
            variables: {
              schema
            }
          })
          .pipe(
            map((response: ApolloQueryResult<{ createTableDef: any }>) => response.data.createTableDef)
          );
        
        return of(schema);
      }),
      mergeMap(schema => {
        let schemaType: string;

        switch (schema.$schema) {
          case SchemaType.Application:
            schemaType = 'app';
            break;

          case SchemaType.Page:
            schemaType = 'page';
            break;

          case SchemaType.Table:
            schemaType = 'table';
            break;

          case SchemaType.Transform:
            schemaType = 'transform';
            break;

          case SchemaType.Schema:
            schemaType = 'schema';
            break;
        }

        if (!schemaType) throw new Error(`$schema ${schema.$schema} is not a valid schema type.`);

        const createSchemaInput = [
          ['def_type', schemaType],
          ['def_file_name', file.name],
          ['def_ts', new Date().toISOString()],
          ['def_content', JSON.stringify(schema)]
        ];

        // Write the schema to the database "metadata repository" (schema_def table)
        return this.apollo
          .mutate({
            mutation: createSchemaMutation,
            variables: {
              parameters: createSchemaInput
            }
          })
          .pipe(
            map((response: ApolloQueryResult<{ createSchema: any }>) => {
              return response.data.createSchema
            })
          );
      })
    );

    return observable;
  }

  getSchema(fileName: string): Observable<Schema> {
    return this.apollo
      .query({
        query: schemaQuery,
        variables: { fileName },
        fetchPolicy: 'network-only'
      })
      .pipe(map((response: ApolloQueryResult<{ schema: Schema }>) => response.data.schema));
  }
}
