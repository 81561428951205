import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { View } from '@compass/core-data';

@Component({
  selector: 'compass-trending-list',
  templateUrl: './trending-list.component.html',
  styleUrls: ['./trending-list.component.scss']
})
export class TrendingListComponent {
  @Input() views: View[];

  constructor(private sanitizer: DomSanitizer) {}

  getFirstColumnViews(): View[] {
    if (!this.views || this.views.length === 0) {
      return [];
    }

    let end = this.views.length / 2;
    if (this.views.length % 2 !== 0) {
      end++;
    }

    return this.views.slice(0, end);
  }

  getSecondColumnViews(): View[] {
    if (!this.views || this.views.length === 0) {
      return [];
    }

    let start = this.views.length / 2;
    if (this.views.length % 2 !== 0) {
      start++;
    }

    return this.views.slice(start);
  }

  sanitize(url: string) {
    if (url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
}
