import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromItems from './items.reducer';
import { emptyItem } from '@compass/core-data';

export const selectItemsState = createFeatureSelector<fromItems.ItemsState>('items');

export const selectItemIds = createSelector(
  selectItemsState,
  fromItems.selectItemIds
);

export const selectItemEntities = createSelector(
  selectItemsState,
  fromItems.selectItemEntities
);

export const selectAllItems = createSelector(
  selectItemsState,
  fromItems.selectAllItems
);

export const selectCurrentItemId = createSelector(
  selectItemsState,
  fromItems.getSelectedItemId
);

export const selectCurrentItem = createSelector(
  selectItemEntities,
  selectCurrentItemId,
  (itemEntities, itemId) => {
    return itemId ? itemEntities[itemId] : Object.assign({}, emptyItem);
  }
);

export const selectItemLoadingStatus = createSelector(
  selectItemsState,
  state => state.isLoading
);
