import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ColDef,
  ValueFormatterParams,
  ValueGetterParams,
  CellClassParams,
  GridOptions
} from 'ag-grid-community';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GridDatePickerComponent } from './grid-date-editor/grid-datepicker.component';
import { GridCheckboxComponent } from './grid-checkbox.component';
import { GridEditAggValuesComponent } from './grid-editaggvalues.component';
import { GridChangeCellRendererComponent } from './grid-changecellrenderer.component';
import { GridAutoCompleteComponent } from './grid-autocomplete.component';
import { isEmpty } from 'lodash';
import * as moment from 'moment';

import { DbFacade } from '../db/db.facade';
import {
  CollectionDim,
  ControlDisplay,
  ControlDropdown,
  ControlInput,
  ControlTable,
  ControlType,
  DataCollectionType,
  DataSourceType,
  DbCallType,
  DbSql,
  DataType,
  ManualDataCollection,
  ManualItems,
  TableType,
  ControlHidden,
  DateService,
  Section,
  ControlAutoComplete,
  GridColumnFilterType,
  GridCellMetadata,
  Option
} from '@compass/core-data';

enum Dimension {
  x,
  y
}

type TableFieldControl =
  | ControlDisplay
  | ControlDropdown
  | ControlInput
  | ControlHidden
  | ControlAutoComplete;

@Injectable({
  providedIn: 'root'
})
export class GridService {
  constructor(
    private readonly currencyPipe: CurrencyPipe,
    private readonly percentPipe: PercentPipe,
    private readonly dbService: DbFacade,
    private readonly dateService: DateService,
  ) {}

  getGridCellJson(str: string): GridCellMetadata {
    try {
      const val = JSON.parse(str);
      if (val && typeof val == 'object' && 'value' in val) return val;
    } catch (e) {
      return null;
    }

    return null;
  }

  generateGridRow(control: ControlTable): { [key: string]: string | number } {
    // verify tableColumns
    if (!control.tableColumns || (control.tableColumns && control.tableColumns.length === 0)) {
      return;
    }

    // todo: tableColumns should be an object instead of an array, for now just pluck off the first row
    const tableColumn = control.tableColumns[0];

    switch (tableColumn.objectID) {
      case DataCollectionType.Dimension:
        // todo: implement generating a row for a dimension table

        break;
      case DataCollectionType.Manual:
        return tableColumn.fields.reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            [currentValue.key]: null
          }),
          {}
        );
    }
  }

  getColumnDefsFromControlTable(
    controlTable: ControlTable,
    formGroup: FormGroup,
    section: Section,
    gridOptions?: GridOptions
  ): Observable<Array<ColDef>> {
    let columnDefinitions: ColDef[] = [];
    const observables: Array<{
      key: string;
      observable: Observable<Array<{ value: string; label: string }>>;
    }> = [];

    if (!controlTable.tableColumns) {
      return of([]);
    }

    // populate options for table rows, only when tableRowsDbValues is not defined
    if (!controlTable.tableRowsDbValues && controlTable.tableRows && controlTable.tableRows.length > 0) {
      this.dbService
        .getDimMembersOptionsObservables(controlTable.tableRows, formGroup, section)
        .forEach(optionsObservable => observables.push(optionsObservable));
    }

    // populate options for table columns
    if (controlTable.tableColumns && controlTable.tableColumns.length > 0) {
      // todo: tableColumns should be an object instead of an array, for now just pluck off the first row
      const tableColumn = controlTable.tableColumns[0];

      switch (tableColumn.objectID) {
        case DataCollectionType.Dimension:
          this.dbService
            .getDimMembersOptionsObservables([tableColumn], formGroup, section)
            .forEach(optionsObservable => observables.push(optionsObservable));
          break;
        case DataCollectionType.Manual:
          // Populate Options for Dropdowns and AutoCompletes
          tableColumn.fields
            .filter(
              control =>
                control.objectID === ControlType.Dropdown ||
                control.objectID === ControlType.AutoComplete
            )
            .filter(
              (control: ControlDropdown | ControlAutoComplete) =>
                control &&
                control.items &&
                control.items.objectID === DataSourceType.DatabaseCall &&
                control.items.dbCall.objectID === DbCallType.Function
            )
            .forEach((control: ControlDropdown | ControlAutoComplete) => {
              observables.push(
                this.dbService.getControlDropdownOptionsObservables(control, formGroup, section)
              );
            });
      }
    }

    // return an observable of the column definitions when table.fields contains a dropdown that requires a database function call
    if (observables.length > 0) {
      return forkJoin(observables.map(value => value.observable)).pipe(
        map(results => {
          // set options
          const options = new Map<string, Array<Option>>();
          observables.forEach(({ key }, index) => {
            options.set(key, results[index]);
          });

          // add tableRows to column definitions
          if (controlTable.tableRows && controlTable.tableRows.length > 0) {
            controlTable.tableRows.forEach(
              row =>
                (columnDefinitions = columnDefinitions.concat(
                  this.getColumnDefinitionsForDimensionDataCollection(
                    row,
                    Dimension.x,
                    controlTable,
                    options,
                    gridOptions
                  )
                ))
            );
          }

          // add tableColumn.fields to column definitions
          if (controlTable.tableColumns && controlTable.tableColumns.length > 0) {
            // todo: tableColumns should be an object instead of an array, for now just pluck off the first row
            const tableColumn = controlTable.tableColumns[0];

            switch (tableColumn.objectID) {
              case DataCollectionType.Dimension:
                columnDefinitions = columnDefinitions.concat(
                  this.getColumnDefinitionsForDimensionDataCollection(
                    tableColumn,
                    Dimension.y,
                    controlTable,
                    options,
                    gridOptions
                  )
                );
                break;
              case DataCollectionType.Manual:
                columnDefinitions = columnDefinitions.concat(
                  this.getColumnDefinitionsForManualDataCollection(
                    tableColumn,
                    controlTable,
                    options,
                    gridOptions
                  )
                );
                break;
            }
          }

          return columnDefinitions;
        })
      );
    }

    // add tableRows to column definitions
    if (controlTable.tableRows && controlTable.tableRows.length > 0) {
      controlTable.tableRows.forEach(
        row =>
          (columnDefinitions = columnDefinitions.concat(
            this.getColumnDefinitionsForDimensionDataCollection(
              row,
              Dimension.x,
              controlTable,
              null,
              gridOptions
            )
          ))
      );
    }

    // add tableColumn.fields to column definitions
    if (controlTable.tableColumns && controlTable.tableColumns.length > 0) {
      // todo: tableColumns should be an object instead of an array, for now just pluck off the first row
      const tableColumn = controlTable.tableColumns[0];

      switch (tableColumn.objectID) {
        case DataCollectionType.Dimension:
          columnDefinitions = columnDefinitions.concat(
            this.getColumnDefinitionsForDimensionDataCollection(
              tableColumn,
              Dimension.y,
              controlTable,
              null,
              gridOptions
            )
          );
          break;
        case DataCollectionType.Manual:
          columnDefinitions = columnDefinitions.concat(
            this.getColumnDefinitionsForManualDataCollection(
              tableColumn,
              controlTable,
              null,
              gridOptions
            )
          );
          break;
      }
    }

    return of(columnDefinitions);
  }

  private getCellEditorParamsForCollectionDim(
    collectionDim: CollectionDim,
    options: Map<string, Option[]>
  ): any {
    if (!options) {
      return null;
    }
    if (!options.has(collectionDim.key)) {
      return null;
    }
    return {
      values: options.get(collectionDim.key),
      formatValue: item => (item && item.label) || item || ''
    };
  }

  private getCellEditorParamsForControl(
    control: TableFieldControl,
    options: Map<string, Option[]>
  ): any {

    switch (control.objectID) {
      case ControlType.Dropdown:
      case ControlType.AutoComplete:
        if (!control.items) return;

        switch (control.items.objectID) {
          case DataSourceType.DatabaseCall:
            switch (control.items.dbCall.objectID) {
              case DbCallType.Function:
                if (!options) {
                  return null;
                }
                if (!options.has(control.key)) {
                  return null;
                }
                let values = options.get(control.key).map(option => option.value);
                // Add the blank/null option to the top of the dropdown options
                if (control["allowNull"]) {
                  values = ["", ...values]
                }

                return {
                  options: options,
                  values: values,
                  formatValue: item => {
                    if (!options.has(control.key)) {
                      return '';
                    }

                    // attempt to get option by value
                    let option = options
                      .get(control.key)
                      .find(option => Number(option.value) === Number(item));
                    if (option) {
                      return option.label;
                    }

                    // attempt to get option by label
                    option = options.get(control.key).find(option => option.label === item);
                    if (option) {
                      return option.label;
                    }

                    return item;
                  }
                };
                break;

              case DbCallType.Sql: {
                // todo: deprecate DbSQL
                const dbCall = control.items.dbCall as DbSql;
                return {
                  values: dbCall.data,
                  formatValue: item => {
                    return (item && item.label) || item || '';
                  }
                };
                break;
              }
            }
            break;

          case DataSourceType.Manual: {
            let items = control.items.manualItems;
            // Add the blank/null option to the top of the dropdown options
            if (control["allowNull"]) {
              items = ["", ...items]
            }

            return {
              values: items,
              control: control
            };
            break;
          }
        }
        break;

      default:
        return {
          control: control
        };
    }
  }

  private getColumnDefinitionsForDimensionDataCollection(
    collectionDim: CollectionDim,
    dimension?: Dimension,
    controlTable?: ControlTable,
    options?: Map<string, Option[]>,
    gridOptions?: GridOptions
  ): ColDef[] {
    switch (dimension) {
      case Dimension.x:
        return [
          this.getColumnDefinitionForXDimensionDataCollection(
            collectionDim,
            controlTable,
            options,
            gridOptions
          )
        ];
      case Dimension.y:
        return this.getColumnDefinitionForYDimensionDataCollection(
          collectionDim,
          controlTable,
          options,
          gridOptions
        );
    }
  }

  private customComparatorForXDim(valueA, valueB, nodeA, nodeB, key) {
    // If this column uses refData, look up and use the decoded value from the map
    const refData = nodeA?.gridApi?.getColumnDef(key)?.refData;
    if (refData) {
      valueA = refData[valueA];
      valueB = refData[valueB];
    }

    if (valueA > valueB) {
      return 1;
    } else if (valueB > valueA) {
      return -1;
    }

    return 0;
  }

  private getColumnDefinitionForXDimensionDataCollection(
    collectionDim: CollectionDim,
    controlTable?: ControlTable,
    options?: Map<string, Option[]>,
    gridOptions?: GridOptions
  ): ColDef {
    let colDef: any = {
      comparator: (a, b, nodeA, nodeB) => this.customComparatorForXDim(a, b, nodeA, nodeB, collectionDim.key),
      wrapText: true,
      headerName: collectionDim.title,
      headerTooltip: collectionDim.description,
      tooltipValueGetter: params => {
        if (!params.value) {
          console.error();
        }
        if (params.value && typeof params.value === 'object' && 'gridMouseOver' in params.value) {
          return params.value.gridMouseOver;
        }

        return params.value;
      },
      field: collectionDim.key,
      colId: collectionDim.key,
      filter: GridColumnFilterType.MultiSelect,
      filterParams: { newRowsAction: 'keep' },
      hide: collectionDim.hide,
      valueGetter: this.valueGetterForXCollectionDim.bind(null, controlTable, collectionDim, options),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: this.getCellEditorParamsForCollectionDim(collectionDim, options),
      pivot: 'pivot' in collectionDim ? collectionDim['pivot'] : false,
      rowGroup: 'rowGroup' in collectionDim ? collectionDim['rowGroup'] : false,
      cellRendererFramework: GridChangeCellRendererComponent,
      cellRendererParams: {
        context: {
          controlTable: controlTable.id
        }
      },
      suppressSizeToFit: false,
      pinned: 'left',
      autoHeight: gridOptions && !gridOptions.rowHeight,
      suppressMenu: true,
    };

    if (collectionDim.columnFilter && collectionDim.columnFilter.type) {
      colDef.filter = GridColumnFilterType[collectionDim.columnFilter.type];
    }

    // non-editable column
    if (
      collectionDim.objectID == DataCollectionType.Dimension ||
      ('editable' in collectionDim && collectionDim['editable'] == false) ||
      collectionDim.readonly
    ) {
      colDef.editable = false;
      colDef.cellStyle = { color: 'gray' };
    }

    if ('columnWidth' in controlTable) {
      colDef.width = controlTable['columnWidth'];
      colDef.suppressSizeToFit = true;
      colDef.wrapText = true;
      colDef.autoHeight = gridOptions && !gridOptions.rowHeight;
    }
    if ('columnWidth' in collectionDim) {
      colDef.width = collectionDim['columnWidth'];
      colDef.suppressSizeToFit = true;
      colDef.wrapText = true;
      colDef.autoHeight = gridOptions && !gridOptions.rowHeight;
    }
    if ('sort' in collectionDim) {
      colDef.sort = collectionDim['sort'];
    }
    if ('pinned' in collectionDim) {
      colDef.pinned = collectionDim['pinned'];
    }
    if ('aggFunc' in collectionDim) {
      colDef.aggFunc = collectionDim['aggFunc'];
    }
    // if it's a date, sort properly, regardless of date format
    if ('dataType' in collectionDim && collectionDim['dataType'] === DataType.Date) {
      colDef.cellEditorPopup = true;
      colDef.cellEditorFramework = GridDatePickerComponent;
      colDef.pivotComparator = (a, b) => {
        if (moment(a).isSameOrBefore(b)) {
          return -1;
        } else if (moment(b).isSameOrBefore(a)) {
          return 1;
        } else {
          return 0;
        }
      };
      colDef.filterParams = {
        comparator: this.dateFilterComparator
      };

      colDef.minWidth = 148;

      colDef.comparator = (a,b) => {
        a = new Date(a);
        b = new Date(b);

        if (a > b) {
          return 1;
        } else if (b > a) {
          return -1;
        }

        return 0;
      }
    }

    // LongText inputs should have a maxWidth
    if ('dataType' in collectionDim && collectionDim['dataType'] === DataType.Longtext) {
      colDef.width = 330;
      colDef.wrapText = true;
    }

    return colDef;
  }

  private getColumnDefinitionForYDimensionDataCollection(
    collectionDim: CollectionDim,
    controlTable?: ControlTable,
    options?: Map<string, Option[]>,
    gridOptions?: GridOptions
  ): ColDef[] {
    switch (controlTable.tableType) {
      case TableType.Dimension:
        // verify dbCall is a Function
        if (collectionDim.dimMembers.dbCall.objectID === DbCallType.Sql) {
          throw new Error(
            'Cannot determine column definition for a dimension data collection using SQL.'
          );
        }

        // return array of column definitions for each option
        return options.get(collectionDim.key).map(option => {
          let label;
          let colDef: any = {
            wrapText: true,
            filterParams: { newRowsAction: 'keep' },
            field: option.value,
            colId: option.value,
            filter: GridColumnFilterType.MultiSelect,
            headerName: option.label || option.value,
            headerTooltip: option.label || option.value,
            hide: collectionDim.hide,
            valueGetter: this.valueGetterForYCollectionDim.bind(
              null,
              collectionDim,
              options,
              option
            ),
            valueFormatter: params => {
              const jsonMetadata = this.getGridCellJson(params.value);
              if (jsonMetadata) {
                return jsonMetadata.value;
              }

              return params.value;
            },
            pivot: 'pivot' in option ? option['pivot'] : false,
            rowGroup: 'rowGroup' in option ? option['rowGroup'] : false,
            cellRendererFramework: GridChangeCellRendererComponent,
            cellRendererParams: {
              context: {
                controlTable: controlTable.id
              }
            },
            cellEditorParams: params => {
              const jsonMetadata = this.getGridCellJson(params.value);
              if (jsonMetadata) {
                params.value = jsonMetadata.value;
              }

              return params;
            },
            valueSetter: this.valueSetterForDim.bind(this, option),
            singleClickEdit: false,
            suppressSizeToFit: false,
            // Handle possible JSON "value" from db for this cell, parse for other properties if so
            cellStyle: params => {
              const jsonMetadata = this.getGridCellJson(params.value);
              if (jsonMetadata && jsonMetadata.gridColor) {
                return { backgroundColor: jsonMetadata.gridColor };
              }
            },
            editable: params => {
              if (controlTable.readonly || collectionDim.readonly) return false;

              if (params) {
                const jsonMetadata = this.getGridCellJson(params.data[option.value]);
                if (jsonMetadata && 'editable' in jsonMetadata) {
                  return jsonMetadata.editable;
                }
              }

              if ('editable' in collectionDim) {
                return collectionDim.editable;
              }

              return true;
            },
            suppressMenu: true,
            type: 'numericColumn',
          };

          if (collectionDim.columnFilter && collectionDim.columnFilter.type) {
            colDef.filter = GridColumnFilterType[collectionDim.columnFilter.type];
          }

          // columnWidth set on Table in schema
          if ('columnWidth' in controlTable) {
            colDef.width = controlTable['columnWidth'];
            colDef.suppressSizeToFit = true;
            colDef.wrapText = true;
            colDef.autoHeight = gridOptions && !gridOptions.rowHeight;
          }
          // columnWidth set on (column) collectionDim in schema
          if ('columnWidth' in collectionDim) {
            colDef.width = collectionDim['columnWidth'];
            colDef.suppressSizeToFit = true;
            colDef.wrapText = true;
            colDef.autoHeight = gridOptions && !gridOptions.rowHeight;
          }
          if ('sort' in option) {
            colDef.sort = option['sort'];
          }
          if ('aggFunc' in option) {
            colDef.aggFunc = option['aggFunc'];
          }
          if ('pinned' in option) {
            colDef.pinned = option['pinned'];
            colDef.wrapText = true;
            colDef.autoHeight = gridOptions && !gridOptions.rowHeight;
          }
          // if it's a date, sort properly, regardless of date format
          if ('dataType' in option && option['dataType'] === DataType.Date) {
            colDef.cellEditorFramework = GridDatePickerComponent;
            colDef.pivotComparator = (a, b) => {
              if (moment(a).isSameOrBefore(b)) {
                return -1;
              } else if (moment(b).isSameOrBefore(a)) {
                return 1;
              } else {
                return 0;
              }
            };
            colDef.filterParams = {
              comparator: this.dateFilterComparator
            };

            colDef.minWidth = 148;

            colDef.comparator = (a,b) => {
              a = new Date(a);
              b = new Date(b);

              if (a > b) {
                return 1;
              } else if (b > a) {
                return -1;
              }

              return 0;
            }
          }

          // LongText inputs have a maxWidth
          if ('dataType' in option && option['dataType'] === DataType.Longtext) {
            if (!collectionDim['columnWidth']) colDef.width = 330;
            colDef.wrapText = true;
          }

          // If schema has tableControl object, this is supposed to be the type of cell component
          if (controlTable.tableControl) {
            const tableControl: TableFieldControl = controlTable.tableControl as TableFieldControl;

            switch (tableControl.objectID) {
              case ControlType.Input:
                if (tableControl.dataType === 'boolean') {
                  colDef.cellClass = 'booleanType';

                  // Pass a custom parameter called disabled that is used by the checkbox
                  colDef.cellRendererParams = {...colDef.cellRendererParams, 'disabled': colDef.editable}
                  colDef.cellRendererFramework = GridCheckboxComponent;
                  
                  // Make the cell not ediable (only the checkbox renderer is editable)
                  colDef.editable = false;
                }
            }
          }

          return colDef;
        });
      case TableType.DimensionOrManual:
      case TableType.Manual:
        return [
          this.getColumnDefinitionForXDimensionDataCollection(collectionDim, controlTable, options, gridOptions)
        ];
    }
  }

  private dateFilterComparator = (filterDate: Date, cellValue: string | Date): number => {
    if (!cellValue || cellValue === 'Invalid date') return -1;
    
    const cellAsDate = moment(cellValue).toDate();

    if (cellAsDate < filterDate) {
      return -1;
    }
    if (cellAsDate > filterDate) {
      return 1;
    }
    // If null or empty string, return -1 (exclude) else 0 (include)
    return !cellValue ? -1 : 0;
  };

  private getColumnDefinitionsForManualDataCollection(
    manualDataCollection: ManualDataCollection,
    controlTable?: ControlTable,
    options?: Map<string, Option[]>,
    gridOptions?: GridOptions
  ): ColDef[] {
    return manualDataCollection.fields.map((control: TableFieldControl) => {
      let colDef: any = {
        wrapText: true,
        cellStyle: this.getCellStyle.bind(this, control),
        hide: control.objectID === ControlType.Hidden,
        headerName: control.title,
        headerTooltip: control.description,
        tooltipValueGetter: params => {
          if (params.value && typeof params.value === 'object' && 'gridMouseOver' in params.value) {
            return params.value.gridMouseOver;
          }

          return params.value;
        },
        field: control.key,
        colId: control.key,
        filter: this.getFilterType(control),
        filterParams: { newRowsAction: 'keep' },
        filterValueGetter: this.filterValueGetter.bind(this, control, options),
        valueFormatter: this.valueFormatter.bind(this, control),
        valueGetter: this.valueGetterForControl.bind(null, control, options),
        cellEditorParams: this.getCellEditorParamsForControl(control, options),
        pivot: 'pivot' in control && control['pivot'],
        rowGroup: 'rowGroup' in control && control['rowGroup'],
        editable: true,
        cellRendererFramework: GridChangeCellRendererComponent,
        cellRendererParams: {
          context: {
            controlTable: controlTable.id
          }
        },
        suppressSizeToFit: false,
        suppressMenu: true
      };

      // Column should not be editable for if it's a Display control type or Editable: false in schema
      if (control.objectID == ControlType.Display || ('editable' in control && !control.editable) || control.readonly) {
        colDef.editable = false;
        colDef.cellStyle = { color: 'gray' };
      }

      // append these remaining properties only if they exist
      if ('aggFunc' in control) {
        colDef.aggFunc = control['aggFunc'];
        colDef.cellEditorFramework = GridEditAggValuesComponent;
        colDef.headerName = '';
        colDef.suppressAggFuncInHeader = true;
        colDef.minWidth = 30;
      }
      if ('sort' in control) {
        colDef.sort = control['sort'];
      }
      if ('pinned' in control) {
        colDef.pinned = control['pinned'];
        colDef.wrapText = true;
        colDef.autoHeight = gridOptions && !gridOptions.rowHeight;
      }
      if ('columnWidth' in controlTable) {
        colDef.width = controlTable['columnWidth'];
        colDef.suppressSizeToFit = true;
        colDef.wrapText = true;
        colDef.autoHeight = gridOptions && !gridOptions.rowHeight;
      }
      if ('columnWidth' in control) {
        colDef.width = control['columnWidth'];
        colDef.suppressSizeToFit = true;
        colDef.wrapText = true;
        colDef.autoHeight = gridOptions && !gridOptions.rowHeight;
      }

      switch ('dataType' in control && control['dataType']) {
        case 'boolean':
            colDef.cellClass = 'booleanType';

            // Pass a custom parameter called disabled that is used by the checkbox
            colDef.cellRendererParams = {...colDef.cellRendererParams, 'disabled': colDef.editable}
            colDef.cellRendererFramework = GridCheckboxComponent;
            
            // Make the cell not ediable (only the checkbox renderer is editable)
            colDef.editable = false;
          break;

        case 'longtext':
          // colDef.cellRendererFramework = GridRichTextComponent;
          colDef = this.setLargeTextEditor(colDef);
          if (!control['columnWidth']) colDef.width = 330;
          colDef.wrapText = true;
          break;

        case 'date':
          colDef.cellEditorFramework = GridDatePickerComponent;
          colDef.pivotComparator = (a, b) => {
            if (moment(a).isSameOrBefore(b)) {
              return -1;
            } else if (moment(b).isSameOrBefore(a)) {
              return 1;
            } else {
              return 0;
            }
          };
          colDef.filterParams = {
            comparator: this.dateFilterComparator,
            control: control,
            inRangeInclusive: true
          };
          colDef.filter = 'agDateColumnFilter';

          colDef.minWidth = 148;

          colDef.comparator = (a,b) => {
            a = new Date(a);
            b = new Date(b);

            if (a > b) {
              return 1;
            } else if (b > a) {
              return -1;
            }

            return 0;
          }
          break;

        // Convert string to a number to sort properly
        case 'integer':
        case 'money':
        case 'number':
        case 'percent':
          colDef.comparator = (a, b) => {
            a = parseFloat(a);
            b = parseFloat(b);
            if (a > b) {
              return 1;
            } else if (b > a) {
              return -1;
            }

            return 0;
          };
          break;

      }

      // append a few more options depending on control type
      switch (control.objectID) {
        // Dropdown
        case ControlType.Dropdown:
          colDef.valueSetter = this.valueSetterForDropdown.bind(this, control, options),
          colDef.cellEditor = 'agRichSelectCellEditor';
          // Only add dropdown error if cell is editable
          if (control.editable !== false && colDef.editable !== false) {
            colDef.cellClass = 'select-cell'
          }
          break;

        // AutoComplete
        case ControlType.AutoComplete:
          colDef.valueSetter = this.valueSetterForDropdown.bind(this, control, options);
          colDef.cellEditorFramework = GridAutoCompleteComponent;
          break;
      }

      return colDef;
    });
  }

  private setLargeTextEditor(colDef: ColDef) {
    return {
      ...colDef,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: '999999'
      }
    };
  }

  private getCellStyle(control: TableFieldControl, params: CellClassParams) {
    const styleObj: any = {
      // borderStyle: 'none'
    };

    // If this is a long ass field, make it align top not center
    if (params.value && params.value.length > 200) {
      styleObj.alignItems = 'flex-start';
    }

    if (control.objectID === ControlType.Display || ('editable' in control && !control.editable)) {
      styleObj.color = 'gray';
    }

    switch ('dataType' in control && control['dataType']) {
      case 'json' && params.value && params.value.gridColor:
        styleObj.backgroundColor = params.value.gridColor;
        break;
      case 'integer':
      case 'money':
      case 'number':
      case 'percent':
        styleObj.textAlign = 'right';
        break;
    }

    // Add a red outline around cell if column has the valueRequired property in the pagedef and the cell is missing a value
    if (control.valueRequired && isEmpty(params.value)) {
      styleObj.borderColor = 'red';
      styleObj.borderStyle = 'solid';
    }

    return !isEmpty(styleObj) ? styleObj : null;
  }

  private valueSetterForDropdown(control: TableFieldControl, options, params: any) {
    let value = params.newValue;

    // No value change, return false so grid cell doesn't "refresh"
    if (params.oldValue === params.newValue) return false;

    // Special handling for dropdown and autocomplete
    if (options && options.get(control.key) != undefined) {
      // Attempt to get the value from the list of options by matching the value being passed to the option.value
      let valFromOptions = options.get(control.key).find(option => option.value == params.newValue);

      // If no match found, attempt to get the value from the list of options by matching the value being passed to the option.label
      if (valFromOptions == undefined) {
        valFromOptions = options.get(control.key).find(option => option.label == params.newValue);
      }

      // Set the value to the found option.value
      if (valFromOptions) {
        value = valFromOptions.value;
      }
    }

    // Set the value and return true to tell grid to refresh the cell
    params.data[control.key] = value;
    return true;
  }

  private valueSetterForDim(option: any, params: any) {
    // resolves dimension values not saving
    if (params.oldValue !== params.newValue) {
      params.data[option.value] = params.newValue;
      return true;
    }

    return false;
  }

  private getFilterType(control: TableFieldControl): string | null {
    if (control.columnFilter && control.columnFilter.type)
      return GridColumnFilterType[control.columnFilter.type];

    switch (control.objectID) {
      case ControlType.AutoComplete:
      case ControlType.Dropdown:
        return GridColumnFilterType.MultiSelect;
      case ControlType.Display:
      case ControlType.Input:
        switch (control.dataType) {
          case DataType.Date:
            return GridColumnFilterType.Date;
          case DataType.Integer:
          case DataType.Number:
          case DataType.Money:
          case DataType.Percent:
            return GridColumnFilterType.Number;
          case DataType.Text:
            return GridColumnFilterType.Text;
          case DataType.Boolean:
            return GridColumnFilterType.MultiSelect;
          default:
            return GridColumnFilterType.Text;
        }
      default:
        return GridColumnFilterType.Text;
    }
  }

  private valueGetterForXCollectionDim(
    controlTable: ControlTable,
    collectionDim: CollectionDim,
    options: Map<string, Option[]>,
    params: ValueGetterParams,
  ): string | number {
    const value = params.data[collectionDim.key];

    // If no options, or the key isn't in the list, return the value, instead of looking for the label from the options map
    if (!options ||!options.has(collectionDim.key)) {
      return value;
    }

    const option = options
      .get(collectionDim.key)
      .find(option => Number(option.value) === Number(value));
    return (option && option.label) || value;
  }

  private valueGetterForYCollectionDim(
    collectionDim: CollectionDim,
    options: Map<string, Option[]>,
    option: Option,
    params: ValueGetterParams
  ): string {
    if (!options || !options.has(collectionDim.key)) {
      return null;
    }

    return params.data[option.value];
  }

  private valueGetterForControl(
    control: TableFieldControl,
    options: Map<string, Option[]>,
    params: ValueGetterParams
  ): string | number {
    try {
      if (!params.data || params.data[control.key] === undefined) return null;

      const value = params.data[control.key];

      // JSON data type: avoid null json values rendering as [object, Object]
      if (
        control.objectID != ControlType.Dropdown &&
        'dataType' in control &&
        control['dataType'] === 'json' &&
        typeof value === 'object'
      ) {
        return value.value === '' ? null : value;
      }

      // Dropdown or AutoComplete control
      if (
        control.objectID === ControlType.Dropdown ||
        control.objectID === ControlType.AutoComplete
      ) {
        if (!control.items) return null;
        switch (control.items.objectID) {
          // Database Call
          case DataSourceType.DatabaseCall:
            switch (control.items.dbCall.objectID) {
              case DbCallType.Function: {
                if (!options) {
                  return null;
                }
                if (!options.has(control.key)) {
                  return null;
                }
                const option = options
                  .get(control.key)
                  .find(option => Number(option.value) === Number(value));
                if (option) {
                  return option.label;
                }
                return value;
              }
              case DbCallType.Sql: {
                // todo: deprecate DbSQL
                const dbCall = control.items.dbCall as DbSql;
                const displayData = dbCall.data.find(item => Number(item.value) === Number(value));
                return (displayData && displayData.label) || value;
              }
            }
            break;

          // Manual
          case DataSourceType.Manual: {
            const items = control.items as ManualItems;
            return items.manualItems.find(item => item === value) || value;
          }
        }
      }

      return value;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  private filterValueGetter(control: TableFieldControl, options: Map<string, Option[]>, params: ValueGetterParams) {
    const val = params.data[params.colDef.field];

    if ((control.objectID == ControlType.AutoComplete || control.objectID == ControlType.Dropdown) && options) {
      return this.valueGetterForControl(control, options, params);
    }

    switch ('dataType' in control && control['dataType']) {
      case DataType.Integer:
      case DataType.Number:
      case DataType.Money:
      case DataType.Percent:
        return Number(val);
      case DataType.Date:
        const dateFormat: string = control['dateFormat'] ? control['dateFormat'] : this.dateService.dateFormat;
        const dateObject = moment(val);
        return dateObject.format(dateFormat);
    }

    return val;
  }

  private valueFormatter(
    control: TableFieldControl,
    params: ValueFormatterParams
  ): string | number {
    let value = params.value;

    if (!value) {
      return value;
    }

    switch (value && 'dataType' in control && control['dataType']) {
      case 'json':
        if ('value' in value) {
          value = value.value === '' ? null : value.value;
        }
        break;

      case DataType.Money:
        value = this.currencyPipe.transform(Number(params.value));
        break;

      case DataType.Integer || DataType.Number:
        value = Number(value);
        break;

      case DataType.Date:
        const dateFormat: string = control['dateFormat'] ? control['dateFormat'] : this.dateService.dateFormat;
        const dateObject = moment(value);
        value = dateObject.format(dateFormat);
        break;

      case DataType.Percent:
        value = this.percentPipe.transform(Number(params.value / 100));
        break;
    }

    return value;
  }
}
