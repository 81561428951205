import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteHistoryService {
  currentUrl: string;
  previousUrl: string;

  constructor(private router: Router) {
    this.currentUrl = router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  getCurrentUrl() {
    return this.currentUrl.replace('/', '');
  }

  getPreviousUrl() {
    return this.previousUrl.replace('/', '');
  }
}
