import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core'
import { AgEditorComponent } from 'ag-grid-angular';
import { ControlInput, ControlDisplay, DateService } from '@compass/core-data';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-ag-grid-datepicker',
  templateUrl: './grid-date-editor.component.html',
  styleUrls: ['./grid-date-editor.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useClass: DateService}
  ]
})
export class GridDatePickerComponent implements AgEditorComponent {
  @Input() control: ControlInput | ControlDisplay;
  
  gridValue: string;
  columnWidth: string;
  params: any;
  formGroup: FormGroup = new FormGroup({});
  filterDate: Date;
  displayCalendarDays = true;
  dateFormControl = new FormControl(); // The input tied to the mat-datepicker

  @ViewChild('datePicker', { read: MatDatepicker}) datePicker: MatDatepicker<Moment>;
  @ViewChild('dateInput', { static: true }) dateInput: ElementRef<HTMLInputElement>;

  constructor(@Inject(MAT_DATE_FORMATS) public dateService: DateService) { }

  onClose() {
    if (this.params.stopEditing) this.params.stopEditing();
  }

  isPopup(): boolean {
    return true;
  }

  afterGuiAttached(): void {
    // Focus and select the dateInput
    this.dateInput.nativeElement.focus();
    this.dateInput.nativeElement.select();
  }

  agInit(params: any): void {
    // TODO: make the background of the popup white
    this.dateInput.nativeElement.style.paddingLeft = '8px';

    this.params = params;
    this.control = params.control;
    this.gridValue = params.value;
    // Set the dateFormat property in the CompassDateFormats class
    if (this.control?.dateFormat) {
      this.dateService.dateFormat = this.control.dateFormat;
      this.dateService.displayCalendarDays = this.control.dateFormat.toLowerCase().indexOf('d') >= 0;
    }

    // Set the date
    this.dateFormControl.setValue(moment(this.gridValue));
  }

  // This is the raw value in the grid (in the pg date format of YYYY-MM-DD)
  getValue(): string {
    // Allow user to reset the date to null
    if (!this.gridValue) {
      return null;
    }

    try {
      return this.gridValue.toString();
    } catch(e) {
      console.error(e);
    }
  }

  onDateInput(value: Moment) {
    this.gridValue = value ? value.format(this.dateService.dateValDefaultFormat) : null;
  }

  onDateChange(value) { }

  monthSelected(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    this.datePicker.select(normalizedMonth);
    this.datePicker.close();
  }
}
