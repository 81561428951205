import { Action } from '@ngrx/store';
import { Item } from '@compass/core-data';

export enum ItemsActionTypes {
  ITEMS_ACTION  = '[ITEM] Items Action',
  ITEM_SELECTED = '[ITEM] Item Selected',
  LOAD_ITEMS    = '[ITEM] Load Items',
  ITEMS_LOADED  = '[ITEM] Items Loaded',
  ADD_ITEM      = '[ITEM] Add Item',
  ITEM_ADDED    = '[ITEM] Item Added',
  UPDATE_ITEM   = '[ITEM] Update Item',
  ITEM_UPDATED  = '[ITEM] Item Updated',
  DELETE_ITEM   = '[ITEM] Delete Item',
  ITEM_DELETED  = '[ITEM] Item Deleted'
}

export class Items implements Action {
  readonly type = ItemsActionTypes.ITEMS_ACTION;
}

export class ItemSelected implements Action {
  readonly type = ItemsActionTypes.ITEM_SELECTED;
  constructor(public payload) {}
}

export class LoadItems implements Action {
  readonly type = ItemsActionTypes.LOAD_ITEMS;
  constructor() {}
}

export class ItemsLoaded implements Action {
  readonly type = ItemsActionTypes.ITEMS_LOADED;
  constructor(public payload: Item[]) {}
}

export class AddItem implements Action {
  readonly type = ItemsActionTypes.ADD_ITEM;
  constructor(public payload: Item) {}
}

export class ItemAdded implements Action {
  readonly type = ItemsActionTypes.ITEM_ADDED;
  constructor(public payload: Item) {}
}

export class UpdateItem implements Action {
  readonly type = ItemsActionTypes.UPDATE_ITEM;
  constructor(public payload: Item) {}
}

export class ItemUpdated implements Action {
  readonly type = ItemsActionTypes.ITEM_UPDATED;
  constructor(public payload: Item) {}
}

export class DeleteItem implements Action {
  readonly type = ItemsActionTypes.DELETE_ITEM;
  constructor(public payload: Item) {}
}

export class ItemDeleted implements Action {
  readonly type = ItemsActionTypes.ITEM_DELETED;
  constructor(public payload: Item) {}
}

export type ItemsActions = Items
  | ItemSelected
  | LoadItems
  | ItemsLoaded
  | AddItem
  | ItemAdded
  | UpdateItem
  | ItemUpdated
  | DeleteItem
  | ItemDeleted
  ;
