<compass-form-control
  *ngFor="let control of componentType.controls; index as i"
  [style.margin-right.px]="getMarginRight(control)"
  [ngStyle]="{width: control.pageWidth}"
  [form]="form"
  [control]="control"
  [section]="section"
  [schema]="schema"
  [formValues$]="formValues$"
  [index]="i"
></compass-form-control>

<ng-container *ngIf="componentType.actions && componentType.actions.length > 0">
  <div class="action-container">
    <compass-action
      *ngFor="let action of componentType.actions"
      [action]="action"
      [componentType]="componentType"
      [form]="form"
      [section]="section"
      [schema]="schema"
      (saved)="onSaved()"
    ></compass-action>
  </div>
</ng-container>
