import { Schema } from '@compass/core-data';
import { Action } from '@ngrx/store';

export enum SchemasActionTypes {
  LOAD_SCHEMA = '[SCHEMA] Load Schema',
  SCHEMA_LOADED = '[SCHEMA] Schema Loaded'
}

export class LoadSchema implements Action {
  readonly type = SchemasActionTypes.LOAD_SCHEMA;
  constructor(public payload: any) {}
}

export class SchemaLoaded implements Action {
  readonly type = SchemasActionTypes.SCHEMA_LOADED;
  constructor(public payload: any) {}
}

export type SchemasActions = LoadSchema | SchemaLoaded;
