<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{ control.title }}</mat-label>
    <!-- Hidden form control, uses relationalKey for it's name, so this is the control that holds the value for reads and writes to db -->
    <input
      type="text"
      [formControlName]="relationalKey"
      [value]="value"
      class="hidden"
    >
    <!-- Multi-Select -->
    <mat-select *ngIf="control.multiple"
      multiple
      [matTooltip]="control.description"
      [formControlName]="matSelectKey"
      (openedChange)="onOpenedChange($event)"
      >

      <ng-container *ngIf="(options | async) as options;">
        <!-- Div for Select All checkbox - to make it render and respond to click like an action -->
        <div *ngIf="options.length > 1;"
          class="mat-option" 
          (click)="selectAllChanged(!this.selectAllIsChecked())"
        >
          <!-- Select All checkbox -->
          <mat-checkbox
            (change)="selectAllChanged($event.checked)"
            (click)="selectAllChanged(!this.selectAllIsChecked())"
            [checked]="selectAllIsChecked()"
          >Select All
          </mat-checkbox>
        </div>
        <!-- Add the list of options -->
        <mat-option *ngFor="let option of options" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </ng-container>
      
    </mat-select>
    <!-- Single select -->
    <mat-select *ngIf="!control.multiple" 
      [matTooltip]="control.description"
      [formControlName]="matSelectKey"
    >
      <!-- Add the blank/null option to the dropdown -->
      <mat-option *ngIf="control.allowNull" [value]=""></mat-option>
      <!-- Add the list of options -->
      <mat-option *ngFor="let option of (options | async)" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
