import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@compass/core-window';
import { environment } from '@env/environment';

@Component({
  templateUrl: './login-success.component.html',
  styleUrls: ['./login-success.component.scss']
})
export class LoginSuccessComponent implements OnInit {
  constructor(private readonly router: Router, @Inject(WINDOW) private readonly window: Window) {}

  ngOnInit() {
    // attempt to redirect back to stored URL
    const url = this.window.localStorage.getItem(environment.localstorage.url);
    if (url && url != '/login/success') {
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl('/home');
    }
  }
}
