import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ComponentChart,
  GridType,
  Schema,
  Section
} from '@compass/core-data';

@Component({
  selector: 'compass-chart',
  templateUrl: './component-chart.component.html',
  styleUrls: ['component-chart.component.scss'],
})
export class CompassChartComponent {
  @Input() id: string;
  @Input() componentType: ComponentChart;
  @Input() form: FormGroup;
  @Input() schema: Schema;
  @Input() section: Section;

  ChartType = GridType;
}
