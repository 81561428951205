import gql from 'graphql-tag';

export const rolesFragment = gql`
  fragment rolesFragment on RoleDTO {
    rol_id
    rol_name
    rol_desc
  }
`;

export const loadRolesQuery = gql`
  query loadRolesQuery {
    loadRoles {
      ...rolesFragment
    }
  }
  ${rolesFragment}
`;

export const createRoleMutation = gql`
  mutation createRoleMutation($input: RoleInput!) {
    createRole(input: $input) {
      ...rolesFragment
    }
  }
  ${rolesFragment}
`;

export const updateRoleMutation = gql`
  mutation updateRoleMutation($patch: RolePatch!) {
    updateRole(patch: $patch) {
      ...rolesFragment
    }
  }
  ${rolesFragment}
`;

export const deleteRoleMutation = gql`
  mutation deleteRoleMutation($rol_id: Number!) {
    deleteRole(rol_id: $rol_id)
  }
`;
