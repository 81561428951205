import { Action } from '@ngrx/store';

export enum UiActionTypes {
  HIDE_SIDENAV = '[UI] Hide Sidenav',
  SHOW_SIDENAV = '[UI] Show Sidenav'
}

export class HideSidenav implements Action {
  readonly type = UiActionTypes.HIDE_SIDENAV;
}

export class ShowSidenav implements Action {
  readonly type = UiActionTypes.SHOW_SIDENAV;
}

export type UiAction = HideSidenav | ShowSidenav;
