import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentTableau, Schema, Section, User } from '@compass/core-data';
import { UsersFacade } from '@compass/core-state';
import { Subject } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { TableauDirective } from '../../core/directives/tableau/tableau.directive';

@Component({
  selector: 'compass-component-tableau',
  templateUrl: './component-tableau.component.html',
  styleUrls: ['./component-tableau.component.scss']
})
export class ComponentTableauComponent implements AfterViewInit, OnDestroy {
  viz;
  
  @Input() componentType: ComponentTableau;
  @Input() form: FormGroup;
  @Input() schema: Schema;
  @Input() section: Section;

  @ViewChild(TableauDirective, { static: true }) vizier: TableauDirective;

  /** Subject to trigger unsubcribe to observable streams. */
  unsubscribe = new Subject<void>();

  constructor(
    private userFacade: UsersFacade,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.populateTableau();
  }

  populateTableau() {
    this.userFacade.authenticatedUser$.pipe(
      tap((user: User) => {
        const options = {
          usr_name: user.usr_name,
          usr_id: user.usr_id,
          user_email: user.usr_email
        };
        this.viz = this.vizier.createViz(options)
      }),
      first()
    ).subscribe();
  }
}
