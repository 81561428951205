// soda.json and sock.json:

import { Db } from "./db.model"

export interface Assignment {
  id: number,
  title: string,
  scopes: Array<Scope>,
  override: {
    sodaExpression: string,
  }
};

export interface Scope {
  facts: Array<string>,
  includeAll?: boolean
}

export interface Soda extends Db {
  schema: string,
  id: number,
  title: string,
  socks: Array<any>,
  assignments: Array<Assignment>,
  functionCall: {
    keys?: string[],
    staticKeys?: any
  },
  keys?: string[],
  getDataSet?: any
  
}

export interface ScopeQryObj {
  includeAll: boolean,
  facts: string[],
  keys: string[],
  tableNames: string[]
};