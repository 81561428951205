import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-mdeditor',
  template: `<ckeditor #editor [ngModel]="model" (change)="onChange($event)" [editor]="Editor" [config]="config"></ckeditor>`
})
export class RichTextEditorComponent implements OnInit {
  @Input() model: any;
  @Output() modelChange = new EventEmitter<string>();
  @ViewChild( 'editor', { static: true } ) editorComponent: CKEditorComponent;

  public Editor = ClassicEditor;

  public config = {
    // toolbar: [
    //   'heading',
    //   '|',
    //   'bold',
    //   'italic',
    //   'link',
    //   'bulletedList',
    //   'numberedList',
    //   '|',
    //   'indent',
    //   'outdent',
    //   '|',
    //   'blockQuote',
    //   'undo',
    //   'redo'
    // ]
  }

  onChange(event: any) {
    const { editor } = event
    const data = editor.getData()
    this.modelChange.emit(data)
  }

  ngOnInit() {
    // For the time being, BSCI would like to remove the toolbar from all longtext controls
    delete this.Editor.defaultConfig.toolbar;
  }
}
