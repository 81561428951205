import 'ag-grid-enterprise';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { CoreDataModule } from '@compass/core-data';
import { CoreStateModule, GridAutoCompleteComponent, GridChangeCellRendererComponent, GridCheckboxComponent, GridDatePickerComponent, GridEditAggValuesComponent, GridRichTextComponent, GridDateFilterComponent } from '@compass/core-state';
import { CoreWindowModule, WINDOW_PROVIDERS } from '@compass/core-window';
import { MaterialModule } from '@compass/material';
import { UiLoginModule } from '@compass/ui-login';
import { UiToolbarModule } from '@compass/ui-toolbar';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AgGridModule } from 'ag-grid-angular';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { DragScrollModule } from 'ngx-drag-scroll';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import { NgxMaskModule } from 'ngx-mask';
registerPlugin(FilePondPluginGetFile);

import { AppComponent } from './app.component';
import { ActionComponent } from './budget-tool/action/action.component';
import { BreadcrumbComponent } from './budget-tool/breadcrumb/breadcrumb.component';
import { BudgetToolComponent } from './budget-tool/budget-tool.component';
import { ComponentCarouselComponent } from './budget-tool/component-carousel/component-carousel.component';
import { ComponentDataEntryComponent } from './budget-tool/component-data-entry/component-data-entry.component';
import { ComponentHtmlComponent } from './budget-tool/component-html/component-html.component';
import { ComponentJsChartComponent } from './budget-tool/component-js-chart/component-js-chart.component';
import { ComponentComponent } from './budget-tool/component/component.component';
import { ControlDisplayComponent } from './budget-tool/control-display/control-display.component';
import { ControlDropdownComponent } from './budget-tool/control-dropdown/control-dropdown.component';
import { ControlHiddenComponent } from './budget-tool/control-hidden/control-hidden.component';
import { ControlInputComponent } from './budget-tool/control-input/control-input.component';
// eslint-disable-next-line max-len
import { ComponentReportComponent } from './budget-tool/component-report/component-report.component';
import { ControlReportTableComponent } from './budget-tool/control-report-table/control-report-table.component';
import { ControlTableDimensionManualComponent } from './budget-tool/control-table-dimension-manual/control-table-dimension-manual.component';
import { ControlTableDimensionComponent } from './budget-tool/control-table-dimension/control-table-dimension.component';
import { ControlTableManualComponent } from './budget-tool/control-table-manual/control-table-manual.component';
import { ControlTableComponent } from './budget-tool/control-table/control-table.component';
import { FormControlComponent } from './budget-tool/form-control/form-control.component';
import { ParameterComponent } from './budget-tool/parameter/parameter.component';
import { SchemaComponent } from './budget-tool/schema/schema.component';
import { SectionComponent } from './budget-tool/section/section.component';
import { DialogConfirmationComponent } from './core/components/dialog-confirmation/dialog-confirmation.component';
import { DialogLoadingComponent } from './core/components/dialog-loading/dialog-loading.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { HomeComponent } from './home/home.component';
import { RoutingModule } from './routing.module';
import { UploadComponent } from './upload/upload.component';
import { ComponentTableauComponent } from './budget-tool/component-tableau/component-tableau.component';
import { TableauDirective } from './core/directives/tableau/tableau.directive';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AppRouteComponent } from './budget-tool/app-route/app-route-component';
import { CompassAgChartComponent } from './budget-tool/component-ag-chart/component-ag-chart.component';
import { CompassChartComponent } from './budget-tool/component-chart/component-chart.component';
import { ComponentFileUploadComponent } from './budget-tool/component-file-upload/component-file-upload.component';
import { ComponentInformationalComponent } from './budget-tool/component-informational/component-informational.component';
import { ControlAutoCompleteComponent } from './budget-tool/control-auto-complete/control-auto-complete.component';
import { ControlRichTextEditor } from './budget-tool/control-rich-text-editor/control-rich-text-editor.component';
import { RichTextEditorComponent } from './budget-tool/rich-text-editor/rich-text-editor.component';
import { TableComponent } from './budget-tool/table/table.component';
import { SodaUtilityComponent } from './soda-utility/soda-utility.component';
import { TrendingListComponent } from './tableau/trending-list/trending-list.component';
import { ControlInputDateComponent } from './budget-tool/control-input-date/control-input-date.component';

@NgModule({
  declarations: [
    ActionComponent,
    AppComponent,
    AppRouteComponent,
    BreadcrumbComponent,
    BudgetToolComponent,
    ComponentCarouselComponent,
    ComponentJsChartComponent,
    CompassAgChartComponent,
    CompassChartComponent,
    ComponentComponent,
    ComponentDataEntryComponent,
    ComponentFileUploadComponent,
    ComponentHtmlComponent,
    ComponentInformationalComponent,
    ControlDisplayComponent,
    ControlDropdownComponent,
    ControlHiddenComponent,
    ControlInputComponent,
    ControlTableComponent,
    ControlTableDimensionComponent,
    ControlTableDimensionManualComponent,
    ControlTableManualComponent,
    DialogLoadingComponent,
    FormControlComponent,
    GridCheckboxComponent,
    GridDatePickerComponent,
    GridDateFilterComponent,
    GridChangeCellRendererComponent,
    GridRichTextComponent,
    GridAutoCompleteComponent,
    ParameterComponent,
    SchemaComponent,
    SectionComponent,
    UploadComponent,
    ComponentReportComponent,
    ControlReportTableComponent,
    DialogConfirmationComponent,
    HomeComponent,
    GridEditAggValuesComponent,
    ComponentTableauComponent,
    TableauDirective,
    TrendingListComponent,
    RichTextEditorComponent,
    ControlRichTextEditor,
    ControlAutoCompleteComponent,
    TableComponent,
    SodaUtilityComponent,
    ControlInputDateComponent
  ],
  entryComponents: [
    DialogLoadingComponent,
    DialogConfirmationComponent,
    GridCheckboxComponent,
    GridDatePickerComponent,
    GridDateFilterComponent,
    GridEditAggValuesComponent,
    GridRichTextComponent,
    GridAutoCompleteComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ChartsModule,
    CoreStateModule,
    CoreDataModule,
    CoreWindowModule,
    DragScrollModule,
    FilePondModule,
    NgxFileDropModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MaterialModule,
    AgGridModule.withComponents([
      GridCheckboxComponent,
      GridDatePickerComponent,
      GridDateFilterComponent,
      GridChangeCellRendererComponent,
      GridEditAggValuesComponent,
      GridRichTextComponent,
      GridAutoCompleteComponent
    ]),
    AgChartsAngularModule,
    RoutingModule,
    StoreRouterConnectingModule.forRoot(),
    UiLoginModule,
    UiToolbarModule,
    NgxMaskModule.forRoot(),
    CKEditorModule
  ],
  providers: [
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    WINDOW_PROVIDERS,
    ThemeService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
