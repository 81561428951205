import gql from 'graphql-tag';

export const tableauFragment = gql`
  fragment tableauFragment on TableauViewDTO {
    viewId
    name
    description
    ownerId
    contentUrl
    contentDomain
    contentPureUrl
    workbookId
    workbookName
    thumbnailUrl
  }
`;

export const getWorkbookByIdFragment = gql`
  fragment getWorkbookByIdFragment on TableauWorkbookDTO {
    id
    name
    project {
      id
      name
    }
    views {
      id
      name
      contentUrl
    }
  }
`;

export const getTableauDataQuery = gql`
  query getTableauDataQuery {
    getWorkbookPreviews {
      ...tableauFragment
    }
  }
  ${tableauFragment}
`;

export const getTrustedTicket = gql`
  query GetTrustedTicket {
    getTrustedTicket
  }
`;

export const getWorkbookByIdQuery = gql`
  query getWorkbookByIdQuery($id: String!) {
    getWorkbookById(id: $id) {
      ...getWorkbookByIdFragment
    }
  }
  ${getWorkbookByIdFragment}
`;

export const searchTableauDataQuery = gql`
  query searchTableauDataQuery($searchString: String!) {
    searchTableaus(searchString: $searchString) {
      views {
        viewId
        name
        ownerId
        contentUrl
        workbookId
      }
      workbooks {
        project {
          id
          name
        }
        owner {
          id
        }
        id
        name
        contentUrl
      }
    }
  }
`;

export const getTrendingViewsQuery = gql`
  query getTrendingViewsQuery {
    getTrendingViews {
      ...tableauFragment
    }
  }
  ${tableauFragment}
`;
