
<div style="display: flex; flex-direction: row; justify-content: space-between;">
  <div style="display: flex; flex-direction: column; flex-basis: 80%;">
    <h2 class="col-12 mb-2" style="font-size: 16px;" *ngIf="control.title">{{control.title}}</h2>
    <p class="description" *ngIf="control.description">{{control.description}}</p>
  </div>
  <div style="align-self: flex-end;">
    <button mat-raised-button
      *ngIf="dspClearFilterBtn | async"
      class="mb-2"
      (click)="onClearFilters()"
    >Clear Filter(s)</button>
  </div>
</div>

<div style="width: 100%; position: relative;">
  <ag-grid-angular
    id="{{control.id}}"
    style="width: 100%;"
    class="ag-theme-balham"
    rowSelection="multiple"
    [domLayout]="getDomLayout()"
    [rowData]="rowData | async"
    [popupParent]="popupParent"
    [gridOptions]="gridOptions"
    [columnDefs]="columnDefs"
    (cellValueChanged)="onCellValueChanged($event)"
    (gridReady)="onGridReady($event)"
    (rowDataChanged)="onRowDataChanged($event)"
  >
  </ag-grid-angular>
  <div *ngIf="showLoadingOverlay" class="overlay" [style.height]="getGridHeight()"></div>
</div>


<div class="action-container">
  <!-- Save button (disabled until editing in table begins) -->
  <button
    *ngIf="control.tableSave"
    mat-raised-button
    class="mr-2 mb-2"
    color="primary"
    [matTooltip]="control.tableSave.description"
    [attr.alt]="control.tableSave.description"
    [disabled]="saveButtonEnabled ? false : !dataToSave.length"
    (click)="onSave()"
  >{{control.tableSave.label || 'Save'}}</button>
  <!-- Actions array from the table object in the schema (never disabled)-->
  <!-- These actions DO NOT use grid/cell changes, and therefore, DO NOT grey out -->
  <ng-container *ngIf="control.tableActions && control.tableActions.length > 0">
    <compass-action
      *ngFor="let action of control.tableActions"
      [action]="action"
      [form]="form"
      [section]="section"
      [schema]="schema"
    ></compass-action>
  </ng-container>
  
  <!-- Add and Delete Row for Manual tables (only) -->
  <div>
    <button mat-raised-button
      *ngIf="dspAddRowBtn | async"
      class="mr-2 mb-2"
      color="accent"
      [disabled]="!canCreateNewRow() || control.readonly"
      (click)="onAddRow()"
    >{{control.newRow && control.newRow.label || 'New Row'}}</button>
    <button mat-raised-button
      *ngIf="dspRemoveRowBtn | async"
      class="mr-2 mb-2"
      color="warn"
      [disabled]="disableRemoveRowBtn"
      (click)="onDeleteRow()"
    >{{control.deleteRow && control.deleteRow.label || 'Remove Row'}}</button>
  </div>
  <!-- Reapply Sort/Filter & Export -->
  <div style="margin-left: auto;">
    <button mat-raised-button
      *ngIf="dspReapplySortFilterBtn | async"
      class="mr-2 mb-2"
      (click)="onReapplySortFilter()"
    >Reapply Filter/Sort</button>
    <button mat-raised-button
      class="mb-2"
      (click)="onExport()"
      [disabled]="!canExport()"
    >Export</button>
  </div>
</div>
