import { Schema } from '@compass/core-data';
import { SchemasActions, SchemasActionTypes } from './schemas.actions';
export interface SchemasState {
  isLoading: boolean;
  schemas: {[name: string]: Schema};
}

export const initialState: SchemasState = {
  isLoading: false,
  schemas: {}
};

export function schemasReducer(state = initialState, action: SchemasActions): SchemasState {
  switch (action.type) {
    case SchemasActionTypes.SCHEMA_LOADED:
      return {
        ...state,
        schemas: {
          ...state.schemas,
          [action.payload.name]: action.payload.schema
        },
        isLoading: false
      };
    case SchemasActionTypes.LOAD_SCHEMA:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
}
