<!-- mat card for page-level title, subtitle, params, actions -->
<mat-card class="mx-3 mt-2" *ngIf="schema.title || schema.description || schema.parameters || schema.actions">
  <mat-card-title *ngIf="schema.title">{{ schema.title }}</mat-card-title>
  <mat-card-subtitle  *ngIf="schema.description">{{ schema.description }}</mat-card-subtitle>
  <mat-card-content class="d-flex flex-wrap">
    <!-- Parameters -->
    <div *ngIf="schema && schema.parameters" class="parameters">
      <compass-parameter
        *ngFor="let parameter of schema.parameters"
        [ngStyle]="{width: parameter.controlType.pageWidth}"
        [form]="form"
        [parameter]="parameter"
        [schema]="schema">
      </compass-parameter>
    </div>
    <!-- Actions -->
    <div *ngIf="schema && schema.actions">
      <compass-action
        *ngFor="let action of schema.actions"
        [action]="action"
        [form]="form"
        [schema]="schema">
      </compass-action>
    </div>
  </mat-card-content>
</mat-card>
<div id="content">
  <!-- Components -->
  <ng-container *ngIf="schema?.components">
    <mat-card class="mx-3 mt-2">
      <mat-card-title *ngIf="schema.title">{{ schema.title }}</mat-card-title>
      <mat-card-subtitle  *ngIf="schema.description">{{ schema.description }}</mat-card-subtitle>
      <mat-card-content class="d-flex flex-wrap">
        <compass-component
          *ngFor="let component of schema.components"
          [component]="component"
          [form]="form"
          [showBox]="schema.components.length > 1"
          [schema]="schema">
        </compass-component>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <!-- Only display sections if page level parameters have values -->
  <ng-container *ngIf="schema.sections && checkAllExistingParametersFulfilled()">
    <!-- Accordion of expansion panel sections -->
    <mat-accordion multi="true">
      <!-- For each Section: -->
      <ng-container *ngFor="let section of schema.sections; trackBy: trackSection">
        <!-- Expandable/Static section business logic: if there's a title and not staticSection (pagedef prop) then it's expandable -->
        <ng-container *ngIf="section.title && !section.staticSection; else regularSection">
          <mat-expansion-panel
            (opened)="saveSectionExpandStateToCache(true, section)"
            (closed)="saveSectionExpandStateToCache(false, section)"
            [expanded]="getSectionExpandStateFromCache(section.id)">
            <mat-expansion-panel-header class="mat-card-title" expandedHeight="48px">
              {{section.title}}
            </mat-expansion-panel-header>
            <!-- Section (no mat-card needed because the expansion panel provides the same CSS) -->
            <compass-section *ngIf="getSectionExpandStateFromCache(section.id)"
              [form]="form"
              [section]="section"
              [schema]="schema"
            >
            </compass-section>
          </mat-expansion-panel>
        </ng-container>
        <!-- Regular Sections -->
        <ng-template #regularSection>
          <!-- Need a mat-card here because not inside expansion panel -->
          <mat-card 
            class="mx-3 mt-2" 
            [ngStyle]="{ width: section.pageWidth }"
            [id]="section.id"
          >
            <!-- Section (non-expandable) -->
            <compass-section
              [form]="form"
              [section]="section"
              [schema]="schema"
              [dspMatCardTitle]="true"
            >
            </compass-section>  
          </mat-card>
        </ng-template>
      </ng-container>
    </mat-accordion>
  </ng-container>
</div>
