import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';

import { Baseline } from './baseline.model';
import {
  allBaselinesQuery,
  createDimensionsBaselineMutation,
  updateDimensionsBaselineMutation,
  deleteDimensionsBaselineMutation
} from './baseline.graphql';

@Injectable({
  providedIn: 'root'
})
export class BaselineService {
  constructor(private apollo: Apollo) { }

  all(): Observable<Baseline[]> {
    return this.apollo
      .query({
        query: allBaselinesQuery,
        fetchPolicy: 'network-only',
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.allBaselines)
      );
  }

  create(input: Baseline): Observable<Baseline> {
    const randomId = Math.floor(Math.random() * 100) + 1;
    const addIdToInput = {bas_id: randomId, ...input};
    return this.apollo
      .mutate({
        mutation: createDimensionsBaselineMutation,
        variables: {
          input: addIdToInput
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.createBaseline)
      );
  }

  update(patch: Baseline): Observable<Baseline> {
    delete (<any>patch).__typename;
    return this.apollo
      .mutate({
        mutation: updateDimensionsBaselineMutation,
        variables: {
          patch
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.updateBaseline)
      );
  }

  delete(id: string): Observable<Baseline> {
    return this.apollo
      .mutate({
        mutation: deleteDimensionsBaselineMutation,
        variables: {
          id
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.deleteBaseline)
      );
  }
}
