import { Directive, ElementRef, Input } from '@angular/core';

declare var tableau: any;

@Directive({
  selector: '[compassTableau]'
})
export class TableauDirective {
  @Input() url: string;

  constructor(private element: ElementRef) { }

  createViz(options: any, onFirstInteractive?: (_: any) => any): void {
    const viz = new tableau.Viz(this.element.nativeElement, this.createURL(), {
      usePublishedSize: true,
      width: '100%',
      height: '800px',
      ...options,
      onFirstInteractive,
      toolbarPosition: tableau.ToolbarPosition.TOP
    });

    return viz;
  }

  createURL(): string {
    const urlOptions = '?iframeSizedToWindow=true&:embed=y&:showAppBanner=true&:display_count=no&:showVizHome=no';
    const res = `${this.url}${urlOptions}`;
    return res;
  }

  
}
