import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'app-control-mdeditor',
  template: '<app-mdeditor [model]="value" (modelChange)="onChangeValue($event)"></app-mdeditor>',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlRichTextEditor),
      multi: true
    }
  ]
})
export class ControlRichTextEditor implements ControlValueAccessor {
  @Input() value: string;
  onChange = (_: any) => {};
  @Output() valueChange = new EventEmitter<string>();

  onChangeValue(val: any) {
    this.value = val;
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }

  writeValue(val: any) {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

}
