import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { NotifyService } from '@compass/core-data';
import { ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { format } from 'sql-formatter';

export const sodaApiQuery = gql`
  query sodaApiQuery($json: JSON, $debug: Boolean) {
    soda(json: $json, debug: $debug)
  }
`;

export const sockApiQuery = gql`
  query sockApiQuery($json: JSON, $debug: Boolean) {
    sock(json: $json, debug: $debug)
  }
`;

@Component({
  selector: 'compass-soda-utility',
  templateUrl: './soda-utility.component.html',
  styleUrls: ['./soda-utility.component.scss']
})
export class SodaUtilityComponent implements OnInit {

  @Input() value;

  @Input() data = {
    sql: '',
    results: null
  };

  form: FormGroup

  sodaSnippetControl: FormControl;

  isLoading$: Observable<boolean>;
  
  columnApi: ColumnApi;

  gridApi: GridApi;

  sodaSchema: JSON;
  
  sockSchema: JSON;
  
  validationError: string;

  validationSuccess: boolean = false;

  columnDefs: ColDef[] = [];

  rowData: [];

  gridOptions: GridOptions = {
    defaultColDef: {
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true
    },
    columnDefs: this.columnDefs,
    enableSorting: true,
    enableFilter: true,
    pagination: true
  };

  constructor(
    private apollo: Apollo,
    private readonly notifyService: NotifyService
  ) {}

  ngOnInit(): void {
    this.createFormControls();
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      sodaSnippetControl: this.sodaSnippetControl
    })
  }

  jsonValidator(): ValidatorFn {
    return (control: AbstractControl): (ValidationErrors | null) => {
      this.validationError = null;
      if (!control.value) return null;
      try {
        JSON.parse(control.value);
        this.validationSuccess = true;
      } catch (error) {
        console.error(error);
        this.validationError = error;
        return [error];
      }
    }
  };

  createFormControls() {
    this.sodaSnippetControl = new FormControl('', [
      Validators.required,
      this.jsonValidator()
    ]);
  }

  onSubmit() {
    try {
      let validJson: {};
      this.data.sql = null;
      this.data.results = null;
      this.rowData = null;
      this.validationError = null;
      this.validationSuccess = null;
      validJson = JSON.parse(this.sodaSnippetControl.value);

      // Display loading spinner
      this.isLoading$ = of(true)

      const jsonType = validJson['$schema'] && validJson['$schema'] == 'soda.json' ? 'soda' : 'sock';

      // Query backend graphql 
      this.apollo
        .query({
          query: jsonType == 'soda' ? sodaApiQuery : sockApiQuery,
          variables: {
            json: validJson,
            debug: true
          },
          fetchPolicy: 'network-only'
        })
        .pipe(
          first(),
          tap((resp: any) => {
            this.rowData = [];
            this.columnDefs = [];
            const respObject = resp.data[jsonType];
            // TODO - type this return object
            this.data.sql = format(respObject.sql, {
              uppercase: true
            })
            this.data.results = respObject.results

            
            const keys = Object.keys(this.data.results[0])
            keys.forEach(key => this.columnDefs.push({field : key}));
            this.rowData = this.data.results;
          }),
          catchError((err) => {
            // Hide loading spinner
            this.isLoading$ = of(false)
            this.validationError = err.message;

            return of(err);
          })
        )
        .subscribe({
          error: err => {
            console.error(err)
            this.notifyService.notifyError(err);
          },
          complete: () => this.isLoading$ = of(false)
        })
    } catch (error) {
      console.error(error)

      return this.notifyService.notifyError(error);
    }
  }

  onGridReady(gridOptions: GridOptions): void {
    this.gridApi = gridOptions.api;
    this.columnApi = gridOptions.columnApi;
    this.gridApi.setPopupParent(document.body);
  }

  onClickClear() {
    this.form.reset();
    this.validationError = null;
    this.data.sql = null;
    this.data.results = null;
  }

  onClickCopySql() {
    navigator.clipboard.writeText(this.data.sql);
  }

  onExport(): void {
    const json = JSON.parse(this.sodaSnippetControl.value);
    const fileName = json.$schema && json.$schema == 'soda.json' ? `soda_${json.id}` : `sock_${json.id}`;
    this.gridOptions.api.exportDataAsExcel({fileName: fileName});
  }
}
