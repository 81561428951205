import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Page } from '@compass/core-data';

@Component({
  selector: 'compass-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  /** The breadcrumbs to display. */
  @Input() breadcrumbs: Page[];

  /** Emit the breadcrumb when the selection has changed. */
  @Output() selectionChange = new EventEmitter<Page>();
}
