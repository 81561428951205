import { DataType } from "./data-type.model";

export enum Status {
  Active = "active",
  Deprecated = "deprecated"
}

export enum TableType {
  Base = "base",
  Feed = "feed",
  Calc = "calc",
  View = "view"
}

export interface DataPoint {
  name: string;
  description: string;
  dataType: DataType;
}

export interface Tabledef {
  $schema: string;
  schemaName: string;
  description: string;
  status: Status;
  tableName: string;
  tableType: TableType;
  keys: Array<string>;
  dataPoints: Array<DataPoint>;
}
  