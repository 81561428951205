import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@compass/material';

import { LoginFailureComponent } from './login-failure/login-failure.component';
import { LoginSuccessComponent } from './login-success/login-success.component';
import { LoginComponent } from './login/login.component';

const DIRECTIVES = [LoginComponent, LoginFailureComponent, LoginSuccessComponent];

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule, ReactiveFormsModule],
  declarations: [DIRECTIVES],
  exports: [DIRECTIVES]
})
export class UiLoginModule {}
