import { CommonModule, CurrencyPipe, PercentPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GraphqlModule } from '@compass/graphql';
import { MaterialModule } from '@compass/material';

import { BaselineService } from './baseline/baseline.service';
import { DbService } from './db/db.service';
import { ItemsService } from './items/items.service';
import { ScenarioService } from './scenario/scenario.service';
import { SchemaService } from './schema/schema.service';
import { TableauService } from './tableau/tableau.service';
import { TimelineService } from './timeline/timeline.service';
import { UsersService } from './users/users.service';
import { NotifyService } from './utils/notify/notify.service';
import { RouteHistoryService } from './utils/route-history/route-history.service';
import { RefreshService } from './refresh/refresh.service';
import { RolesService } from './roles/roles.service';
import { DateService } from './utils/date/date.service';
import { FormService } from './utils/form/form.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, GraphqlModule, MaterialModule],
  providers: [
    BaselineService,
    CurrencyPipe,
    PercentPipe,
    DbService,
    ItemsService,
    NotifyService,
    RouteHistoryService,
    ScenarioService,
    SchemaService,
    TableauService,
    TimelineService,
    RouteHistoryService,
    UsersService,
    RefreshService,
    RolesService,
    DateService,
    FormService
  ],
  exports: [HttpClientModule]
})
export class CoreDataModule {}
