import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { User } from '@compass/core-data';
import { UsersFacade } from '@compass/core-state';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'compass-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  authenticatedUser$: Observable<User>;
  @Input() title: string;
  @Input() sidenav: MatSidenav;
  @Input() isAuthenticated: boolean;

  constructor(private usersFacade: UsersFacade) {}

  ngOnInit() {
    this.authenticatedUser$ = this.usersFacade.authenticatedUser$;
  }

  logout() {
    this.usersFacade.logoutUser();
  }

  envBorderStyle() {
    if (environment.borderColor) {
      return {
        "background-color": environment.borderColor
      };
    }
  }
}
