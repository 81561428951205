import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import * as fromItems from './items/items.reducer';
import * as fromRoles from './roles/roles.reducer';
import * as fromUi from './ui/ui.reducer';
import * as fromUsers from './users/users.reducer';
import * as fromFavorites from './favorites/favorites.reducer';
import * as fromSchemas from './schemas/schemas.reducer';

export interface AppState {
  ui: fromUi.State;
  users: fromUsers.UsersState;
  items: fromItems.ItemsState;
  roles: fromRoles.RolesState;
  favorites: fromFavorites.FavoritesState;
  schemas: fromSchemas.SchemasState;
  router: RouterReducerState<any>;
}

export const reducers: ActionReducerMap<AppState> = {
  ui: fromUi.uiReducer,
  users: fromUsers.usersReducer,
  items: fromItems.itemsReducer,
  roles: fromRoles.rolesReducer,
  favorites: fromFavorites.favoritesReducer,
  schemas: fromSchemas.schemasReducer,
  router: routerReducer
};
