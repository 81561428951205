import { Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { IDoesFilterPassParams, RowNode } from 'ag-grid-community';
import { IDateAngularComp } from 'ag-grid-angular';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateService } from '@compass/core-data';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ag-grid-datefilter',
  templateUrl: './grid-date-editor.component.html',
  styleUrls: ['./grid-date-editor.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useClass: DateService}
  ]
})
export class GridDateFilterComponent implements IDateAngularComp {
  private control;
  private params: any;
  private valueGetter: (rowNode: RowNode) => any;
  public modelDate: Date;
  public dateFormControl = new FormControl(); // The input tied to the mat-datepicker
  public formGroup: FormGroup = new FormGroup({});

  @ViewChild('datePicker') datePicker: MatDatepicker<Moment>;
  @ViewChild('dateInput', { static: true }) dateInput: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(MAT_DATE_FORMATS) public dateService: DateService,
    private renderer: Renderer2
  ) { }

  agInit(params: any): void {
    this.renderer.addClass(this.dateInput.nativeElement, 'date-filter-input');
    this.params = params;
    this.control = params.filterParams.control;
    this.valueGetter = params.filterParams.valueGetter;

    if (!this.control) {
      console.error(this.control)
    }

    // Set the dateFormat property in the CompassDateFormats class
    if (this.control.dateFormat) {
      this.dateService.dateFormat = this.control.dateFormat;
      this.dateService.displayCalendarDays = this.control.dateFormat.toLowerCase().indexOf('d') >= 0;
    }
  }

  isFilterActive(): boolean {
    return !!this.modelDate;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (this.modelDate == null) return true;
    
    let dateAsString = this.valueGetter(params.node);
    let dateAsObject = moment(dateAsString);

    return dateAsObject.toDate() === this.modelDate;
  }

  getDate() {
    return this.getModel();
  }

  setDate(date: Date) {
    this.setModel({value: date})
    this.dateFormControl.setValue(date);
  }

  getModel(): any {
    return this.modelDate;
  }

  setModel(model: any): void {
    if (model && model.value && !isNaN(Date.parse(model.value))) {
        this.modelDate = new Date(model.value);
    } else {
        this.modelDate = null;
    }
  }

  isPopup() {
    return true;
  }

  onDateInput(value) {}

  onDateChange(value): void {
    this.setDate(value);
    this.params.onDateChanged();
  }

  onClose() {}

  monthSelected(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    this.datePicker.select(normalizedMonth);
    this.datePicker.close();
  }

  onButtonFocus(event) {}
}