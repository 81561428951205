import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ControlInput, Section, Schema, DataType } from '@compass/core-data';

@Component({
  selector: 'compass-control-input',
  templateUrl: './control-input.component.html',
  styleUrls: ['./control-input.component.scss']
})
export class ControlInputComponent implements OnInit {
  @Input() control: ControlInput;
  @Input() form: FormGroup;
  @Input() value: boolean | number | string | Date;
  @Input() section: Section;
  @Input() schema: Schema;
  @Input() relationalKey: string;

  DataType = DataType;
  
  formControl: AbstractControl;

  constructor() {}

  ngOnInit() {
    if (!this.schema || !this.section || !this.form || !this.control || !this.relationalKey) {
      console.error("Control Input is missing required properties needed in OnInit!");
    }

    this.formControl = this.form.get(this.relationalKey);
    if (!this.formControl) {
      return console.error('Expecting formControl to be defined.');
    }
  }
}
