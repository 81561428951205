// TODO: split into multiple files
export enum DataType {
    String = "string", // TODO: string property does not exist in pagedef.json, can this be removed?
    Text = "text",
    Longtext = "longtext",
    Number = "number",
    Integer = "integer",
    Date = "date",
    Percent = "percent",
    Money = "money",
    Boolean = "boolean",
    Json = "json"
  }
