<div id="login" class="mx-auto mt-5">
  <div class="login-grid">
    <mat-card>
      <form [formGroup]="form" (submit)="login()">
        <mat-card-actions>
          <button mat-raised-button type="submit" color="primary">
            Log In
          </button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>
