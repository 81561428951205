import { User, Privilege, Role } from '@compass/core-data';
import { Action } from '@ngrx/store';

export enum UsersActionTypes {
  AUTHENTICATED_USER_LOADED = '[USER] Authenticated User Loaded',
  LOAD_AUTHENTICATED_USER = '[USER] Load Authenticated User',
  LOAD_AUTHENTICATED_USER_PRIVS = '[USER] Load Authenticated User Privileges',
  AUTHENTICATED_USER_PRIVS_LOADED = '[USER] Authenticated User Privileges Loaded',
  LOAD_AUTHENTICATED_USER_ROLES = '[USER] Load Authenticated User Roles',
  AUTHENTICATED_USER_ROLES_LOADED = '[USER] Authenticated User Roles Loaded',
  LOGOUT_USER = '[USER] Logout User'
}

export class AuthenticatedUserLoaded implements Action {
  readonly type = UsersActionTypes.AUTHENTICATED_USER_LOADED;
  constructor(public payload: { user: User }) {}
}

export class LoadAuthenticatedUser implements Action {
  readonly type = UsersActionTypes.LOAD_AUTHENTICATED_USER;
}

export class LogoutUser implements Action {
  readonly type = UsersActionTypes.LOGOUT_USER;
  constructor() {}
}

export class LoadAuthenticatedUserPrivs implements Action {
  readonly type = UsersActionTypes.LOAD_AUTHENTICATED_USER_PRIVS;
  constructor(public payload: { user: User }) {}
}

export class AuthenticatedUserPrivsLoaded implements Action {
  readonly type = UsersActionTypes.AUTHENTICATED_USER_PRIVS_LOADED;
  constructor(public payload: { privileges: any[] }) {}
}

export class LoadAuthenticatedUserRoles implements Action {
  readonly type = UsersActionTypes.LOAD_AUTHENTICATED_USER_ROLES;
  constructor(public payload: { user: User }) {}
}

export class AuthenticatedUserRolesLoaded implements Action {
  readonly type = UsersActionTypes.AUTHENTICATED_USER_ROLES_LOADED;
  constructor(public payload: { roles: any[] }) {}
}

export type UsersActions =
  | AuthenticatedUserLoaded
  | LoadAuthenticatedUser
  | LogoutUser
  | LoadAuthenticatedUserPrivs
  | AuthenticatedUserPrivsLoaded
  | LoadAuthenticatedUserRoles
  | AuthenticatedUserRolesLoaded;
