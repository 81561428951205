import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ComponentType } from '@compass/core-data';
import { FilePondOptions } from 'filepond';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import mime from 'mime/lite';

import catalog from 'file-icon-vectors/dist/icons/square-o/catalog.json';

@Component({
  template: `
    <div *ngIf="(files | async) as fs">
      <ul *ngFor="let file of fs">
        <li class="upload-file">
          <a [href]="file.url">
            <div class="upload-file-inner">
              <span [class]="getIconClass(file.type)"></span>{{ file.name }}
              <div class="remove-file" [routerLink]="" (click)="removeFile($event, file.url)">
                ×
              </div>
            </div>
          </a>
        </li>
      </ul>
      <file-pond
        #fileUpload
        (onprocessfile)="onProcessFile($event)"
        [options]="pondOptions"
      ></file-pond>
    </div>
  `,
  selector: 'compass-file-upload',
  styleUrls: ['./component-file-upload.component.scss']
})
export class ComponentFileUploadComponent implements AfterViewInit {
  @Input() componentType: ComponentType;
  @Input() id: string;
  files = new Subject<Array<any>>();

  constructor(private http: HttpClient) {}

  get httpHeaders(): any {
    return {
      'Component-Id': this.id
    };
  }

  @ViewChild('fileUpload') fileUpload: any;
  get pondOptions(): FilePondOptions {
    return {
      instantUpload: true,
      server: {
        url: `${environment.baseEndpoint}/files`,
        headers: this.httpHeaders
      }
    };
  }

  getIconClass(mimeType: string): string {
    const ext = mime.getExtension(mimeType);
    return 'fiv-sqo ' + (catalog.includes(ext) ? `fiv-icon-${ext}` : 'fiv-icon-master');
  }

  removeFile(event: any, url: string): void {
    event.preventDefault();
    this.http
      .delete(url)
      .toPromise()
      .then(() => this.fetchFiles());
  }

  onProcessFile(event: any): void {
    if (!event.error) {
      this.fileUpload.removeFile(event.file.id);
    }
    this.fetchFiles();
  }

  fetchFiles(): void {
    this.http
      .get(`${environment.baseEndpoint}/files/`, { headers: this.httpHeaders })
      .toPromise()
      .then((files: Array<any>) => {
        const newFiles = files.map(f => ({
          id: f.id,
          name: f.name,
          size: f.size,
          type: f.type,
          url: `${environment.baseEndpoint}/files/${f.id}`
        }));
        this.files.next(newFiles);
      });
  }

  ngAfterViewInit(): void {
    this.fetchFiles();
  }
}
