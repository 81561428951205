import { ApolloQueryResult } from 'apollo-client';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Apollo } from 'apollo-angular';

import { View } from './tableau.model';
import {
  getTableauDataQuery,
  searchTableauDataQuery,
  getTrendingViewsQuery,
  getTrustedTicket,
  getWorkbookByIdQuery
} from './tableau.graphql';

export interface Dashboard {
  image: string;
  name: string;
  viz?: string;
  graphId: string;
  description?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TableauService {
  baseImagePath = 'assets/images/thumbnails/';
  baseTableauPath = 'https://tableau.bsci.com/t/ClinicalReporting/views/';
  dashboards: Dashboard[] = [
    {
      image: `${this.baseImagePath}2019-Enrollment-Goals.png`,
      viz: `${this.baseTableauPath}2019GoalsandObjectives/EnrollmentGoals`,
      name: '2019 Enrollment Goals',
      graphId: '69cec761-508a-4849-a986-3365b293d9e9'
    },
    {
      image: `${this.baseImagePath}Health-Metrics.png`,
      viz: `${this.baseTableauPath}HealthMetrics/HealthMetrics`,
      name: 'Health Metrics',
      graphId: '43c0d177-c649-49d7-a780-a6233b88b7c9'
    },
    {
      image: `${this.baseImagePath}PMO-Productivity-Metrics.png`,
      viz: `${this.baseTableauPath}ProductivityMetrics_0/PMOProductivity`,
      name: 'PMO Productivity Metrics',
      graphId: '774132ef-e5d8-4571-b451-5866b5f37753'
    },
    {
      image: `${this.baseImagePath}2019-ATE-Objectives.jpg`,
      viz: `${this.baseTableauPath}2019GoalsandObjectives/ATEGoals`,
      name: '2019 ATE Objectives',
      graphId: 'ea8f1053-78a8-480d-a890-ae627762a565'
    },
    {
      image: `${this.baseImagePath}2019-Enrollment-Objectives.jpg`,
      viz: `${this.baseTableauPath}2019GoalsandObjectives/EnrollmentGoals`,
      name: '2019 Enrollment Objectives',
      graphId: '52cbad7e-d7e7-43d8-8461-d03923780427'
    },
    {
      image: `${this.baseImagePath}2019-EU-Country-Goals.jpg`,
      viz: `${this.baseTableauPath}2018RCMGoals/EnrollmentsbyCountry`,
      name: '2019 EU Country Goals',
      graphId: 'a8f6ec5d-f4c2-41f6-8487-af4bc200e1de'
    },
    {
      image: `${this.baseImagePath}2019-RCM-Goals.jpg`,
      viz: `${this.baseTableauPath}2019RCMGoalsandObjectives/1H2019Enrollments`,
      name: '2019 RCM Goals',
      graphId: '21a193a5-5302-4ba6-84b0-928ea2d26c7c'
    },
    {
      image: `${this.baseImagePath}Monitoring-Visits.jpg`,
      viz: `${this.baseTableauPath}MonitoringInformation/MonitoringVisits`,
      name: 'Monitoring Visits',
      graphId: '487bb94e-9509-4664-a55d-31910a628464'
    },
    {
      image: `${this.baseImagePath}MV-Compliance.jpg`,
      viz: `${this.baseTableauPath}MonitoringVisitandReportCompliance_0/MonitoringVisitCompliance`,
      name: 'MV Compliance',
      graphId: '924f07b0-b5c4-4352-9bd5-0eed4fcfa347'
    },
    {
      image: `${this.baseImagePath}Plans-Versus-Actuals.jpg`,
      viz: `${this.baseTableauPath}StudyPlansvsActuals_0/Monthly`,
      name: 'Plans Versus Actuals',
      graphId: '25fb778e-57b0-4fab-8201-c7979d72cb7b'
    },
    {
      image: `${this.baseImagePath}Productivity-Metrics.jpg`,
      viz: `${this.baseTableauPath}ProductivityMetrics_0/CTMProductivity`,
      name: 'Productivity Metrics',
      graphId: '773cb45f-6b79-4817-b140-324d3504ef33'
    },
    {
      image: `${this.baseImagePath}Recruitment-Opportunity.jpg`,
      viz: `${this.baseTableauPath}RecruitmentOpportunity/RecruitmentOpportunity`,
      name: 'Recruitment Opportunity',
      graphId: '9b88b51b-df73-4a03-8f4a-6e403e27e270'
    },
    {
      image: `${this.baseImagePath}Site-Management-Map.jpg`,
      viz: `${this.baseTableauPath}MonitoringInformation/USSiteManagementMap`,
      name: 'Site Management Map',
      graphId: 'e8002790-ad90-49f7-af95-e16d1132e4c3'
    },
    {
      image: `${this.baseImagePath}Site-Selection.jpg`,
      viz: `${this.baseTableauPath}SiteSelection/SiteNominationsPREEMPT`,
      name: 'Site Selection',
      graphId: '264344fc-e2b4-463f-aef9-469e79f7ea0b'
    },
    {
      image: `${this.baseImagePath}Study-Compliance.jpg`,
      viz: `${this.baseTableauPath}StudyCompliance_0/StudyComplianceMatrix`,
      name: 'Study Compliance',
      graphId: '80822450-e36e-4aeb-a0d2-3f5afa434047'
    },
    {
      image: `${this.baseImagePath}Training-Compliance.jpg`,
      viz: `${this.baseTableauPath}TrainingCompliance/Study-SpecificTrainingTracker`,
      name: 'Training Compliance',
      graphId: '79fae5ea-4cc1-464f-857c-bafcf8f46dd6'
    },
    {
      image: `${this.baseImagePath}Trial-Management.jpg`,
      viz: `${this.baseTableauPath}TrialManagement_0/CTMSuiteLandingPage`,
      name: 'Trial Management',
      graphId: '5613fda6-9458-46db-a1fe-052a7a37f5ad'
    }
  ];

  constructor(private apollo: Apollo) {}

  favoriteDashboards(): Observable<Dashboard[]> {
    return of(this.dashboards.slice(Math.max(this.dashboards.length - 10, 1)));
  }

  getSiteViews(): Observable<View[]> {
    return this.apollo
      .query({
        query: getTableauDataQuery,
        fetchPolicy: 'network-only'
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.getWorkbookPreviews));
  }

  getTrustedTicket(): Observable<string> {
    return this.apollo
      .query({
        query: getTrustedTicket,
        fetchPolicy: 'network-only'
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.getTrustedTicket));
  }

  getWorkbookById(workbookId: string): Observable<any> {
    return this.apollo
      .query({
        query: getWorkbookByIdQuery,
        variables: { id: workbookId },
        fetchPolicy: 'network-only'
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.getWorkbookById));
  }

  searchTableauData(searchString: String) {
    return this.apollo
      .query({
        query: searchTableauDataQuery,
        variables: { searchString },
        fetchPolicy: 'network-only'
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.searchTableaus));
  }

  getTrendingViews(): Observable<View[]> {
    return this.apollo
      .query({
        query: getTrendingViewsQuery,
        fetchPolicy: 'network-only'
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.getTrendingViews));
  }
}
