import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationStart } from '@angular/router';
import { Observable, of, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil, switchMap, tap } from 'rxjs/operators';

import { SchemaService } from '@compass/core-data';
import { UsersFacade } from '@compass/core-state';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class PrivGuard implements CanActivate, OnDestroy {
  private unsubscribe = new Subject<void>();
  
  constructor(
    private readonly userFacade: UsersFacade,
    private readonly schemaService: SchemaService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const appLevelAccess: Observable<boolean> = this.userHasAppAccess(route);
    const pageLevelAccess: Observable<boolean> = this.userHasPageAccess(route);
  
    const navigateStart$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      takeUntil(this.unsubscribe)
    );
  
    return combineLatest([appLevelAccess, pageLevelAccess]).pipe(
      takeUntil(this.unsubscribe),
      switchMap((accessLevels: Array<boolean>) => {
        // User does NOT have Access to App and/or Page
        if (accessLevels.includes(false)) {
          // Display message to user
          const snackbarRef = this.snackbar.open('You do not have access. Please contact your System Administrator.', '', {duration: 2500});
  
          // Close the snackbar if navigation occurs
          navigateStart$.pipe(
            tap(() => snackbarRef.dismiss())
          ).subscribe();

          return of(false);
        }
        // User has Access to App and Page (if exists)
        return of(true);
      })
    );
  }

  userHasAppAccess(route) {
    return this.userFacade.authenticatedUserPrivs$.pipe(
      filter(privs => !!privs),
      switchMap(apps => {
        const canAccessApp = apps.some(app =>
          (app.app_foldername && app.app_foldername.toLowerCase() === route.params.folder) ||
          (app.app_foldername && app.app_foldername.toLowerCase() === route.data.folder) ||
          (app.app_filename && app.app_filename.toLowerCase() === route.params.schema)
        )
        return of(canAccessApp);
      })
    )
  }

  userHasPageAccess(route) {
    return this.schemaService.getSchema(route.params.schema).pipe(
      switchMap(schema => {
        // If schema doesn't have roles, return true (user has page level access)
        if (!schema || !schema.roles) return of(true);

        return this.userFacade.authenticatedUserRoles$.pipe(
          filter(roles => !!roles),
          switchMap(roles => {
            const userHasPageRole = roles.some(role => schema.roles.includes(role.rol_name))
            return of(userHasPageRole);
          })
        )
      })
    )
  }
}
