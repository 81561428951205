import { Action } from '@ngrx/store';
import { Role } from '@compass/core-data';

export enum RolesActionTypes {
  ROLES_ACTION  = '[ROLE] Action',
  ROLE_SELECTED = '[ROLE] Role Selected',
  LOAD_ROLES    = '[ROLE] Load Roles',
  ROLES_LOADED  = '[ROLE] Roles Loaded',
  ADD_ROLE      = '[ROLE] Add Role',
  ROLE_ADDED    = '[ROLE] Role Added',
  UPDATE_ROLE   = '[ROLE] Update Role',
  ROLE_UPDATED  = '[ROLE] Role Updated',
  DELETE_ROLE   = '[ROLE] Delete Role',
  ROLE_DELETED  = '[ROLE] Role Deleted'
}

export class Roles implements Action {
  readonly type = RolesActionTypes.ROLES_ACTION;
}

export class RoleSelected implements Action {
  readonly type = RolesActionTypes.ROLE_SELECTED;
  constructor(public payload) {}
}

export class LoadRoles implements Action {
  readonly type = RolesActionTypes.LOAD_ROLES;
  constructor() {}
}

export class RolesLoaded implements Action {
  readonly type = RolesActionTypes.ROLES_LOADED;
  constructor(public payload: Role[]) {}
}

export class AddRole implements Action {
  readonly type = RolesActionTypes.ADD_ROLE;
  constructor(public payload: Role) {}
}

export class RoleAdded implements Action {
  readonly type = RolesActionTypes.ROLE_ADDED;
  constructor(public payload: Role) {}
}

export class UpdateRole implements Action {
  readonly type = RolesActionTypes.UPDATE_ROLE;
  constructor(public payload: Role) {}
}

export class RoleUpdated implements Action {
  readonly type = RolesActionTypes.ROLE_UPDATED;
  constructor(public payload: Role) {}
}

export class DeleteRole implements Action {
  readonly type = RolesActionTypes.DELETE_ROLE;
  constructor(public payload: Role) {}
}

export class RoleDeleted implements Action {
  readonly type = RolesActionTypes.ROLE_DELETED;
  constructor(public payload: Role) {}
}

export type RolesActions = Roles
  | RoleSelected
  | LoadRoles
  | RolesLoaded
  | AddRole
  | RoleAdded
  | UpdateRole
  | RoleUpdated
  | DeleteRole
  | RoleDeleted
  ;
