import gql from 'graphql-tag';

export const scenarioFragment = gql`
  fragment scenarioFragment on ScenarioDTO {
    sce_id
    sce_name
    sce_desc
    scen_sce_id
    scen_sce_sort
    sce_active
    sce_sys_active
    sce_sys_ts
    sce_sys_usr
    sce_sys_desc
  }
`;

export const allScenariosQuery = gql`
  query allScenariosQuery {
    allScenarios {
      ...scenarioFragment
    }
  }
  ${scenarioFragment}
`;

export const scenarioByIdQuery = gql`
  query scenarioByIdQuery($id: String!) {
    scenarioById(id: $id) {
      ...scenarioFragment
    }
  }
  ${scenarioFragment}
`;

export const createDimensionsScenarioMutation = gql`
  mutation createDimensionsScenarioMutation($input: ScenarioInput!) {
    createDimensionsScenario(input: $input) {
      ...scenarioFragment
    }
  }
  ${scenarioFragment}
`;

export const updateDimensionsScenarioMutation = gql`
  mutation updateDimensionsScenarioMutation($patch: ScenarioInput!) {
    updateDimensionsScenario(patch: $patch) {
      ...scenarioFragment
    }
  }
  ${scenarioFragment}
`;

export const deleteDimensionsScenarioMutation = gql`
  mutation deleteDimensionsScenarioMutation($id: String!) {
    deleteDimensionsScenario(id: $id)
  }
`;
