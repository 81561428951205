import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import { parse } from 'papaparse';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { DbFunction, DbTable, Section, Schema, SortColumn } from '../schema';
import { fnQuery, tblQuery } from './db.graphql';
import { Fn } from './fn.model';
import { Tbl } from './tbl.model';
import { Option } from './option.model';
import { FormService } from '../utils/form/form.service';
import { environment } from '@env/environment';
import { User } from '../users/users.model';
import { select, Store } from '@ngrx/store';
import { UsersState } from 'libs/core-state/src/lib/users/users.reducer';
import { selectUser } from 'libs/core-state/src/lib/users/users.selectors';

// The environments that should console log all fn and tbl queries
const envNamesToLog = ['dev', 'test', 'staging', 'validation'];

@Injectable({
  providedIn: 'root'
})
export class DbService {
  fnLogsGrouped = false;
  tblLogsGrouped = false;
  loggedInUser: User;

  constructor(
    private apollo: Apollo,
    private readonly formService: FormService,
    private readonly store: Store<UsersState>
  ) {
    window['__APOLLO_CLIENT__'] = apollo.getClient();
    this.store.pipe(select(selectUser)).pipe(take(1)).subscribe(user => this.loggedInUser = user);
  }

  fn(dbFunction: DbFunction, parameters?: Array<[string, string]>): Observable<Fn> {
    if (envNamesToLog.some(envName => environment.baseEndpoint.includes(envName))) {
      if (!this.fnLogsGrouped) {
        console.group('Fn String');
        this.fnLogsGrouped = true;
        setTimeout(() => {
          console.groupEnd();
          this.fnLogsGrouped = false;
        }, 1000)
      }
      console.log(this.createFnString(dbFunction, parameters))
    }
    return this.apollo
      .query({
        query: fnQuery,
        variables: {
          connection: dbFunction.connection,
          name: dbFunction.functionCall.fxnName,
          parameters
        },
        fetchPolicy: 'network-only'
      })
      .pipe(map((response: ApolloQueryResult<{ fn: Fn }>) => response.data.fn));
  }

  tbl(dbTable: DbTable, columnNames: Array<string>, parameters?: Array<[string, string]>, sortColumns?: SortColumn[]): Observable<Tbl> {
    if (envNamesToLog.some(envName => environment.baseEndpoint.includes(envName))) {
      if (!this.tblLogsGrouped){
        console.group('Tbl String');
        this.tblLogsGrouped = true;
        setTimeout(() => {
          console.groupEnd();
          this.tblLogsGrouped = false;
        }, 1000)
      }
      console.log(this.createTblString(dbTable, columnNames, parameters, sortColumns));
    }
    return this.apollo
      .query({
        query: tblQuery,
        variables: {
          connection: dbTable.connection,
          tableName: dbTable.tableName,
          columnNames: columnNames,
          parameters,
          sortColumns: sortColumns
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((response: ApolloQueryResult<{ tbl: Tbl }>) => {
          return response.data.tbl;
        })
      );
  }

  getOptionsWithDbFunction(
    dbFunction: DbFunction,
    formGroup: FormGroup,
    parameters: Array<[string, string]>
  ): Observable<Array<Option>> {

    return this.fn(dbFunction, parameters).pipe(
      map(result => {
        const rows = result['pie_query'];
        const keys = Object.keys(rows);
        return keys.map(key => {
          const parsed = parse(rows[key], { delimiter: ',' });

          if (parsed.errors.length > 0) {
            console.log('error', dbFunction, formGroup, parameters)
            console.error(parsed.errors);
            return null;
          }

          // the value is in the first position
          // the label is in the second position
          return {
            value: parsed.data[0][0],
            label: parsed.data[0][1]
          };
        });
      })
    );
  }

  getParametersWithDbFunction(
    dbFunction: DbFunction,
    formGroup: FormGroup,
    schema: Schema,
    section?: Section
  ): Array<[string, string]> {
    let parameters: Array<[string, string]> = [];
    if (!schema) {
      console.error("Missing Schema.")
    }

    if (
      dbFunction.functionCall &&
      dbFunction.functionCall.keys &&
      dbFunction.functionCall.keys.length > 0
    ) {
      parameters = this.getValuesForKeys(dbFunction.functionCall.keys, formGroup, schema, section);
    }
    return parameters;
  }

  getParametersWithTableName(
    dbTable: DbTable,
    formGroup: FormGroup,
    schema: Schema,
    section: Section
  ): Array<[string, string]> {
    let parameters: Array<[string, string]> = [];

    if (
      dbTable.tableName &&
      dbTable.keys &&
      dbTable.keys.length > 0
    ) {
      parameters = this.getValuesForKeys(dbTable.keys, formGroup, schema, section);
    }

    return parameters;
  }

  getValuesForKeys(keys, formGroup: FormGroup, schema?: Schema, section?: Section) {
    let parameters: Array<[string, string]>;
    const parentId = section ? section.id : schema.id;

    // get all parameters
    parameters = keys
      .filter(key => this.formService.getFormControl(key, formGroup, schema, section))
      .map(key => {
        // Section
        if (section && formGroup.controls.hasOwnProperty(`${section.id}_${key}`)) {
          const value = (typeof formGroup.get(`${section.id}_${key}`).value !== 'undefined' && formGroup.get(`${section.id}_${key}`).value !== null) ? formGroup.get(`${section.id}_${key}`).value.toString() : ''; // Default to empty string
          return [key, value] as [string, string]
        }
        // Schema
        if (schema && formGroup.controls.hasOwnProperty(`${schema.id}_${key}`)) {
          const value = (typeof formGroup.get(`${schema.id}_${key}`).value !== 'undefined' && formGroup.get(`${schema.id}_${key}`).value !== null) ? formGroup.get(`${schema.id}_${key}`).value.toString() : ''; // Default to empty string
          return [key, value] as [string, string]
        }
        // Check for non-relational key. This shouldn't exist, but just in case.
        if (formGroup.controls.hasOwnProperty(key)) {
          console.warn(`Relational key does not exist on form for: ${key}`)
          const value = (typeof formGroup.get(key).value !== 'undefined' && formGroup.get(key).value !== null) ? formGroup.get(key).value.toString() : ''; // Default to empty string
          return [key, value] as [string, string]
        } else {
          console.warn(`Key does not exist on form: ${key}`)
          return null;
        }
      });

    return parameters;
  }

  createFnString (dbFunction: DbFunction, parameters?: Array<Array<string>>): string {
    const xSysParms = this.loggedInUser ? `['x_sys_user', '${this.loggedInUser.usr_name}'],['x_sys_usr_id', '${this.loggedInUser.usr_id}']` : '';
    const pMsg = `['p_msg','on']`;
    const n = parameters?.length ?? 0;
    if (n === 0) {
      return `SELECT pie_query(ARRAY[['x_qry', '${dbFunction.functionCall.fxnName}'],${pMsg},${xSysParms}]::hstore);`
    } else {
      let nameString = `SELECT pie_query(ARRAY[['x_qry', '${dbFunction.functionCall.fxnName}'],${pMsg},`
      let parametersString = '';
      for (let i = 0; i < n - 1; i++ ) {
        parametersString += `['${parameters[i][0]}', '${parameters[i][1]}'],`
      }
      parametersString += `['${parameters[n-1][0]}', '${parameters[n-1][1]}']`;
      parametersString += `,${xSysParms}`;

      return `${nameString}${parametersString}]::hstore);`
    }
  }

  createTblString (dbTable: DbTable, columnNames: Array<string>, parameters: Array<Array<string>>, sortColumns: Array<SortColumn>){
    let sql = '';
    const n = parameters?.length ?? 0;
    const colList = columnNames ? columnNames.join(',') : '*'
    if (n === 0) {
      sql = `SELECT ${colList} FROM ${dbTable.tableName}`;
    } else {
      let whereClause = '';
      for (const param of parameters) {
        const nameArray = param[0].split('_');
        const colName = nameArray.slice(nameArray.length - 2).join('_')
        whereClause += ` AND ${colName} IN (${param[1]})`
      }
      sql = `SELECT ${colList} FROM ${dbTable.tableName} WHERE true ${whereClause}`
    }
    if (sortColumns?.length > 0) {
      let orderBy = ' ORDER BY ';
      sortColumns.forEach((col, index) => {
        orderBy += `${col.name} ${col.sort}`
        if (index != sortColumns.length -1) {
          orderBy += ', ';
        }
      });
      sql += `${orderBy}`;
    }
    return `${sql};`;
  }
}
