import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Role } from '@compass/core-data';
import { RolesActions, RolesActionTypes } from './roles.actions';

export interface RolesState extends EntityState<Role> {
  selectedRoleId: string | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>();
export const initialState: RolesState = adapter.getInitialState({
  selectedRoleId: null,
  isLoading: false,
});

export function rolesReducer(state = initialState, action: RolesActions): RolesState {
  switch (action.type) {
    case RolesActionTypes.ROLE_SELECTED: {
      return Object.assign({}, state, { selectedRoleId: action.payload });
    }

    case RolesActionTypes.LOAD_ROLES: {
      return {
        ...state,
        isLoading: true
      }
    }

    case RolesActionTypes.ROLES_LOADED: {
      return adapter.upsertMany(action.payload, {
        ...state,
        isLoading: false
      })
    }

    case RolesActionTypes.ADD_ROLE: {
      return {
        ...state,
        isLoading: true
      }
    }

    case RolesActionTypes.ROLE_ADDED: {
      return adapter.addOne(action.payload, {
        ...state,
        isLoading: false
      })
    }

    case RolesActionTypes.UPDATE_ROLE: {
      return {
        ...state,
        isLoading: true
      }
    }

    case RolesActionTypes.ROLE_UPDATED: {
      return adapter.upsertOne(action.payload, {
        ...state,
        isLoading: false
      })
    }

    case RolesActionTypes.DELETE_ROLE: {
      return {
        ...state,
        isLoading: true
      }
    }

    case RolesActionTypes.ROLE_DELETED: {
      return adapter.removeOne(action.payload.rol_id, {
        ...state,
        isLoading: false
      })
    }

    default:
      return state;
  }
}

export const getSelectedRoleId = (state: RolesState) => state.selectedRoleId;

// get selectors...

export const {
  selectIds: selectRoleIds,
  selectEntities: selectRoleEntities,
  selectAll: selectAllRoles,
  selectTotal: selectRoleTotal
} = adapter.getSelectors();
