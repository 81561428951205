'use strict';

import { Column, RowNode, CellEvent, ShouldRowBeSkippedParams } from 'ag-grid-community';
import { Observable, throwError } from 'rxjs';
import { distinctUntilChanged, takeUntil, catchError, mergeMap, tap } from 'rxjs/operators';
import { get, find } from 'lodash';
import { Component, ViewEncapsulation, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataCollectionType, DbTable, DbFunction, ControlTable, NotifyService, RefreshService, FormService } from '@compass/core-data';
import { TableComponent } from '../table/table.component';
import { DbFacade, GridService } from '@compass/core-state';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from '@compass/core-window';

@Component({
  selector: 'compass-control-report-table',
  templateUrl: '../table/table.component.html',
  styleUrls: ['../table/table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ControlReportTableComponent extends TableComponent implements OnInit {

  cellValueBefore;

  pivotRowDataForUpdates = [];

  constructor(
    public cd: ChangeDetectorRef,
    public dbFacade: DbFacade,
    public gridService: GridService,
    public readonly notifyService: NotifyService,
    public readonly refreshService: RefreshService,
    public readonly matDialog: MatDialog,
    public readonly formService: FormService,
    public readonly router: Router,
    public readonly localStorageService: LocalStorageService
  ) {
    super(cd, dbFacade, gridService, notifyService, refreshService, matDialog, formService, router, localStorageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    (this.gridOptions.onCellEditingStarted = function(event) {
      this.cellValueBefore = event.value;
      // Focus and select contents. This should be moved to the grid-editaggvalues.component
      const inputCellField = document.getElementById('aggValueCell') as HTMLInputElement;
      if (inputCellField) {
        inputCellField.focus();
        inputCellField.select();
      }
    }),
      (this.gridOptions.onCellEditingStopped = (event: CellEvent) => {
        if (this.cellValueBefore === event.value || !event.colDef.aggFunc) {
          return;
        }

        this.updateAggregatedCell(event);
      });

    this.gridOptions.processCellFromClipboard = (params: CellEvent) => {
      if (!params.value) {
        return;
      }

      this.updateAggregatedCell(params);
    };
  }

  updateAggregatedCell(params: CellEvent): void {
    const colId = params.column.getColId();
    const colDef = params.column.getColDef();
    const valName = colDef.pivotValueColumn.getColId();

    let childNode;
    colDef.pivotKeys.forEach(key => {
      childNode = childNode ? childNode[key] : params.node.childrenMapped[key];
    });

    const rowNode: RowNode = childNode[0];
    rowNode.data[valName] = params.value;
    params.node.aggData[colId] = params.value;

    this.pivotRowDataForUpdates.push(childNode[0].data);
  }

  onExport(): void {
    // https://www.ag-grid.com/javascript-data-grid/excel-export-api/#excelexportparams
    this.gridApi.exportDataAsExcel({
      // Don't export the first column being grouped by as it's own row. The export should be the same as displayed in the grid.
      shouldRowBeSkipped(params: ShouldRowBeSkippedParams) {
        // Don't export an additional row for each new group
        return params.node.hasOwnProperty('leafGroup') ? !params.node.leafGroup : false;
      },
      processCellCallback({ value }) {
        if (!value || !value.match) return value;
        const match = value.match(/^ -> (.*?)(( ->)|$)/);
        return match && match[1] ? match[1] : value;
      },
      processHeaderCallback({ column }) {
        const colDef = column.getColDef();
        const value = get(colDef, 'headerName');
        if (value && value.match) {
          const match = value.match(/^sum\((.*)\)$/);
          return match && match[1] ? match[1] : value;
        }
        return value;
      },
      columnGroups: true,
      fileName: this.control.title || 'export'
    });
  }

  setRowData(): void {
    if (
      !get(this.control, 'dbValues.dbCall') ||
      // Check that parameters (relatedFormControls) all have values
      (this.relatedFormControls &&
        this.relatedFormControls.find(control => control.status === 'INVALID'))
    ) {
      return;
    }

    let dataObservable: Observable<any>;
    this.pivotRowDataForUpdates = [];
    const dbTable: DbTable = Object.create(this.control.dbValues.dbCall as DbTable);

    // Handle getDataSet (2 transactions to get data for grid)
    if (dbTable.getDataSet) {
      const dbFunction = dbTable.getDataSet as DbFunction;
      const parameters = this.dbFacade.getParametersWithDbFunction(
        dbFunction,
        this.form,
        this.schema,
        this.section
      );

      // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
      if (dbTable.staticKeys) {
        dbTable.staticKeys.forEach((staticKey: any) => {
          parameters.push([staticKey.key, staticKey.value]);
        });
      }

      // Execute the function to get the tableName and tableColumns
      dataObservable = this.dbFacade.fn(dbTable.getDataSet, parameters).pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe),
        catchError(e => {
          console.error(e);
          this.notifyService.notifyError(e);
          return throwError(e);
        }),
        mergeMap(res => {
          const tableColumnsArr = JSON.parse(res.pie_query.tableColumns);
          dbTable.tableName = res.pie_query.tableName;
          dbTable.columnNames = [];

          const control = Object.create(this.control) as ControlTable;
          control.tableColumns = tableColumnsArr;
          this.control = control;
          this.control.tableColumns = tableColumnsArr;



          // Define dbTable.columnNames (array of string)
          tableColumnsArr[0].fields.map(field => {
            dbTable.columnNames.push(field.key);
          });

          // Get data from the tbl query
          const observables = this.setColDefs().pipe(
            mergeMap(() => {
              return this.dbFacade.tbl(dbTable, dbTable.columnNames).pipe(
                  distinctUntilChanged(),
                  takeUntil(this.unsubscribe),
                  catchError(e => {
                    console.error(e);
                    this.notifyService.notifyError(e);
                    return throwError(e);
                  })
                );
              })
          )
          return observables;
        })
      );
    } else {
      const tableColumn = this.control.tableColumns[0];
      let columnNames = [];
      const parameters = this.dbFacade.getParametersWithTableName(
        dbTable,
        this.form,
        this.schema,
        this.section
      );

      // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
      if (dbTable.staticKeys) {
        dbTable.staticKeys.forEach((staticKey: any) => {
          parameters.push([staticKey.key, staticKey.value]);
        });
      }

      // return an array of key/value pairs based on the tableColumns
      switch (tableColumn.objectID) {
        case DataCollectionType.Dimension:
          throw new Error('Manual table does not support dimension table columns.');
        case DataCollectionType.Manual:
          columnNames = tableColumn.fields.map(col => col.key);
      }

      // Get data from tbl query
      dataObservable = this.dbFacade.tbl(dbTable, columnNames, parameters, dbTable.sortColumns).pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe),
        catchError(e => {
          console.error(e);
          this.notifyService.notifyError(e?.message || e);
          return throwError(e);
        })
      );
    }

    // Populate the grid with the data
    if (dataObservable) {
      dataObservable.subscribe(
        rowData => {
          const data: any[] = new Array(rowData);
          this.rowData.next(data[0]);

          if (this.control.pivotOn) {
            this.sortColumnGroups
          }

        },
        err => {
          this.showLoadingOverlay = false;
          console.error(err);
          this.gridApi.hideOverlay();
        }
      );
    }
  }

  private sortColumnGroups(): void {
    const tableColumns = this.control.tableColumns[0];
    const fields = 'fields' in tableColumns ? tableColumns['fields'] : null;

    if (!fields) {
      return;
    }

    const schemaCol = find(fields, field => 'sort' in field);

    // note: if it's not a grouped col, and not a pivoted column in schema, then sorting should apply properly through grid.service
    if (schemaCol) {
      let sortedCol: Column;

      if ('rowGroup' in schemaCol && schemaCol['rowGroup']) {
        // find the sorted column group column from ag-grid
        sortedCol = find(this.columnApi.getAllDisplayedVirtualColumns(), gridCol => {
          return gridCol.getColDef().showRowGroup === schemaCol.key;
        });
      } else if ('pivot' in schemaCol && schemaCol['pivot']) {
        // todo - find pivot colId to define sortModel. See https://www.ag-grid.com/javascript-grid-pivoting/#sorting-with-pivot
      }

      if (sortedCol && 'sort' in schemaCol) {
        // define the ag-grid sortModel
        const sortModel = [
          {
            colId: sortedCol.getColId(),
            sort: schemaCol.sort
          }
        ];
        // sort
        this.gridApi.setSortModel(sortModel);
      }
    }
  }
}
