<ng-container class='chart' [ngSwitch]="componentType.gridType">
  <compass-ag-chart
    *ngSwitchCase="ChartType.Ag_Chart"
    [id]="id"
    [componentType]="componentType"
    [form]="form"
    [section]="section"
    [schema]="schema">
  </compass-ag-chart>

  <compass-js-chart
    *ngSwitchDefault
    [id]="id"
    [componentType]="componentType"
    [form]="form"
    [section]="section"
    [schema]="schema">
  </compass-js-chart>
</ng-container>