import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';

import { Scenario } from './scenario.model';
import {
  allScenariosQuery,
  createDimensionsScenarioMutation,
  updateDimensionsScenarioMutation,
  deleteDimensionsScenarioMutation
} from './scenario.graphql';

@Injectable({
  providedIn: 'root'
})
export class ScenarioService {
  constructor(private apollo: Apollo) { }

  all(): Observable<Scenario[]> {
    return this.apollo
      .query({
        query: allScenariosQuery,
        fetchPolicy: 'network-only',
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.allScenarios)
      );
  }

  create(input: Scenario): Observable<Scenario> {
    const randomId = Math.floor(Math.random() * 100) + 1;
    const addIdToInput = {sce_id: randomId, ...input};
    return this.apollo
      .mutate({
        mutation: createDimensionsScenarioMutation,
        variables: {
          input: addIdToInput
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.createDimensionsScenario)
      );
  }

  update(patch: Scenario): Observable<Scenario> {
    delete (<any>patch).__typename;
    return this.apollo
      .mutate({
        mutation: updateDimensionsScenarioMutation,
        variables: {
          patch
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.updateDimensionsScenario)
      );
  }

  delete(id: string): Observable<Scenario> {
    return this.apollo
      .mutate({
        mutation: deleteDimensionsScenarioMutation,
        variables: {
          id
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.deleteDimensionsScenario)
      );
  }
}
