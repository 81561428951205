import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  Action,
  AffectedComponent,
  ComponentCarousel,
  ComponentDataEntry,
  ComponentHTML,
  DbCallType, FormService, NotifyService,
  RefreshService, Schema, Section
} from '@compass/core-data';
import { DbFacade } from '@compass/core-state';
import { isEmpty, omit } from 'lodash';
import { forkJoin, merge, Observable, Subject, throwError } from 'rxjs';
import { catchError, delay, takeUntil, tap } from 'rxjs/operators';
import { DialogConfirmationComponent } from '../../core/components/dialog-confirmation/dialog-confirmation.component';

import { DialogLoadingComponent } from '../../core/components/dialog-loading/dialog-loading.component';

@Component({
  selector: 'compass-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnDestroy, OnInit {
  /** The action for the schema, section or component.  */
  @Input() action: Action;

  /** The component which contains a data input control. */
  @Input() componentType: ComponentCarousel | ComponentDataEntry | ComponentHTML;

  /** Tabular data associated with this action. */
  @Input() data: Array<{ [key: string]: string }>;

  /** Emit event when the table data has changed. */
  @Output() dataChanged = new EventEmitter<Array<{ [key: string]: string | number }>>();

  /** The loading dialog reference. */
  dialogLoadingRef: MatDialogRef<DialogLoadingComponent>;

  /** Form Controls that have key referenced by action button */
  relatedFormControls: Array<AbstractControl> = [];

  /** The FormGroup instance for the schema. */
  @Input() form: FormGroup;

  @Input() schema: Schema;

  @Input() section: Section;

  @Input() disabled: boolean;

  /** Subject to trigger unsubcribe to observable streams. */
  private unsubscribe = new Subject<void>();

  constructor(
    private readonly dbFacade: DbFacade,
    private readonly matDialog: MatDialog,
    private readonly notifyService: NotifyService,
    private readonly router: Router,
    private readonly refreshService: RefreshService,
    private readonly formService: FormService
  ) { }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.refreshService.unsubscribe.next();
  }

  ngOnInit() {
    if (this.action && this.action.db.dbCall.objectID == DbCallType.Function &&
      this.action.db.dbCall.functionCall &&
      this.action.db.dbCall.functionCall.keys) {
      // Get all related (by keys) form controls
      this.action.db.dbCall.functionCall.keys.forEach(key => {
        // Get page schema's form's controls
        const control = this.formService.getFormControl(key, this.form, this.schema, this.section);
        if (control && control.statusChanges) {
          this.relatedFormControls.push(control);
        }
      });
    }
  }

  onClick(): void {
    if (this.action.db.dbCall.objectID != DbCallType.Function) {
      throw new Error('Actions only support dbCall type Function.');
    }

    // If messageWarning is defined, make user confirm before proceeding
    if (this.action.messageWarning) {
      const dialogRef = this.matDialog.open(DialogConfirmationComponent, {
        width: '250px',
        data: { message: this.action.messageWarning }
      });

      dialogRef.afterClosed().subscribe(proceed => {
        if (!proceed) return;

        this.invokeDbCallFunction();
      })
    } else {
      this.invokeDbCallFunction();
    }
  }

  disableBtn(): boolean {
    // verify dbCall is a function
    if (!this.action || this.action.db.dbCall.objectID !== DbCallType.Function || !this.action.db.dbCall.functionCall) {
      return false;
    }

    if (this.disabled) return true;

    // Check if any controls have INVALID status
    // TODO - consider instead, looking for any required form controls that don't have a value
    const invalidFormControls = this.relatedFormControls.filter(control => control.status === 'INVALID');
    return invalidFormControls.length > 0;
  }

  private invokeDbCallFunction() {
    // verify dbCall is a function
    if (this.action.db.dbCall.objectID !== DbCallType.Function) {
      const msg = `The ${this.schema.filename} schema is configured incorrectly! Action's only support db call type 'Function'.`;
      alert(msg)
      console.error(msg)
      return;
    }

    // Get parameters from page schema form
    const dbFunction = this.action.db.dbCall;
    let parameters = this.dbFacade.getParametersWithDbFunction(dbFunction, this.form, this.schema, this.section);

    // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
    if (dbFunction.functionCall.staticKeys) {
      dbFunction.functionCall.staticKeys.forEach((staticKey: any) => {
        parameters.push([staticKey.key, staticKey.value]);
      });
    }

    let action$: Observable<any>;

    // Perform single action with parameters
    if (isEmpty(this.data)) {
      action$ = this.dbFacade.fn(dbFunction, parameters)
    } else {
      // Perform action on each item in this.data
      action$ = merge(
        forkJoin(
          this.data.map(cellParams => {
            // Remove 'rowIndex' metadata property from the params (used by tables when setting dataToSave)
            cellParams = omit(cellParams, 'rowIndex');

            const paramsForSave = [...parameters, ...Object.entries(cellParams)];
            return this.dbFacade.fn(dbFunction, paramsForSave);
          })
        )
      );
    }

    this.performAction(action$);
  }

  private performAction(action$: Observable<any>) {
    this.showLoadingDialog();

    action$
      .pipe(
        delay(500),
        takeUntil(this.unsubscribe)
      )
      .subscribe({
        next: () => {
          this.showMessageSuccess(this.action.messageSuccess);

          // Clear the data that was saved
          this.data = [];

          const affectedComponents = this.action.affectedComponents;
          // Kick off refreshService subject observable that tables will subscribe to
          if (affectedComponents && affectedComponents.length) {
            affectedComponents.forEach((affectedComponent: AffectedComponent) => this.refreshService.subject.next(affectedComponent));
          }
          this.dataChanged.emit(this.data);

          if (this.action.redirect) {
            this.router.navigate(['apps', this.action.redirect]);
          }
        },
        error: (err) => {
          this.hideLoadingDialog();
          this.showMessageFailure(`${this.action.messageFailure}: ${err}`);
          this.dataChanged.emit(this.data);

        },
        complete: () => {
          this.hideLoadingDialog();
        }
      });
  }

  hideLoadingDialog(): void {
    this.dialogLoadingRef.close();
  }

  showLoadingDialog(): void {
    this.dialogLoadingRef = this.matDialog.open(DialogLoadingComponent, { disableClose: true });
  }

  showMessageFailure(message: string): void {
    message = message || 'Action failed.';
    this.notifyService.notifyError(message);
  }

  showMessageSuccess(message: string): void {
    message = message || 'Action was successful.';
    this.notifyService.notify(message);
  }
}
