import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DataCollectionType, DbFunction, Option } from '@compass/core-data';
import { CellValueChangedEvent } from 'ag-grid-community';
import { Observable, forkJoin, of } from 'rxjs';
import { map, takeUntil, take } from 'rxjs/operators';
import { isEmpty } from 'lodash';

import { TableComponent } from '../table/table.component';

@Component({
  selector: 'compass-control-table-dimension-manual',
  templateUrl: '../table/table.component.html',
  styleUrls: ['../table/table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ControlTableDimensionManualComponent extends TableComponent implements OnDestroy {
  // This property holds the refData for the column definitions
  // i.e. {"colKey1": {1: "foo", 2: "bar"}, "colKey2": {1: "foo", 2: "bar"}}
  refDataColMappings = {};

  private getCombinationsOfOptions(options: Option[][]) {
    if (options.length === 0) {
      return [];
    } else if (options.length === 1) {
      return options[0];
    } else {
      const result = [];
      const combinations = this.getCombinationsOfOptions(options.slice(1));
      for (const c in combinations) {
        for (let i = 0; i < options[0].length; i++) {
          result.push([options[0][i], combinations[c]]);
        }
      }
      return result;
    }
  }

  // Get the data for the array of tableRows from the pagedef and make it ready for the grid
  // dbFunction MUST return an hstore with values: 'payload', JSON_AGG(data)::text
  private getGeneratedRowsForTableRows(): Observable<Array<{ [key: string]: string | number }>> {
    this.refDataColMappings = {};
    const dbFunction = this.control.tableRowsDbValues.dbCall as DbFunction;
    const parameters: Array<[string, string]> = this.dbFacade.getParametersWithDbFunction(
      dbFunction,
      this.form,
      this.schema,
      this.section
    );

    if (dbFunction.functionCall?.keys?.length && isEmpty(parameters)) {
      return null;
    }

    // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
    if (dbFunction.functionCall && dbFunction.functionCall.staticKeys) {
      dbFunction.functionCall.staticKeys.forEach((staticKey: any) => {
        parameters.push([staticKey.key, staticKey.value]);
      });
    }

    return this.dbFacade.fn(dbFunction, parameters).pipe(
      map((results: any) => {
        const data = results.pie_query.payload;
        // No result set returned from database
        if (!data) return [];

        const jsonData = JSON.parse(data);
        const tableColumnObj = this.gridService.generateGridRow(this.control);
        let newRows = [];
        jsonData.forEach(row => {
          // Add the keys for the tableColumns into a "row" (ag-grid) object
          let newRow: {[key: string]: string | number} = {};
          // Iterate through the tableRows to append each key value pair ot the newRow object
          this.control.tableRows.forEach(tableRow => {
            const newRowKey = tableRow.key;
            const newRowVal = row[tableRow.dbColMap.key];

            // Append the key/value pair
            const refData = {[newRowVal]: row[tableRow.dbColMap.value]};
            this.refDataColMappings[tableRow.key] = this.refDataColMappings[tableRow.key] ? Object.assign(this.refDataColMappings[tableRow.key], refData) : refData;

            // Add the key for the tableRow and the correct value from the db data row
            newRow[newRowKey] = newRowVal;
          });

          // Merge the tableRows key/value pairs with the tableColumnObj key/value (null) pairs
          newRow = Object.assign(newRow, tableColumnObj);
          newRows.push(newRow);
        });

        // Alter columnDefs
        this.control.tableRows.forEach(tableRow => {
          // Set the refData
          this.gridApi.getColumnDef(tableRow.key).refData = this.refDataColMappings[tableRow.key];

          // In the future, if we are to support editing Dimensions, we would need this:
          // this.gridApi.getColumnDef(tableRow.key).cellEditorParams = {values: Object.keys(this.refDataColMappings[tableRow.key])};
        })

        return newRows;
      })
    )
  }

  private getGeneratedRowsPerDimension(): Observable<Array<{ [key: string]: string | number }>> {
    if (!this.control.tableRows) {
      return of([]);
    }

    const optionsObservables = this.dbFacade.getDimMembersOptionsObservables(
      this.control.tableRows,
      this.form,
      this.section
    );

    return forkJoin(optionsObservables.map(optionsObservable => optionsObservable.observable)).pipe(
      take(1),
      map((rows: Option[][]) => {
        const newRows: Array<{ [key: string]: string | number }> = [];

        if (this.control.tableRows.length === 1) {
          rows.forEach((options, rowIndex) => {
            options.forEach(option => {
              const row = this.gridService.generateGridRow(this.control);
              row[this.control.tableRows[rowIndex].key] = option.value;
              newRows.push(row);
            });
          });
        } else if (this.control.tableRows.length > 1) {
          const combinations = this.getCombinationsOfOptions(rows);
          combinations.forEach(combination => {
            const row = this.gridService.generateGridRow(this.control);
            combination.forEach(
              (option, index) => (row[this.control.tableRows[index].key] = option.value)
            );
            newRows.push(row);
          });
        }

        return newRows;
      })
    );
  }

  setRowData(): void {
    if (this.relatedFormControls.some(formControl => formControl.status != 'VALID')) return;

    const dbValues = this.getDbValuesFn();
    if (!dbValues) return;

    const tableRowsObservable = this.control.tableRowsDbValues ? this.getGeneratedRowsForTableRows() : this.getGeneratedRowsPerDimension();

    // Get the data for the tableRows (grid ready) and dbValues (hstore of values for the array of tableRows + tableColumns)
    forkJoin([tableRowsObservable, dbValues])
      .pipe(
        map(([newRows, dbValues]) => {
          // Merge the database data to the tableRows data (where the values match)
          dbValues.map(row => {
            // find the first matching row (hstore array of values) from dbValues record set that has the same set of values as a tableRowsValues row
            const newRow = newRows.find(r => {
              return this.control.tableRows.every((collectionDim, index) => r[collectionDim.key] == row[index])
            });
            if (!newRow) {
              return;
            }

            // todo: tableColumns should be refactored to be a single object instead of an array
            const tableColumn = this.control.tableColumns[0];

            // add tableColumns to the newRow
            switch (tableColumn.objectID) {
              case DataCollectionType.Dimension:
                throw new Error('Dimension/Manual table does not support dimension table columns.');
              case DataCollectionType.Manual:
                tableColumn.fields.forEach((control, index) => {
                  newRow[control.key] = row[index + this.control.tableRows.length];
                });
            }
          });

          /**
           * newRows data structure with column keys and data values for ag-grid:
            [
              {[tableRow[0].key]: "a", [tableRow[1].key]: "b", [tableColumn[0].key]: "c", [tableColumn[1].key]: "d"},
              {[tableRow[0].key]: "foo", [tableRow[1].key]: "bar", [tableColumn[0].key]: "foo", [tableColumn[1].key]: "bar"},
              ...
            ]
          */
          return newRows;
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        rowData => this.rowData.next(rowData),
        err => {
          this.showLoadingOverlay = false;
          this.gridApi.showNoRowsOverlay();
          console.error(err);
          this.notifyService.notifyError('There was an error populating the grid. Please check the console for more information.');
        }
      );
  }
}
