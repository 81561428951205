import { Component, OnDestroy } from '@angular/core'
import { AgEditorComponent, ICellRendererAngularComp } from 'ag-grid-angular'

@Component({
  selector: 'app-ag-grid-checkbox-editor',
  template: `
    <input 
      type="checkbox" 
      (click)="checkedHandler($event)"
      [checked]="params.value == 'true' || params.value == true"
      [disabled]="!params.disabled"
      style="padding-left: 10px; padding-right: 10px;"
    />
  `
})
export class GridCheckboxComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  checkedHandler(event) {
    let checked = event.target.checked ? 'true' : 'false';
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }

  refresh() {
    return false;
  }

  getValue() {
    return this.params.value; 
  }
}
