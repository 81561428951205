import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable, Subject, BehaviorSubject, merge, of } from 'rxjs';
import {
  map,
  startWith,
  distinctUntilChanged,
  takeUntil,
  filter,
  debounceTime,
  tap,
  catchError
} from 'rxjs/operators';

import {
  ControlAutoComplete,
  DataSourceType,
  Db,
  DbFunction,
  Section,
  Schema,
  FormService,
  Option,
  RefreshService,
  NotifyService
} from '@compass/core-data';
import { DbFacade } from '@compass/core-state';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';

// If the searchControl's value is a string, that means it did not match an option (an object from the array).
function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string' && control.value.length) {
      return { 'invalidAutocompleteObject': { value: control.value } };
    }
    return null;  /* valid option selected */
  }
}

@Component({
  selector: 'compass-control-auto-complete',
  templateUrl: './control-auto-complete.component.html',
  styleUrls: ['./control-auto-complete.component.scss']
})
export class ControlAutoCompleteComponent implements OnInit, OnDestroy {
  /** The visible Input Control that is used to enter values to narrow Options list */
  searchControl = new FormControl('', { validators: [autocompleteObjectValidator()]});

  /** The dropdown control to display. */
  @Input() control: ControlAutoComplete;

  /** The FormGroup instance for the schema. */
  @Input() form: FormGroup;

  /** Only wire to controls in the appropriate section */
  @Input() section: Section;

  @Input() value: any;

  @Input() schema: Schema;

  @Input() relationalKey: string;

  @Input() isParameter: boolean;

  formControl: AbstractControl;

  /** Subject to trigger unsubcribe to observable streams. */
  private unsubscribe = new Subject<void>();

  /** An observable of an array of key and value objects that populates the dropdown options. */
  options = new BehaviorSubject<Array<Option>>([]);

  filteredOptions: Observable<Option[]>;

  allOptions = new Array<Option>();

  searchControlKey: string;

  relatedFormControls;

  constructor(
    private readonly dbFacade: DbFacade,
    private readonly formService: FormService,
    public readonly refreshService: RefreshService,
    private apollo: Apollo,
    private readonly notifyService: NotifyService
  ) {}

  panelOpened() {
    const selectedOpt = document.getElementById(this.formControl.value);
    if (selectedOpt) {
      selectedOpt.scrollIntoView(true);
    }
    setTimeout(() => {
      this.allowSideNavScroll(false);
    }, 100)
  }

  panelClosed() {
    this.allowSideNavScroll(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.form.removeControl(this.searchControlKey);
  }

  ngOnInit(): void {
    if (!this.relationalKey) console.error('Expecting relationalKey to be defined here!');
    this.formControl = this.form.get(this.relationalKey);

    if (!this.formControl) console.error('Expecting formControl to be defined here!')

    // Register this component with the refresh service.
    // If affectedComponents for an action has this component's id (from the schema), the refresh service will call setOptionsWithDbFunction to repopulate the options.
    if (this.control.key) {
      this.refreshService.refreshComponent(this, this.control.key);
    }

    this.searchControlKey = 'search_' + this.control.key;
    this.form.addControl(this.searchControlKey, this.searchControl);

    // Watch for value changes and filter options as user types into the search Input
    this.searchControl.valueChanges.pipe(startWith('')).subscribe(value => {
      if (typeof value != 'string') return;

      const searchString = value.toLowerCase();
      this.filteredOptions = this.options.pipe(
        map(options => {
          this.allOptions = options;
          return options.filter(option => option.label.toLowerCase().includes(searchString));
        })
      );
    });

    this.relatedFormControls = this.formService.getRelatedFormControls(
      this.control,
      this.form,
      this.schema,
      this.section
    );

    this.populateOptions();

    // If the search control status is invalid and the relationalKey form control has a value, clear that value out
    this.searchControl.statusChanges.pipe(
      filter(value => value == 'INVALID' && !isEmpty(this.formControl.value) && isEmpty(this.searchControl.value)),
      tap(() => this.optionSelected(null)),
      takeUntil(this.unsubscribe)
    ).subscribe();

    // Auto-select first option if parameter
    if (this.isParameter) {
      this.options.pipe(
        filter(options => !isEmpty(options) && options.length == 1)
      ).subscribe(options => {
        // Auto-select first option if parameter
        this.formControl.setValue(options[0].value)
      });
    }
  }

  onBlur(event: Event & { target: HTMLInputElement }): void {
    try {
      const newValue = event.target ? event.target.value : event;
      if (newValue === '') {
        // Clear the selection
        this.optionSelected(null);
      }

      this.allowSideNavScroll(true);
    } catch (err) {
      console.error(err);
    }
  }

  optionSelected(value: MatAutocompleteSelectedEvent & { value: string }): void {
    let val = value && value.hasOwnProperty('value') ? value.value : value;

    val = val || '';
    this.formControl.setValue(val);
  }

  get selectedValue(): string {
    if (this.form) {
      const value = this.formControl.value ? this.formControl.value : null;
      if (value) {
        const option = this.allOptions.find(option => option.value === value) || null;
        return this.displayValue(option);
      }
    }
    return '';
  }

  displayValue(value: Option | string | null): string | null {
    if (value === null) {
      return null;
    } else if (typeof value === 'string') {
      return value;
    } else {
      return value.label;
    }
  }

  private observeKeys() {
    const items = this.control.items as Db;
    const dbFunction = items.dbCall as DbFunction;

    // verify function call has keys
    if (
      !dbFunction.functionCall.keys ||
      (dbFunction.functionCall.keys && dbFunction.functionCall.keys.length === 0)
    ) {
      return;
    }

    // unsubscribe from previous value changes observables
    this.unsubscribe.next();

    // watch for future value changes on dependent controls
    const observables = dbFunction.functionCall.keys
      .filter(key => this.formService.getFormControl(key, this.form, this.schema, this.section))
      .map(key => {
        const control = this.formService.getFormControl(key, this.form, this.schema, this.section);
        return control.valueChanges.pipe(
          filter(val => !!val),
          distinctUntilChanged()
        );
      });

    merge(...observables)
      .pipe(debounceTime(100))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.setOptionsWithDbFunction(dbFunction));
  }

  private setOptionsFromSoda() {
    // Query backend graphql
    this.apollo
      .query({
        query: gql` query sodaApiQuery($json: JSON, $debug: Boolean) {
            soda(json: $json, debug: $debug)
        }`,
        variables: {
          json: this.control.items,
          debug: true
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        tap((resp: any) => {
          resp.data.results;

          const options = resp.data.soda.results.map(res => {
            return {
              value: res.org_id,
              label: res.payload
            };
          });

          this.options.next(options);

          const value = this.formControl.value;
          const exists = options.findIndex(option => option.value === value) !== -1;
          if (!exists) {
            this.formControl.setValue(null);
            return;
          }

          if (value) {
            this.optionSelected(value);
          }
        }),
        catchError((err) => {
          console.error(err)
          return of(err);
        })
      )
      .subscribe()
  }

  private setOptionsWithDbFunction(dbFunction: DbFunction): void {
    // Check for invalid params (keys)
    const missingRequiredParams = this.relatedFormControls ? this.relatedFormControls.find(formControl => {
      const valueRequired = this.formService.isControlRequired(formControl);
      const missingValue = isEmpty(formControl.value);
      return valueRequired && missingValue;
    }) : false;
    if (missingRequiredParams) return;

    this.dbFacade
      .getOptionsWithDbFunction(dbFunction, this.form, this.section)
      .subscribe(options => {
        this.options.next(options);

        const value = this.formControl.value;
        const exists = options.findIndex(option => option.value === value) !== -1;
        if (!exists) {
          this.formControl.setValue(null);
          return;
        }

        if (value) {
          this.optionSelected(value);
        }
      });
  }

  allowSideNavScroll(allow: boolean) {
    const el = document.getElementById('schema');
    if (el) {
      el.style.overflow = allow ? 'auto' : 'hidden';
    }
  }

  populateOptions(isRefresh?: boolean) {
    // Populate the AutoComplete with the options to select from
    if (this.control.items['$schema'] && this.control.items['$schema'] == 'soda.json') {
      this.setOptionsFromSoda();
    } else if (this.control.items.objectID == DataSourceType.DatabaseCall) {
      if (!isRefresh) {
        this.observeKeys();
      }

      const dbFunction = this.control.items.dbCall as DbFunction;
      this.setOptionsWithDbFunction(dbFunction);
    } else {
      this.notifyService.notifyError(`Datasource ${this.control.items.objectID} is not supported.`)
      console.error('DataSourceType not supported', JSON.stringify(this.control.items));
    }
  }
}
