import gql from 'graphql-tag';

export const usersFragment = gql`
  fragment usersFragment on UserDTO {
    usr_id
    usr_name
    usr_desc
    usr_firstname
    usr_lastname
    usr_email
    usr_active
    usr_created 
    usr_updated
  }
`;

export const authenticatedUserQuery = gql`
  query authenticatedUserQuery {
    authenticatedUser {
      ...usersFragment
    }
  }
  ${usersFragment}
`;

export const allUsersQuery = gql`
  query allUsersQuery {
    allUsers {
      ...usersFragment
    }
  }
  ${usersFragment}
`;

export const userByIdQuery = gql`
  query userByIdQuery($usr_id: ID!) {
    userById(usr_id: $usr_id) {
      ...usersFragment
    }
  }
  ${usersFragment}
`;
