<div [id]="parameter.id" [ngStyle]="{ width: parameter.pageWidth }">
  <!-- todo: render title -->
  <!-- todo: render description -->
  <compass-form-control
    [form]="form"
    [control]="parameter.controlType"
    [section]="section"
    [schema]="schema"
    [isParameter]="true"
  ></compass-form-control>
</div>
