import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoles from './roles.reducer';
import { emptyRole } from '@compass/core-data';

export const selectRolesState = createFeatureSelector<fromRoles.RolesState>('roles');

export const selectRoleIds = createSelector(
  selectRolesState,
  fromRoles.selectRoleIds
);

export const selectRoleEntities = createSelector(
  selectRolesState,
  fromRoles.selectRoleEntities
);

export const selectAllRoles = createSelector(
  selectRolesState,
  fromRoles.selectAllRoles
);

export const selectCurrentRoleId = createSelector(
  selectRolesState,
  fromRoles.getSelectedRoleId
);

export const selectCurrentRole = createSelector(
  selectRoleEntities,
  selectCurrentRoleId,
  (roleEntities, roleId) => {
    return roleId ? roleEntities[roleId] : Object.assign({}, emptyRole);
  }
);

export const selectRoleLoadingStatus = createSelector(
  selectRolesState,
  state => state.isLoading
);
