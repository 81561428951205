import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Item } from '@compass/core-data';
import { ItemsActions, ItemsActionTypes } from './items.actions';

export interface ItemsState extends EntityState<Item> {
  selectedItemId: string | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Item> = createEntityAdapter<Item>();
export const initialState: ItemsState = adapter.getInitialState({
  selectedItemId: null,
  isLoading: false,
});

export function itemsReducer(state = initialState, action: ItemsActions): ItemsState {
  switch (action.type) {
    case ItemsActionTypes.ITEM_SELECTED: {
      return Object.assign({}, state, { selectedItemId: action.payload });
    }

    case ItemsActionTypes.LOAD_ITEMS: {
      return {
        ...state,
        isLoading: true
      }
    }

    case ItemsActionTypes.ITEMS_LOADED: {
      return adapter.upsertMany(action.payload, {
        ...state,
        isLoading: false
      })
    }

    case ItemsActionTypes.ADD_ITEM: {
      return {
        ...state,
        isLoading: true
      }
    }

    case ItemsActionTypes.ITEM_ADDED: {
      return adapter.addOne(action.payload, {
        ...state,
        isLoading: false
      })
    }

    case ItemsActionTypes.UPDATE_ITEM: {
      return {
        ...state,
        isLoading: true
      }
    }

    case ItemsActionTypes.ITEM_UPDATED: {
      return adapter.upsertOne(action.payload, {
        ...state,
        isLoading: false
      })
    }

    case ItemsActionTypes.DELETE_ITEM: {
      return {
        ...state,
        isLoading: true
      }
    }

    case ItemsActionTypes.ITEM_DELETED: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        isLoading: false
      })
    }

    default:
      return state;
  }
}

export const getSelectedItemId = (state: ItemsState) => state.selectedItemId;

// get selectors...

export const {
  selectIds: selectItemIds,
  selectEntities: selectItemEntities,
  selectAll: selectAllItems,
  selectTotal: selectItemTotal
} = adapter.getSelectors();
