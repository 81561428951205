export interface Item {
  id: string;
  name: string;
  description?: string;
}

export const emptyItem: Item = {
  id: null,
  name: '',
  description: ''
}
