import { Component } from '@angular/core'
import { ICellRendererParams } from 'ag-grid-community'
import { ICellRendererAngularComp } from 'ag-grid-angular'
import { RefreshService } from '@compass/core-data';

@Component({
  selector: 'app-ag-grid-changecellrenderer',
  template: `<div [class.highlight]="applyHighlightClass">{{value}}</div>`,
  styles: [
    `.highlight {
      color: red;
    }`
  ]
})

export class GridChangeCellRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
  value: string;
  applyHighlightClass = false;
  valueChanges: string[] = [];

  constructor(private readonly refreshService: RefreshService) { }

  refresh(params: any): boolean {
    // If this is the first change in this cell, push the change into the valueChanges array
    if (!this.valueChanges.length) {
      this.valueChanges.push(this.value)
    } 
    
    const newValue = params.valueFormatted || params.value;

    if (newValue !== this.value) {
      this.valueChanges.push(newValue)
    }

    const hasHighlight = params.eGridCell.getElementsByClassName("highlight");

    if (newValue === this.value && !hasHighlight.length) {
      return false;
    }

    this.applyHighlightClass = this.valueChanges[0] != this.valueChanges[this.valueChanges.length - 1];
    this.value = newValue;

    // return true to tell the grid we refreshed successfully
    return true;
  }

  removeHighlight(): void {
    this.applyHighlightClass = false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.valueFormatted || params.value;

    if (params.context && params.context.controlTable) {
      // This doesn't seem to be necessary anymore. Leaving it commented out for the time being
      // this.refreshService.refreshComponent(this, params.context.controlTable)
    }
  }
}
