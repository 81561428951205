import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { map as _map } from 'lodash';

import { User } from './users.model';
import { allUsersQuery, authenticatedUserQuery, userByIdQuery } from './users.graphql';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private apollo: Apollo) {}

  authenticatedUser(): Observable<User> {
    return this.apollo
      .query({
        query: authenticatedUserQuery,
        fetchPolicy: 'network-only'
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.authenticatedUser));
  }

  all(): Observable<User[]> {
    return this.apollo
      .query({
        query: allUsersQuery,
        fetchPolicy: 'network-only'
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.allUsers));
  }

  userById(usr_id: number): Observable<User> {
    return this.apollo
      .query({
        query: userByIdQuery,
        fetchPolicy: 'network-only',
        variables: {
          usr_id: usr_id
        }
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.userById));
  }
}
