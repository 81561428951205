import { Injectable } from '@angular/core';

import { filter } from 'rxjs/operators';
import { Store, ActionsSubject, select } from '@ngrx/store';

import { Item } from '@compass/core-data';
import { ItemsState } from './items.reducer';
import * as ItemsActions from './items.actions';
import { ItemsActionTypes } from './items.actions';
import { selectAllItems, selectCurrentItem, selectItemLoadingStatus } from './items.selectors';

@Injectable({providedIn: 'root'})
export class ItemsFacade {
  items$ = this.store.pipe(select(selectAllItems));
  currentItem$ = this.store.pipe(select(selectCurrentItem));
  isItemLoading$ = this.store.pipe(select(selectItemLoadingStatus));

  mutations$ = this.actions$
    .pipe(
      filter(action =>
        action.type === ItemsActionTypes.ADD_ITEM
        || action.type === ItemsActionTypes.UPDATE_ITEM
        || action.type === ItemsActionTypes.DELETE_ITEM
      )
    );

  constructor(private store: Store<ItemsState>, private actions$: ActionsSubject) {}

  selectItem(itemId: string) {
    this.store.dispatch(new ItemsActions.ItemSelected(itemId));
  }

  loadItems() {
    this.store.dispatch(new ItemsActions.LoadItems());
  }

  createItem(item: Item) {
    this.store.dispatch(new ItemsActions.AddItem(item));
  }

  updateItem(item: Item) {
    this.store.dispatch(new ItemsActions.UpdateItem(item));
  }

  deleteItem(item: Item) {
    this.store.dispatch(new ItemsActions.DeleteItem(item));
  }
}
