import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Route, RouterModule, RouterStateSnapshot } from '@angular/router';
import { CommonModule } from '@angular/common';

import { BudgetToolComponent } from './budget-tool/budget-tool.component';
import { AuthGuard } from './core/guards/auth.guard';
import { PrivGuard } from './core/guards/priv.guard';
import { UploadComponent } from './upload/upload.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from 'libs/ui-login/src/lib/login/login.component';
import { LoginSuccessComponent } from '@compass/ui-login';
import { AppRouteComponent } from './budget-tool/app-route/app-route-component';
import { SodaUtilityComponent } from './soda-utility/soda-utility.component';
import { SchemasFacade } from '@compass/core-state';

@Injectable({ providedIn: 'root' })
export class AppResolver implements Resolve<any> {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
    return route.paramMap.get('folder');
  }
}

const routes: Route[] = [
  { path: 'login', component: LoginComponent },
  { path: 'login/success', canActivate: [AuthGuard], component: LoginSuccessComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'apps',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: BudgetToolComponent
      },
      { 
        canActivate: [PrivGuard],
        path: 'api',
        pathMatch: 'full',
        data: {
          folder: 'api'
        },
        component: SodaUtilityComponent
      },
      {
        path: ':app',
        component: AppRouteComponent
      },
      {
        path: ':folder/:schema',
        canActivate: [PrivGuard],
        component: BudgetToolComponent,
        resolve: {
          folder: AppResolver
        }
      }
    ]
  },
  { path: 'upload', component: UploadComponent, canActivate: [AuthGuard, PrivGuard] },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  providers: [AuthGuard, PrivGuard],
  exports: [RouterModule]
})
export class RoutingModule {}
