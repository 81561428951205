import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Favorite } from './favorite.model';
import { getFavorites, likeEntity, dislikeEntity } from './favorite.graphql';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  constructor(private apollo: Apollo) {}

  loadFavorites(): Observable<Favorite[]> {
    return this.apollo
      .query({
        query: getFavorites,
        fetchPolicy: 'network-only'
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.getFavorites));
  }

  likeEntity(id: string, type: string, label: string): Observable<Favorite[]> {
    return this.apollo
      .mutate({
        mutation: likeEntity,
        variables: {
          input: {
            id,
            type,
            label
          }
        }
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.likeEntity));
  }

  dislikeEntity(id: string, type: string): Observable<Favorite[]> {
    return this.apollo
      .mutate({
        mutation: dislikeEntity,
        variables: {
          input: {
            id,
            type
          }
        }
      })
      .pipe(map((response: ApolloQueryResult<any>) => response.data.likeEntity));
  }
}
