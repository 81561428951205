<mat-toolbar color="primary" [ngStyle]="envBorderStyle()" *ngIf="isAuthenticated"
  class="mat-elevation-z5 d-flex justify-content-between position-relative">
  <div class="d-flex">
    <img class="bs-logo my-2 mx-4" src="assets/images/bs-logo-white.png" />
    <span class="title align-self-center">{{title}}</span>
  </div>
  <div class="d-flex">
    <span *ngIf="authenticatedUser$ | async as user" class="align-self-center mr-3">
      {{user.usr_name}}
    </span>
    <button mat-mini-fab (click)="logout()">
      <mat-icon>person</mat-icon>
    </button>
  </div>
</mat-toolbar>

<mat-toolbar color="primary" *ngIf="!isAuthenticated"
  class="mat-elevation-z5 d-flex justify-content-between position-relative">
    <img class="bs-logo my-2 mx-4" src="assets/images/bs-logo-white.png" />
    <span class="title align-self-center">{{title}}</span>
</mat-toolbar>
