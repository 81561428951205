export interface Scenario {
  sce_id: number;
  sce_name: string;
  sce_desc: string;
  scen_sce_id: number;
  scen_sce_sort: number;
  sce_active: boolean;
  sce_sys_active: boolean;
  sce_sys_ts: string;
  sce_sys_usr: string;
  sce_sys_desc: string;
}

export const emptyScenario: Scenario = {
  sce_id: null,
  sce_name: '',
  sce_desc: '',
  scen_sce_id: null,
  scen_sce_sort: null,
  sce_active: false,
  sce_sys_active: false,
  sce_sys_ts: '',
  sce_sys_usr: '',
  sce_sys_desc: ''
}
