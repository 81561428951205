import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ControlHidden, Section, Schema, FormService } from '@compass/core-data';

@Component({
  selector: 'compass-control-hidden',
  templateUrl: './control-hidden.component.html',
  styleUrls: ['./control-hidden.component.scss']
})
export class ControlHiddenComponent {}
