import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(private snackbar: MatSnackBar) { }

  notify(message: string, action = '') {
    this.snackbar.open(message, action, {
      duration: 2500,
    });
  }

  notifyError(message: string, action = 'Close') {
    // Remove GraphQL's formatted message string
    message = message.replace('Error: Error: GraphQL error: ', '');
    message = message.replace('GraphQL error: ', '');
    message = message.split(' SQL: ')[0];

    return this.snackbar.open(message, action, {
      duration: 10000,
      panelClass: "error-message"
    });
  }
}
