import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  ComponentChart,
  DbCallType,
  DbFunction,
  DbTable,
  FormService,
  RefreshService,
  Schema,
  Section
} from '@compass/core-data';
import { DbFacade, GridService } from '@compass/core-state';
import { AgCartesianChartOptions, AgCartesianSeriesOptions } from 'ag-charts-community';
import { isEmpty } from 'lodash';
import * as moment from 'moment-timezone';
import { parse } from 'papaparse';
import { merge, Subject, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  takeUntil
} from 'rxjs/operators';

@Component({
  selector: 'compass-ag-chart',
  templateUrl: './component-ag-chart.component.html',
  styleUrls: ['component-ag-chart.component.scss'],
  host: {
    class: 'compass-ag-chart'
  }
})
export class CompassAgChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() id: string;
  @Input() componentType: ComponentChart;
  @Input() form: FormGroup;
  @Input() schema: Schema;
  @Input() section: Section;

  chartConfiguration: AgCartesianChartOptions;
  private unsubscribe = new Subject<void>();

  /** Form Controls that have key referenced by the chart */
  relatedFormControls: Array<AbstractControl> = [];

  constructor(
    private readonly formService: FormService,
    public gridService: GridService,
    private readonly dbFacade: DbFacade,
    private readonly refreshService: RefreshService,
    private readonly cd: ChangeDetectorRef
  ) {
    this.chartConfiguration = {}
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  ngOnInit(): void {
    this.relatedFormControls = this.formService.getRelatedFormControls(
      this.componentType,
      this.form,
      this.schema,
      this.section
    );
  }

  ngAfterViewInit() {
    this.refreshService.refreshComponent(this, this.id);
    this.populate();
  }

  private setFormValuesUsingDbFunction(dbFunction: DbFunction) {
    let parameters = this.dbFacade.getParametersWithDbFunction(
      dbFunction,
      this.form,
      this.schema,
      this.section
    );

    if (!parameters || !parameters.length) {
      return this.chartConfiguration = {};
    }

    const missingRequiredParams = this.relatedFormControls ? this.relatedFormControls.find(formControl => {
      const valueRequired = this.formService.isControlRequired(formControl);
      const missingValue = isEmpty(formControl.value);
      return valueRequired && missingValue;
    }) : false;

    // Check that parameters (relatedFormControls) all have values
    if (missingRequiredParams) {
      // console.error(`Missing required parameter(s) for chart (title: '${this.componentType.title}').`);
      return this.chartConfiguration = {};
    }


    this.dbFacade
      .fn(dbFunction, parameters)
      .pipe(
        first(),
        map(result => {
          const rows = result['pie_query'];
          const keys = Object.keys(rows);
          return keys
            .map(key => rows[key])
            .map(value => {
              try {
                const parsed = parse(value, { delimiter: ',' });

                // Avoid JSON.parse error when parsed.data == [""]. This happens when value == """".
                // TODO - display a "No Data" message on the chart
                if (parsed.data[0].includes("")) {
                  return {};
                }
                const data = JSON.parse(parsed.data[0]);
                return data;
              } catch (error) {
                console.error(error);
                return {};
              }
            });
        }),
        map(results => results[0])
      )
      .subscribe(async (parsed) => {
        await this.createChartConfigurationFromDbFunction(parsed);
      });
  }

  private setFormValuesUsingDbTable(dbTable: DbTable) {
    const chartConfiguration = this.componentType.chartType;
    const parameters = this.dbFacade.getParametersWithTableName(
      dbTable,
      this.form,
      this.schema,
      this.section
    );

    if (dbTable.staticKeys) {
      dbTable.staticKeys.forEach((staticKey: any) => {
        parameters.push([staticKey.key, staticKey.value]);
      });
    }

    if (chartConfiguration?.xAxis?.yearsOut !== undefined) {
      const startId = this.convertToTimeId(moment());
      const endId = this.convertToTimeId(moment().add(chartConfiguration.xAxis.yearsOut, 'year').endOf('year'))
      let dates = '';
      for (let i = startId; i < endId; i++) {
        dates += `${i},`
      }
      dates += `${endId}`
      parameters.push(['tim_id', dates])
    }

    // must sort data Only by xAxis key
    const sortColumns = {
      name: chartConfiguration.xAxis.key,
      sort: chartConfiguration.xAxis.sort
    };

    this.dbFacade.tbl(dbTable, dbTable.tableColumns, parameters, [sortColumns]).pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe),
      catchError(e => {
        console.error(e);
        return throwError(e);
      })
    ).subscribe(response => {
      if (response?.length) {
        this.createChartFromTable(response)
      } else {
        // TODO - display a "No Data" message on the chart
        console.log('No data')
      }
    });

  }

  async createChartConfigurationFromDbFunction(configurations: any): Promise<void> {
    if (configurations?.data) {
      const parsedData = configurations.data;
      const series: Array<AgCartesianSeriesOptions> = [];
      const dataSetsArray: Array<number> = [];
      for (const set of parsedData.datasets) {
        const chartType = set.type ?? configurations.type;
        if (chartType === 'bar') {
          const label = set.label
          series.push({ type: 'column', xKey: 'label', yKey: label, formatter: params => { return { fill: params.datum[`${label}color`] } } });
        } else if (chartType === 'line') {
          series.push({ type: 'line', xKey: 'label', yKey: set.label, stroke: set.backgroundColor, marker: { enabled: false, fill: set.backgroundColor } });
        }
        dataSetsArray.push(set.data.length);
      }
      const dataValuesLength = parsedData.labels.length;
      const dataSets = dataSetsArray.length;
      const data = [];
      let maxValue = 0;
      if (dataSetsArray.every(v => v === dataValuesLength)) {
        for (let i = 0; i < dataValuesLength; i++) {
          const value = { label: parsedData.labels[i] };
          for (let j = 0; j < dataSets; j++) {
            maxValue = Math.max(maxValue, parsedData.datasets[j].data[i])
            const label = parsedData.datasets[j].label;
            value[label] = parsedData.datasets[j].data[i];
            if (parsedData.datasets[j].pointBorderColor === undefined) {
              value[`${label}color`] = parsedData.datasets[j].backgroundColor?.[i]
            }
          }
          data.push(value);
        }
      }
      this.chartConfiguration = {
        data,
        series,
        axes: [
          {
            type: 'number',
            position: 'left',
            max: maxValue
          },
          {
            type: 'category',
            position: 'bottom'
          }
        ],
        legend: { position: 'top' }
      }
    }
  }

  convertToTimeId(time: moment.Moment): number {
    const difference = time.startOf('month').diff(moment('1-1-2005', 'MM-DD-YYYY'), 'month');
    return Math.floor(difference / 12) + Math.floor(difference / 3) + difference + 89;
  }

  // this assumes y-axis is going to be number.
  createChartFromTable(response: Array<any>): void {
    const chartSchema = this.componentType.chartType;
    const overLayChart = chartSchema.overLayChart
    const yValueKey = chartSchema.yAxis.valueKey
    const ySeriesKey = chartSchema.yAxis.seriesKey;
    const xKey = chartSchema.xAxis.key;
    const series: Array<AgCartesianSeriesOptions> = [];
    const data = [];
    const seriesNames: Set<string> = new Set();
    for (const r of response) {
      seriesNames.add(r[ySeriesKey].toUpperCase());
    }
    const initialValue = {}
    for (const s of seriesNames) {
      initialValue[s] = 0;
    }
    let value = {
      ...initialValue
    }
    const firstDatum = response[0];
    value[xKey] = firstDatum[xKey];
    if (overLayChart) {
      for (let i = 0; i < overLayChart.length; i++) {
        value[`chart${i}`] = Number(firstDatum[overLayChart[i].valueKey])
      }
    }
    value[firstDatum[ySeriesKey].toUpperCase()] = Number(firstDatum[yValueKey]);
    for (let i = 1; i < response.length; i++) {
      const datum = response[i];
      const datumSeriesName = datum[ySeriesKey].toUpperCase();
      if (datum[xKey] === value[xKey]) {
        value[datumSeriesName] = Number(datum[yValueKey]);
      } else {
        data.push(value);
        value = {
          ...initialValue
        }
        value[xKey] = datum[xKey];
        value[datumSeriesName] = Number(datum[yValueKey]);
        if (overLayChart) {
          for (let i = 0; i < overLayChart.length; i++) {
            value[`chart${i}`] = Number(datum[overLayChart[i].valueKey])
          }
        }
      }
    }
    data.push(value);
    if (overLayChart) {
      for (let i = 0; i < overLayChart.length; i++) {
        if (overLayChart[i].objectID === 'line'){
          series.push({ type: 'line', xKey, yKey: `chart${i}`, yName: overLayChart[i].seriesName, marker: { enabled: false } });
        } else if (overLayChart[i].objectID === 'column')
        series.push({ type: 'column', xKey, yKey: `chart${i}`, yName: overLayChart[i].seriesName});
      }
    }

    // sorting to alphabetize key
    for (const s of [...seriesNames].sort()) {
      series.push({
        type: chartSchema.objectID,
        xKey: xKey,
        yKey: s,
        yName: s,
        stacked: true,
        highlightStyle: {
          series: {
            dimOpacity: 0.2,
            strokeWidth: 2
          }
        }
      })
    }

    this.chartConfiguration = {
      data,
      series,
      axes: [
        { type: chartSchema.yAxis.axisType, position: 'left', title: { text: chartSchema.yAxis.axisLabel } },
        { type: chartSchema.xAxis.axisType, position: 'bottom', title: { text: chartSchema.xAxis.axisLabel } }
      ],
      legend: { position: chartSchema.legendLocation }
    }

    // Force change detection to render the chart
    this.cd.detectChanges();
  }

  populate(refresh?: boolean): void {
    if (this.componentType.dbValues.dbCall.objectID === DbCallType.Function) {
      const dbFunction = this.componentType.dbValues.dbCall as DbFunction;
      if (
        !dbFunction.functionCall.keys ||
        (dbFunction.functionCall.keys && dbFunction.functionCall.keys.length === 0)
      ) {
        return;
      }
      if (refresh) {
        this.setFormValuesUsingDbFunction(dbFunction);
      }

      this.unsubscribe.next();
      this.unsubscribe.complete();

      const observables = dbFunction.functionCall.keys
        .filter(key => this.formService.getFormControl(key, this.form, this.schema, this.section))
        .map(key => {
          const control = this.formService.getFormControl(key, this.form, this.schema, this.section);
          return control.valueChanges.pipe(
            filter(val => !!val),
            distinctUntilChanged(),
            takeUntil(this.unsubscribe)
          );
        });
      merge(...observables)
        .pipe(debounceTime(100))
        .subscribe(() => this.setFormValuesUsingDbFunction(dbFunction));
    } else if (this.componentType.dbValues.dbCall.objectID === DbCallType.Table) {
      const dbTable: DbTable = this.componentType.dbValues.dbCall as DbTable;
      const observables = dbTable.keys
        .filter(key => this.formService.getFormControl(key, this.form, this.schema, this.section))
        .map(key => {
          const control = this.formService.getFormControl(key, this.form, this.schema, this.section);
          return control.valueChanges.pipe(
            filter(val => !!val),
            distinctUntilChanged(),
            takeUntil(this.unsubscribe)
          );
        });

      merge(...observables)
        .pipe(debounceTime(100))
        .subscribe(() => this.setFormValuesUsingDbTable(dbTable))
    }
  }
}
