<ng-container *ngIf="(currentSchema$ | async) as schema">

  <nav mat-tab-nav-bar *ngIf="schema?.navigations">
    <a
      *ngFor="let link of schema?.navigations"
      mat-tab-link
      [routerLink]="formatRouterLink(link)"
      [routerLinkActive]="['active']"
      #rla="routerLinkActive"
      [class.active]="rla.isActive"
      [style.opacity]="rla.isActive ? 1 : 0.6"
    >
      {{ link.title }}
    </a>
  </nav>

  <compass-schema
    id="schema"
    [schema]="schema"
    [form]="form">
  </compass-schema>

  <router-outlet></router-outlet>

</ng-container>