import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UsersState } from './users.reducer';

export const selectUsersState = createFeatureSelector<UsersState>('users');

export const selectAuthenticatedUserLoaded = createSelector(
  selectUsersState,
  state => state.authenticatedUserLoaded
);

export const selectIsUserLoading = createSelector(
  selectUsersState,
  state => state.isLoading
);

export const selectUser = createSelector(
  selectUsersState,
  state => state.user
);

export const selectUserPrivs = createSelector(
  selectUsersState,
  state => state.privileges
);

export const selectUserRoles = createSelector(
  selectUsersState,
  state => state.roles
);
