import { Component, HostBinding, Input, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentType, Component as SchemaComponent, Section, Schema } from '@compass/core-data';

@Component({
  selector: 'compass-component',
  templateUrl: './component.component.html',
  styleUrls: ['./component.component.scss']
})
export class ComponentComponent {
  /** The component to display. */
  @Input() component: SchemaComponent;

  /** The ComponentType enum for the template. */
  ComponentType = ComponentType;

  /** The FormGroup instance for the schema. */
  @Input() form: FormGroup;

  @Input() schema: Schema;

  @Input() section: Section;

  @Input()
  @HostBinding('class.boxed')
  showBox = false;
}
