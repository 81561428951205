import { Injectable } from '@angular/core';

import { filter } from 'rxjs/operators';
import { Store, ActionsSubject, select } from '@ngrx/store';

import { Role } from '@compass/core-data';
import { RolesState } from './roles.reducer';
import * as RolesActions from './roles.actions';
import { RolesActionTypes } from './roles.actions';
import { selectAllRoles, selectCurrentRole, selectRoleLoadingStatus } from './roles.selectors';

@Injectable({providedIn: 'root'})
export class RolesFacade {
  roles$ = this.store.pipe(select(selectAllRoles));
  currentRole$ = this.store.pipe(select(selectCurrentRole));
  isRoleLoading$ = this.store.pipe(select(selectRoleLoadingStatus));

  mutations$ = this.actions$
    .pipe(
      filter(action =>
        action.type === RolesActionTypes.ADD_ROLE
        || action.type === RolesActionTypes.UPDATE_ROLE
        || action.type === RolesActionTypes.DELETE_ROLE
      )
    );

  constructor(private store: Store<RolesState>, private actions$: ActionsSubject) {}

  selectRole(roleId: string) {
    this.store.dispatch(new RolesActions.RoleSelected(roleId));
  }

  loadRoles() {
    this.store.dispatch(new RolesActions.LoadRoles());
  }

  createRole(role: Role) {
    this.store.dispatch(new RolesActions.AddRole(role));
  }

  updateRole(role: Role) {
    this.store.dispatch(new RolesActions.UpdateRole(role));
  }

  deleteRole(role: Role) {
    this.store.dispatch(new RolesActions.DeleteRole(role));
  }
}
