import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UsersFacade } from '@compass/core-state';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NotifyService } from '@compass/core-data';

@Component({
  template: '<div></div>'
})
export class AppRouteComponent implements OnInit {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly userFacade: UsersFacade,
    private location: Location,
    private readonly notifyService: NotifyService,
  ) {}

  ngOnInit(): void {
    const folderName = this.activatedRoute.paramMap.pipe(
      filter(paramMap => paramMap.has('app')),
      map(paramMap => paramMap.get('app'))
    );

    const userApps = this.userFacade.authenticatedUserPrivs$.pipe(filter(apps => !!apps));

    combineLatest([folderName, userApps]).pipe(
    ).subscribe(([folderName, userApps]) => {
      const navToApp = userApps.find(app => app.app_foldername == folderName)

      if (navToApp) {
        this.router.navigate([`/apps/${navToApp.app_foldername}/${navToApp.app_filename}`], { replaceUrl: true });
      } else {
        console.error(`Could not find App with Folder Name ${folderName}`);
        this.location.back();
        this.notifyService.notifyError(`Could not find App with Folder Name ${folderName}`);
      }
    })
  }
}
