import { Injectable } from '@angular/core';
import { DbService, CollectionDim, ControlDropdown, ControlAutoComplete, DbTable, Db, DbFunction, DbCallType, Section, Schema, StaticKey, SortColumn } from '@compass/core-data';
import { SchemasFacade } from '../schemas/schemas.facade';
import { FormGroup } from '@angular/forms';
import { Observable, empty } from 'rxjs';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DbFacade {
  constructor(private dbService: DbService, private schemasFacade: SchemasFacade) { }

  fn(dbFunction: DbFunction, parameters?: Array<[string, string]>): Observable<any> {
    return this.dbService.fn(dbFunction, parameters);
  }

  tbl(dbTable: DbTable, columnNames: Array<string>, parameters?: Array<[string, string]>, sortColumns?: SortColumn[]): Observable<any> {
    return this.dbService.tbl(dbTable, columnNames, parameters, sortColumns);
  }

  getControlDropdownOptionsObservables(
    control: ControlDropdown | ControlAutoComplete,
    formGroup: FormGroup,
    section: Section
  ): any {
    const items = control.items as Db;
    const dbFunction = items.dbCall as DbFunction;
    return {
      key: control.key,
      observable: this.getOptionsWithDbFunction(dbFunction, formGroup, section)
    };
  }

  getDimMembersOptionsObservables(
    collectionDims: CollectionDim[],
    formGroup: FormGroup,
    section?: Section
  ): any[] {
    // verify dimensions
    if (!collectionDims || (collectionDims && collectionDims.length === 0)) {
      return;
    }

    return collectionDims
      .filter(row => row.dimMembers.dbCall.objectID === DbCallType.Function)
      .map(row => {
        const dbFunction = row.dimMembers.dbCall as DbFunction;
        return {
          key: row.key,
          observable: this.getOptionsWithDbFunction(dbFunction, formGroup, section)
        };
      });
  }

  getOptionsWithDbFunction(
    dbFunction: any,
    formGroup: FormGroup,
    section?: Section,
  ): Observable<Array<any>> {
    const schema = this.schemasFacade.currentSchema;
    const parameters = this.getParametersWithDbFunction(dbFunction, formGroup, schema, section);
    if (!this.validParameters(schema, formGroup, parameters, section)) {
      return empty();
    }
    return this.dbService.getOptionsWithDbFunction(dbFunction, formGroup, parameters);
  }

  getParams(dbFunction: any, formGroup: FormGroup, schema: Schema, section: Section): Array<[string, string]> {
    const mainParams = this.getParametersWithDbFunction(dbFunction, formGroup, schema, section)
    const additionalParams = (dbFunction.functionCall.StaticKeys || []).map((staticKey: StaticKey) => [staticKey.key, staticKey.value])
    return [...mainParams, ...additionalParams]
  }

  getParamKeys(dbFunction: any, formGroup: FormGroup, schema: Schema, section: Section): Array<string> {
    return this.getParametersWithDbFunction(dbFunction, formGroup, schema, section).map(p => p[0])
  }

  getRelationalParamKeys(dbFunction: any, formGroup: FormGroup, schema: Schema, section: Section): Array<string> {
    const keys = this.getParamKeys(dbFunction, formGroup, schema, section)
    const additionalKeys = [schema.id, section.id].reduce((memo, id) => {
      return [...memo, ...keys.map(key => `${id}_${key}`)]
    }, [])

    return [...keys, ...additionalKeys]
  }

  validParameters(schema, formGroup, parameters, section): boolean {
    if (isEmpty(parameters)) { return true; }
    let schemaParams = []
    if (section && section.parameters) {
      schemaParams = [...schemaParams, ...section.parameters]
    }
    if (schema && schema.parameters) {
      schemaParams = [...schemaParams, ...schema.parameters]
    }
    schemaParams = schemaParams.map(p => p.controlType.key)

    return !parameters.some(([key, value]) => schemaParams.includes(key) && isEmpty(value));
  }

  getParametersWithDbFunction(
    dbFunction: any,
    formGroup: FormGroup,
    schema?: Schema,
    section?: Section,
  ): Array<[string, string]> {
    const parameters = this.dbService.getParametersWithDbFunction(dbFunction, formGroup, schema, section);
    if (!this.validParameters(schema, formGroup, parameters, section)) {
      return [];
    }

    return parameters;
  }

  getParametersWithTableName(
    dbTable: DbTable,
    formGroup: FormGroup,
    schema: Schema,
    section: Section
  ): Array<[string, string]> {
    const parameters = this.dbService.getParametersWithTableName(dbTable, formGroup, schema, section);
    if (!this.validParameters(schema, formGroup, parameters, section)) {
      return [];
    }

    return parameters;
  }
}
