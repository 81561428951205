import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { SchemasActionTypes, SchemaLoaded } from './schemas.actions';
import { map, switchMap } from 'rxjs/operators';
import { SchemaService } from '@compass/core-data';

@Injectable()
export class SchemasEffects {
  constructor(private readonly actions$: Actions, private readonly schemaService: SchemaService) {}

  @Effect()
  loadSchema$: Observable<Action> = this.actions$.pipe(
    ofType(SchemasActionTypes.LOAD_SCHEMA),
    switchMap(({ payload }) =>
      this.schemaService
        .getSchema(payload)
        .pipe(map(schema => new SchemaLoaded({ schema, name: payload })))
    )
  );
}
