import { Schema } from '@compass/core-data';
import { RouterReducerState, getSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SchemasState } from './schemas.reducer';

export interface RouterState {
  router: RouterReducerState<any>;
}

export const selectRouter = createFeatureSelector<RouterState, RouterReducerState<any>>('router');

export const selectSchemasState = createFeatureSelector<SchemasState>('schemas');

const { selectRouteParams, selectRouteData } = getSelectors(selectRouter);

export const selectSchemas = createSelector(
  selectSchemasState,
  state => state.schemas
);

export const selectSchema = createSelector(
  selectSchemas,
  (schemas: { [x: string]: Schema }, props: { filename: string }) => schemas[props.filename]
);

export const selectCurrentSchema = createSelector(
  selectSchemas,
  selectRouteParams,
  selectRouteData,
  (schemas, params, data) => schemas[params?.schema]
);
