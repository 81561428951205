import { Component, Input } from '@angular/core'
import { AgEditorComponent } from 'ag-grid-angular'

@Component({
  selector: 'app-ag-grid-richtext-editor',
  template: `
    <app-control-mdeditor [value]="value" (valueChange)="valueChange($event)" (keydown)="onKeyDown($event)"></app-control-mdeditor>
  `,
  styles: [
    `::ng-deep .ag-theme-balham .ag-popup-editor {
      width: 400px;
    }`
  ]
})
export class GridRichTextComponent implements AgEditorComponent {
  @Input() value: string;
  readonly: boolean = false;
  private params: any;

  agInit(params: any): void {
    this.value = params.value
    this.readonly = params.readonly;
    this.params = params;
  }

  getValue() {
    return this.value;
  }

  isPopup(): boolean {
    return true;
  }

  onChange(event: any) {
    this.value = event.target.value;
    let colId = this.params.column.colId
    this.params.node.setDataValue(colId, this.value);
  }

  valueChange(event: any) {
    this.value = event;
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.stopPropagation();
    }
  }

}
