import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { HideSidenav, ShowSidenav } from './ui.actions';
import { State } from './ui.reducer';
import { isSidenavHidden } from './ui.selectors';

@Injectable({
  providedIn: 'root'
})
export class UiFacade {
  isSidenavHidden$ = this.store.pipe(select(isSidenavHidden));

  constructor(private store: Store<State>) {}

  hideSidenav() {
    this.store.dispatch(new HideSidenav());
  }

  showSidenav() {
    this.store.dispatch(new ShowSidenav());
  }
}
