import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  DbCallType,
  FormService,
  ManualDataCollection,
  NotifyService,
  RefreshService
} from '@compass/core-data';
import { DbFacade, GridService } from '@compass/core-state';
import { LocalStorageService } from '@compass/core-window';
import { CellValueChangedEvent } from 'ag-grid-community';
import { isEmpty } from 'lodash';
import { map, takeUntil } from 'rxjs/operators';

import { TableComponent } from '../table/table.component';

@Component({
  selector: 'compass-control-table-manual',
  templateUrl: '../table/table.component.html',
  styleUrls: ['../table/table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ControlTableManualComponent extends TableComponent implements OnInit, AfterViewInit {

  constructor(
    public cd: ChangeDetectorRef,
    public dbFacade: DbFacade,
    public gridService: GridService,
    public readonly notifyService: NotifyService,
    public readonly refreshService: RefreshService,
    public readonly matDialog: MatDialog,
    public readonly formService: FormService,
    public readonly router: Router,
    public readonly localStorageService: LocalStorageService
  ) {
    super(cd, dbFacade, gridService, notifyService, refreshService, matDialog, formService, router, localStorageService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dspRemoveRowBtn.next(!!this.control.deleteRow);
    this.dspAddRowBtn.next(!this.control.disableNewRow);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  onAddRow(): void {
    const newRow = this.gridService.generateGridRow(this.control);
    const firstColKey = this.columnApi.getAllDisplayedColumns()[0].getColId()

    // If filter/sort was on, display the Reset
    if (!isEmpty(this.gridApi.getFilterModel()) || !isEmpty(this.gridApi.getSortModel())) {
      this.dspReapplySortFilterBtn.next(true);
    }

    // Wipe out the filter/sort
    this.onAddRowFilterModel = this.gridApi.getFilterModel();
    this.onAddRowSortModel = this.gridApi.getSortModel();
    this.gridApi.setFilterModel(null);
    this.gridApi.setSortModel(null);

    const newRowIndex = this.gridApi.getModel().getRowCount();
    // Add new row
    this.gridApi.applyTransaction({ add: [newRow] });
    // Scroll to new row
    this.gridApi.ensureIndexVisible(newRowIndex);
    // Select the row
    this.gridApi.getDisplayedRowAtIndex(newRowIndex).setSelected(true);
    // Start editing first cell in new row
    this.gridApi.startEditingCell({
      rowIndex: newRowIndex,
      colKey: firstColKey
    });
  }

  setRowData(): void {
    if (this.relatedFormControls.find(formControl => formControl.status != 'VALID')) return;

    let dbValues = null;

    switch (this.control.dbValues.dbCall.objectID) {
      case DbCallType.Table:
        dbValues = this.getDbValuesTbl();
        break;
      case DbCallType.Function:
        dbValues = this.getDbValuesFn();
        break;
      default:
        throw new Error('Manual tables only support Table and Function dbCalls');
    }

    if (!dbValues) return;

    const schemaColumn = this.control.tableColumns[0] as ManualDataCollection;

    dbValues
      .pipe(
        map((values: any) => {
          // Handle array of key/value pair arrays
          if (this.control.dbValues.dbCall.objectID == DbCallType.Function) {
            const rowData = values.map(row => {
              return schemaColumn.fields.reduce(
                (previousValue, currentValue, index) => ({
                  ...previousValue,
                  [currentValue.key]: row[index]
                }),
                {}
              );
            });
            return rowData;
          } else {
            // Return JSON from table call
            return values;
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        rowData => {
          if (rowData?.length > 0) {
            this.gridApi.setColumnDefs(this.columnDefs);
          }
          this.rowData.next(rowData);
        },
        err => {
          this.showLoadingOverlay = false;
          console.error(err);
          this.gridApi.hideOverlay();
        }
      );
  }
}
