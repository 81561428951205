import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';

import { Item } from './item.model';
import {
  allItemsQuery,
  createItemMutation,
  updateItemMutation,
  deleteItemMutation
} from './items.graphql';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  constructor(private apollo: Apollo) { }

  all(): Observable<Item[]> {
    return this.apollo
      .query({
        query: allItemsQuery,
        fetchPolicy: 'network-only',
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.allItems)
      );
  }

  create(input: Item): Observable<Item> {
    delete input.id;
    return this.apollo
      .mutate({
        mutation: createItemMutation,
        variables: {
          input
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.createItem)
      );
  }

  update(patch: Item): Observable<Item> {
    return this.apollo
      .mutate({
        mutation: updateItemMutation,
        variables: {
          patch
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.updateItem)
      );
  }

  delete(id: string): Observable<Item> {
    return this.apollo
      .mutate({
        mutation: deleteItemMutation,
        variables: {
          id
        }
      }).pipe(
        map((response: ApolloQueryResult<any>) => response.data.deleteItem)
      );
  }
}
