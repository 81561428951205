import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentInformational, Db, DbCallType, DbFunction, NotifyService, Schema, Section } from '@compass/core-data';
import { DbFacade } from '@compass/core-state';
import { Observable, Subject, of } from 'rxjs';
import { bufferTime, distinctUntilChanged, filter, map, pairwise, startWith, switchMap, take } from 'rxjs/operators';
import { parse } from 'papaparse';
import { intersection, isEmpty, isEqual } from 'lodash';
import Handlebars from 'handlebars';
import marked from 'marked';

enum MarkdownSourceType {
  Template = 'template',
  Database = 'dbValues'
}

@Component({
  selector: 'compass-component-informational',
  templateUrl: './component-informational.component.html',
  styles: [`
    :host ::ng-deep p {
      margin: 0 !important;
      margin-block-end: 0 !important;
      margin-block-start: 0 !important;
    }
    :host ::ng-deep ul {
      margin: 0 !important;
      margin-block-end: 0 !important;
      margin-block-start: 0 !important;
    }
  `]
})
export class ComponentInformationalComponent implements OnInit {
  @Input() id: string;
  @Input() componentType: ComponentInformational;
  @Input() form: FormGroup;
  @Input() schema: Schema;
  @Input() section: Section;

  values$: Observable<string[]>;
  template$: Observable<string>;
  formChanges$: Observable<any>;

  constructor(private readonly dbFacade: DbFacade) {}

  ngOnInit(): void {
    const type: MarkdownSourceType = this.determineMarkdownSource();
    
    if (type === MarkdownSourceType.Template) {
      this.configureMarkdownFromPagedef();
    } else if (type === MarkdownSourceType.Database) {
      this.configureMarkdownFromDatabase();
    }
  }

  determineMarkdownSource(): MarkdownSourceType {
    if (this.componentType.template && this.componentType.dbValues) 
      throw ('Markdown / Informational Component does not support tempate with dbValues property.');

    if (this.componentType.template) {
      return MarkdownSourceType.Template;
    }

    if (this.componentType.dbValues) {
      return MarkdownSourceType.Database;
    }

    throw new Error('Markdown / Informational Component is not configured properly!');
  }

  configureMarkdownFromPagedef() {
    let markdownString = '';
    
    this.template$ = of(this.componentType.template).pipe(
      map(markdownItem => {
        markdownString = markdownString.concat(markdownItem, "\n");
        return markdownString;
      }),
      map(markdown => {
        const compiledTemplate = Handlebars.compile(this.componentType.template);
        return marked(compiledTemplate({ values: markdown }))
      })
    );

    const markdownArr: [] = this.componentType.template as any;
    markdownArr.forEach((val, index) => {
      markdownString = markdownString.concat(val);
      if (markdownArr.length > 0 && index < markdownArr.length) {
        markdownString = markdownString.concat("\n");
      }
    })

    const compiledTemplate = Handlebars.compile(markdownString);
    this.template$ = of(marked(compiledTemplate({ values: markdownString })));
  }

  configureMarkdownFromDatabase() {
    const dbFunction = this.componentType.dbValues.dbCall as DbFunction;
    const paramKeys = this.dbFacade.getRelationalParamKeys(dbFunction, this.form, this.schema, this.section)
    const formChanges$ = this.form.valueChanges.pipe(
      distinctUntilChanged(),
      pairwise(),
      map((value: any[]) => {
        return Object.keys(value[0]).reduce((memo, key) => {
          if (value[0][key] !== value[1][key]) {
            return {
              ...memo,
              [key]: [value[0][key], value[1][key]]
            }
          } else {
            return memo
          }
        }, {})
      }),
      filter((val: object) => Object.keys(val).length > 0),
      filter((val: object) => intersection(Object.keys(val), paramKeys).length > 0),
      bufferTime(100),
      distinctUntilChanged((a, b) => isEqual(a,b)),
      startWith(true)
    )
    this.values$ = formChanges$.pipe(
      switchMap(() => {
        let params = this.dbFacade.getParams(dbFunction, this.form, this.schema, this.section)
        return this.dbFacade
          .fn(dbFunction, params)
          .pipe(
            take(1),
            map(result => {
              const rows = result['pie_query'];
              const keys = Object.keys(rows);
              return keys.map(key => rows[key]).map(value => parse(value, { delimiter: ',' }));
            }),
            map(results => results[0]),
            map(parsed => parsed && !isEmpty(parsed.data) ? parsed.data[0] : [])
          )
      })
    )
    this.template$ = this.values$.pipe(
      map(values => {
        const compiledTemplate = Handlebars.compile(this.componentType.template);
        return marked(compiledTemplate({ values: values }))
      })
    )
  }
}
