import {
  Component,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Control,
  ControlDisplay,
  ControlDropdown,
  ControlHidden,
  ControlInput,
  ControlType,
  DataSourceType,
  DbCallType,
  NotifyService,
  DataType,
  Section,
  Schema,
} from '@compass/core-data';
import { WINDOW } from '@compass/core-window';
import { environment } from '@env/environment';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap, take } from 'rxjs/operators';
import { DbFacade } from '@compass/core-state';
import { isEmpty } from 'lodash';

type BasicControl = ControlDisplay | ControlDropdown | ControlHidden | ControlInput;

@Component({
  selector: 'compass-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss']
})
export class FormControlComponent implements OnDestroy, OnInit, OnChanges {
  /** A form-like control. The available controls are specified in the compass.json schema. */
  @Input() control: Control;

  /** The control type is declared so the enum is available in the template. */
  ControlType = ControlType;

  /** The FormGroup instance for the schema. */
  @Input() form: FormGroup;

  @Input() section: Section;

  @Input() schema: Schema;

  @Input() isParameter: boolean;

  @Input() value$: Observable<any>;

  @Input() index: number;

  /** Add the `table` class to the host element when the control is a ControlTable. */
  @HostBinding('class.table')
  isTable = false;

  /** Add the `textarea` class to the host element when the control is a ControlInput and DataType.Longtext (textarea). */
  @HostBinding('class.textarea')
  isTextarea = false;

  /** Set the value of the control for simple value controls. */
  @Input() value: boolean | number | string | Array<number | string>;

  // @Input() formValues: Observable<any[]>;
  @Input() formValues$: BehaviorSubject<any>;

  // Concatenation of the control KEY with it's schema parent ID property (schemaParentId || controlKey)
  relationalKey: string;  

  currentSchema$: Observable<Schema>;

  formControl: AbstractControl;

  /** Subject to trigger unsubcribe to observable streams. */
  private unsubscribe = new Subject<void>();

  constructor(
    private readonly dbFacade: DbFacade,
    @Inject(WINDOW) private window: Window,
    private readonly notifyService: NotifyService,
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    if (this.control.objectID === ControlType.Table) {
      return;
    }
    this.form.removeControl(this.relationalKey);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Set form control value
    if (changes.value && changes.value.currentValue !== undefined && this.relationalKey) {
      let value = changes.value.currentValue;
      
      // Exit if value hasn't changed, or one of the following control types (handle form control value there)
      // if (this.formControl.value === value) return;
      if (this.control.objectID === ControlType.Table) return;
      
      switch (this.control.objectID) {
        case ControlType.Dropdown:
          if (this.control.multiple && typeof value == 'string') {
            value = value.split(',');
          }
        break;
        case ControlType.Input:
          if (this.control.dataType == DataType.Boolean) {
            value = value === 'true' || value === true;
          }
          break;
      }

      this.formControl.setValue(value);
    }
  }

  ngOnInit() {
    if (this.control.objectID === ControlType.Table) {
      return this.isTable = true;
    }
    if (!this.control || !this.form) console.error('FormControl is expecting control and form to be defined here!')
    
    if (this.control && this.form) {
      this.relationalKey = this.generateRelationalKey();
      this.addControlToForm();
      this.setValueFromLocalStorage();
      this.setDefaultValueIfNoValue();
      this.persistControlValue();
      this.isTextarea =
        this.control.objectID === ControlType.Input && this.control.dataType === DataType.Longtext;
    }

    if (this.formValues$) {
      this.formValues$.subscribe(values => {
        if (!this.formControl) {
          return console.error('Expecting formControl to be defined here!')
        }
        // If there are no values in the DataEntry 
        if (isEmpty(values)) return;

        if (values[this.index] === undefined) {
          console.error(`This data populating the data entry form controls does not contain a value for form control index ${this.index}`);
        }
          
        const value = this.formatValue(values[this.index]);

        // Set the form control's value
        this.formControl.setValue(value)
      })
    }
  }

  private addControlToForm(): void {
    if (this.control.objectID === ControlType.Table) {
      return;
    }

    let newControl: FormControl;
    let formControlOptions: any = {
      value: this.value,
      disabled: this.control.editable === false || this.control.readonly || this.control.objectID === ControlType.Display
    };

    if (this.control.valueRequired) {
      if (formControlOptions.disabled) this.notifyService.notifyError(`Control (${this.control.title}) is required and disabled, the pagedef needs to be corrected!`);
      newControl = new FormControl(formControlOptions, Validators.required);
    } else {
      newControl = new FormControl(formControlOptions);
    }

    this.form.addControl(this.relationalKey, newControl);
    this.formControl = this.form.get(this.relationalKey);

    if (this.value && this.value != this.formControl.value) {
      console.warn('Form Control was initialized without its value.')
    }
  }

  private generateRelationalKey(): string {
    if (!this.section && !this.schema || !this.control || !this.control.key) {
      console.error(`FormControl is missing properties required to generate relational key!`);
    }

    return this.section ? `${this.section.id}_${this.control.key}` : `${this.schema.id}_${this.control.key}`;
  }

  private getFormValueFromLocalStorage(): { [key: string]: string | number } {
    if (!this.window) {
      return {};
    }

    const localStorageValue = this.window.localStorage.getItem(environment.localstorage.persist);
    if (!localStorageValue || localStorageValue.length === 0) {
      return {};
    }

    return JSON.parse(localStorageValue);
  }

  private persistControlValue(): void {
    if (!this.form.controls.hasOwnProperty(this.relationalKey)) {
      return;
    }

    const cacheKey = (this.control as BasicControl).key;

    // On every value change to this control, set it's value in the cache
    this.form
      .get(this.relationalKey)
      .valueChanges.pipe(
        filter(value => value !== null),
        distinctUntilChanged(),
        tap(value => {
          const values = {
            ...this.getFormValueFromLocalStorage(),
            [cacheKey]: value
          };
          this.window.localStorage.setItem(
            environment.localstorage.persist,
            JSON.stringify(values)
          );
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private setDefaultValueIfNoValue(): void {
    // verify control is not a table
    if (this.control.objectID === ControlType.Table) {
      return;
    }

    // verify default is defined
    if (this.control.defaultIfNoValue === undefined) {
      return;
    }

    switch (this.control.defaultIfNoValue.objectID) {
      case DataSourceType.DatabaseCall:
        this.setDefaultValueFromDbCall();
        break;
      case DataSourceType.SingleManualItem:
        // this.value = this.control.defaultIfNoValue.value;
        this.formControl.setValue(this.control.defaultIfNoValue.value);
        break;
    }
  }

  setDefaultValueFromDbCall() {
    // verify control is not a table
    if (this.control.objectID === ControlType.Table) {
      return;
    }

    // verify default is defined
    if (this.control.defaultIfNoValue === undefined) {
      return;
    }

    // verify default value is a dbCall
    if (this.control.defaultIfNoValue.objectID !== DataSourceType.DatabaseCall) {
      return;
    }

    switch (this.control.defaultIfNoValue.dbCall.objectID) {
      case DbCallType.Function:
        this.setDefaultValueFromDbCallFunction();
        break;
      case DbCallType.Sql:
        this.setDefaultValueFromDbCallSql();
        break;
    }
  }

  private setDefaultValueFromDbCallFunction() {
    // verify control is not a table
    if (this.control.objectID === ControlType.Table) {
      return;
    }

    // verify default is defined
    if (this.control.defaultIfNoValue === undefined) {
      return;
    }

    // verify default value is a dbCall
    if (this.control.defaultIfNoValue.objectID !== DataSourceType.DatabaseCall) {
      return;
    }

    // verify the dbCall is a function
    if (this.control.defaultIfNoValue.dbCall.objectID !== DbCallType.Function) {
      return;
    }

    // get parameters
    const dbFunction = this.control.defaultIfNoValue.dbCall;
    let parameters = this.dbFacade.getParametersWithDbFunction(
      dbFunction,
      this.form,
      this.schema,
      this.section
    );

    // do not set value if the parameters are undefined
    if (!parameters || !parameters.length) {
      return this.notifyService.notifyError(
        `Form control component error. Db function ${dbFunction} requires parameters but none were found.`
      );
    }

    // Append any staticKeys (additional [key, value]'s that are hardcoded in the schema)
    if (dbFunction.functionCall.staticKeys) {
      dbFunction.functionCall.staticKeys.forEach((staticKey: any) => {
        parameters.push([staticKey.key, staticKey.value]);
      });
    }

    // set value
    this.dbFacade
      .fn(dbFunction, parameters)
      .pipe(
        take(1),
        map(result => {
          const rows = result['pie_query'];
          const keys = Object.keys(rows);
          return keys.map(key => rows[key]);
        }),
        map(results => {
          // currently, we only care about the first result
          return results[0];
        })
      )
      .subscribe(value => {
        this.formControl.setValue(value);
      });
  }

  private setDefaultValueFromDbCallSql() {
    throw new Error('Default values for form control does not support SQL database calls.');
  }

  private setValueFromLocalStorage(): void {
    if (!this.isParameter) {
      return;
    }

    const cacheKey = this.control.key;
    const cache = this.getFormValueFromLocalStorage();
    if (!cache.hasOwnProperty(cacheKey)) return;
    
    const value = this.formatValue(cache[cacheKey]);

    // If cached value is an array and control is not multi select or array has an empty value, discard
    if (Array.isArray(value) && (!this.control['multiple'] || value.find(val => isEmpty(val)))) {
      return;
    }

    this.formControl.setValue(value)
  }

  private formatValue(value) {
    // Dropdown multiple value formatting
    if (this.control.objectID === ControlType.Dropdown && this.control.multiple && typeof value == 'string') {
      value = value.split(',');
    }

    // Checkbox
    if (this.control['dataType'] === DataType.Boolean) {
      value = value === 'true' || value === true;
    }

    return value;
  }
}
