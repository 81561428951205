import { FormGroup } from '@angular/forms';

export class CompassFormGroup extends FormGroup {
  private metadata = {}

  setMetadata(key: string, value: any) {
    this.metadata[key] = value;
  }

  getMetadata(key: string) {
    return this.metadata[key]
  }
}
