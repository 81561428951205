import { UsersActionTypes } from '../users/users.actions';

export function clearState(reducer) {
  return function (state, action) {

    if (action.type === UsersActionTypes.LOGOUT_USER) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
