import { Component, Input, HostBinding, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Parameter, Section, ControlType, Schema } from '@compass/core-data';

@Component({
  selector: 'compass-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.scss']
})
export class ParameterComponent implements OnInit, AfterViewInit {
  /** The FormGroup instance for the schema. */
  @Input() form: FormGroup;

  /** The parameter to render. */
  @Input() parameter: Parameter;

  @Input() section: Section;

  @Input() schema: Schema;

  relationalKey: string;

  /** Add the `multiselect` class to the host element when the control is a ControlDropdown with multiple enabled. */
  @HostBinding('class.multiselect')
  isMultiSelect = false;

  ngOnInit() {
    this.relationalKey = this.generateRelationalKey();
    this.isMultiSelect = this.parameter.controlType.objectID === ControlType.Dropdown && this.parameter.controlType.multiple;
  }

  //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  ngAfterViewInit(): void {
    const formControl: AbstractControl = this.form.controls[this.relationalKey];
    const valueRequired = this.parameter.controlType.valueRequired;
    
    if (formControl && valueRequired !== false) {
      // All Parameters should have (value) required validator
      formControl.setValidators([Validators.required]);
    }
  }

  private generateRelationalKey(): string {
    if (!this.section && !this.schema) {
      console.error("Parameter missing section and schema");
    }
    if (!this.section && this.schema) {
      // This Control is a Schema level Parameter
      if (this.schema.parameters.filter(param => param.controlType === this.parameter.controlType).length === 1) {
        return `${this.schema.id}_${this.parameter.controlType.key}`;
      }
    }
    
    return `${this.section.id}_${this.parameter.controlType.key}`;
  }
}
