import gql from 'graphql-tag';

export const loginQuery = gql`
  query login($user: LoginInput!) {
    login(user: $user) {
      token
      user {
        id
        usr_name
        usr_created
        usr_updated
      }
      tableauResponse {
        credentials {
          site {
            id
            contentUrl
          }
          user {
            id
          }
          token
        }
      }
      newUser
    }
  }
`;
