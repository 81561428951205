export interface Timeline {
  tim_id: number;
  tim_name: string;
  tim_desc: string;
  cale_tim_id: number;
  cale_tim_sort: number;
  tim_sys_active: boolean;
  tim_sys_ts: string;
  tim_sys_usr: string;
  tim_sys_desc: string;
}

export const emptyTimeline: Timeline = {
  tim_id: null,
  tim_name: '',
  tim_desc: '',
  cale_tim_id: null,
  cale_tim_sort: null,
  tim_sys_active: false,
  tim_sys_ts: '',
  tim_sys_usr: '',
  tim_sys_desc: ''
}
