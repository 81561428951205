import { Inject, Injectable } from '@angular/core';
import { NotifyService, FavoritesService } from '@compass/core-data';
import { WINDOW } from '@compass/core-window';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  FavoritesLoaded,
  FavoritesActionTypes,
  LikeEntity,
  EntityLiked,
  DislikeEntity,
  EntityDisliked
} from './favorites.actions';

@Injectable()
export class FavoritesEffects {
  @Effect()
  loadFavorites$: Observable<Action> = this.actions$.pipe(
    ofType(FavoritesActionTypes.LOAD_FAVORITES),
    switchMap(() =>
      this.favoritesService
        .loadFavorites()
        .pipe(map(favorites => new FavoritesLoaded({ favorites })))
    ),
    catchError(error => {
      this.notifyService.notify(error, 'Error');
      return of(new FavoritesLoaded({ favorites: null }));
    })
  );

  @Effect()
  likeEntity$: Observable<Action> = this.actions$.pipe(
    ofType(FavoritesActionTypes.LIKE_ENTITY),
    switchMap((action: LikeEntity) =>
      this.favoritesService
        .likeEntity(action.payload.id, action.payload.type, action.payload.label)
        .pipe(map(favorites => new EntityLiked({ favorites })))
    ),
    catchError(error => {
      this.notifyService.notify(error, 'Error');
      return of(new FavoritesLoaded({ favorites: null }));
    })
  );

  @Effect()
  dislikeEntity$: Observable<Action> = this.actions$.pipe(
    ofType(FavoritesActionTypes.DISLIKE_ENTITY),
    switchMap((action: DislikeEntity) =>
      this.favoritesService
        .dislikeEntity(action.payload.id, action.payload.type)
        .pipe(map(() => new EntityDisliked({ id: action.payload.id })))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly favoritesService: FavoritesService,
    private readonly notifyService: NotifyService,
    @Inject(WINDOW) private readonly window: Window
  ) {}
}
