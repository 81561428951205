import gql from 'graphql-tag';

export const itemsFragment = gql`
  fragment itemsFragment on ItemDTO {
    id
    name
    description
  }
`;

export const allItemsQuery = gql`
  query allItemsQuery {
    allItems {
      ...itemsFragment
    }
  }
  ${ itemsFragment }
`;

export const createItemMutation = gql`
  mutation createItemMutation($input: ItemInput!) {
    createItem(input: $input) {
      ...itemsFragment
    }
  }
  ${ itemsFragment }
`;

export const updateItemMutation = gql`
  mutation updateItemMutation($patch: ItemPatch!) {
    updateItem(patch: $patch) {
      ...itemsFragment
    }
  }
  ${ itemsFragment }
`;

export const deleteItemMutation = gql`
  mutation deleteItemMutation($id: String!) {
    deleteItem(id: $id)
  }
`;
