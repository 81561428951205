<!-- mat-card has been moved to schema.component.html -->
  <mat-card-header *ngIf="section.title" class="mb-0 d-flex align-items-center">
    <!-- Only display mat-card-title if non-expandable section, because expansion panels have mat-expansion-panel-header -->
    <mat-card-title *ngIf="dspMatCardTitle">
      {{ section.title }}
    </mat-card-title>
    <mat-card-subtitle *ngIf="section.description && section.description.trim().length > 0">
      {{ section.description }}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="section.parameters || section.components || section.actions" class="d-flex align-items-center flex-wrap">
    <compass-parameter
      *ngFor="let parameter of section.parameters"
      [style.width]="parameter.controlType.pageWidth"
      [form]="form"
      [parameter]="parameter"
      [section]="section"
      [schema]="schema"
    ></compass-parameter>

    <mat-card-actions class="m-0" *ngIf="section.actions && section.actions.length > 0">
      <compass-action
        *ngFor="let action of section.actions"
        [action]="action"
        [form]="form"
        [section]="section"
        [schema]="schema"
      ></compass-action>
    </mat-card-actions>

    <compass-component
      *ngFor="let component of section.components"
      [component]="component"
      [form]="form"
      [showBox]="section.components.length > 1 && !component.hideBorder"
      [section]="section"
      [schema]="schema"
    ></compass-component>

    <!-- todo: variables -->
  </mat-card-content>
