import gql from 'graphql-tag';

import { rolesFragment } from '../roles/roles.graphql';

export const loadUserAppsQuery = gql`
  query loadUserAppsQuery($usr_id: ID!) {
    loadUserApps(usr_id: $usr_id) {
      app_id
      app_name
      app_desc
      app_filename
      app_foldername
      app_icon
      app_status
      appl_app_sort
    }
  }
`;

export const loadUserRolesQuery = gql`
  query loadUserRolesQuery($usr_id: ID) {
    loadUserRoles(usr_id: $usr_id) {
      rol_id
      rol_name
      rol_desc
      rol_short
    }
  }
`;
