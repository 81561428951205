import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { DataPersistence } from '@nrwl/nx';
import { Effect, Actions } from '@ngrx/effects';

import { NotifyService, Role, RolesService } from '@compass/core-data';
import { RolesActionTypes, LoadRoles, RolesLoaded, AddRole, RoleAdded, UpdateRole, RoleUpdated, DeleteRole, RoleDeleted } from './roles.actions';
import { RolesState } from './roles.reducer';

@Injectable()
export class RolesEffects {
  @Effect()
  loadRoles$ = this.dataPersistence.fetch(RolesActionTypes.LOAD_ROLES, {
    run: (action: LoadRoles, state: RolesState) => {
      return this.rolesService.load().pipe(map((res: Role[]) => new RolesLoaded(res)));
    },

    onError: (action: LoadRoles, error) => {
      this.notifyService.notify(error, 'Error');
    }
  });

  @Effect()
  addRole$ = this.dataPersistence.pessimisticUpdate(RolesActionTypes.ADD_ROLE, {
    run: (action: AddRole, state: RolesState) => {
      return this.rolesService.create(action.payload).pipe(map((res: Role) => new RoleAdded(res)));
    },

    onError: (action: AddRole, error) => {
      this.notifyService.notify(error, 'Error');
    }
  });

  @Effect()
  updateRole$ = this.dataPersistence.pessimisticUpdate(RolesActionTypes.UPDATE_ROLE, {
    run: (action: UpdateRole, state: RolesState) => {
      return this.rolesService.update(action.payload).pipe(map((res: Role) => new RoleUpdated(res)));
    },

    onError: (action: UpdateRole, error) => {
      this.notifyService.notify(error, 'Error');
    }
  });

  @Effect()
  deleteRole$ = this.dataPersistence.pessimisticUpdate(RolesActionTypes.DELETE_ROLE, {
    run: (action: DeleteRole, state: RolesState) => {
      return this.rolesService.delete(action.payload.rol_id).pipe(map(_ => new RoleDeleted(action.payload)));
    },

    onError: (action: DeleteRole, error) => {
      this.notifyService.notify(error, 'Error');
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<RolesState>,
    private rolesService: RolesService,
    private notifyService: NotifyService
  ) { }
}
